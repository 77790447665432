<template>
  <Toast />
  <div v-if="buttonModeLocal == 'atribuir' && checkRoleDistribution()" class="flex justify-content-end ml-4 mt-2"
    style="width: 100%">
    <div style="height: 45px" v-if="this.checked.length > 0" class="p-float-label customAutoCompleteEmail mr-2">
      <Dropdown v-model="selectedRoUser" :options="roUsers" />
      <label><i class="pi pi-user" style="font-weight: 900"></i>
        {{ this.$langGetValue("user") }}</label>
    </div>
    <Button icon="pi pi-user" :label="this.$langGetValue('buttonBatchAssign')" @click="batchAssign"
      style="margin-bottom: 10px; margin-right: 15px; color: #195ab4; height: 45px"
      class="p-button p-component p-button-outlined" />
  </div>

  <DataTable class="customDataTable" v-model:expandedRows="expandedRows" :value="resultLocalData" dataKey="id" lazy
    :paginator="resultLocalData == null || resultLocalData.length > 0" :rows="pages"
    :rowsPerPageOptions="[5, 10, 20, 50]" :totalRecords="totalRecords" @page="paginate($event)"
    :first="this.currentPageLocal * pages" :loading="resultLocalData == null" loadingIcon="">
    <!-- <template #header>
        <div class="flex flex-wrap items-center justify-between gap-2">
            <Button icon="pi pi-file-excel" rounded raised @click="download()"
            v-tooltip.top="'Export to Excel\n(first 500 results)'"  />
        </div>
    </template> -->
    <template #loading>
      <div v-for="index in 5" :key="index">
        <div class="flex">
          <div class="col-10 flex flex-column justify-content-start pr-4" style="padding: 0">
            <div class="flex align-items-center">
              <Skeleton shape="circle" size="16px" class="mr-2"> </Skeleton>
              <Skeleton width="8rem" class="mr-4"></Skeleton>

              <div class="mt-1 flex">
                <Skeleton width="4rem" class="mb-2"></Skeleton>
                <Skeleton width="4rem" class="mb-2 ml-2"></Skeleton>
              </div>
            </div>

            <div class="flex align-items-center justify-content-start mt-3">
              <Skeleton width="50rem" class="mb-2"></Skeleton>
            </div>
          </div>

          <div class="col-2 flex" style="padding: 0">
            <div style="width: 100%; height: 100%" class="flex">
              <div style="width: 50%" class="flex align-items-center justify-content-start">
                <Divider layout="vertical" />
              </div>
              <div style="width: 50%" class="flex align-items-center justify-content-start">
                <Skeleton shape="circle" size="1rem"> </Skeleton>
              </div>
            </div>
          </div>
        </div>
        <Divider layout="horizontal" />
      </div>
    </template>
    <Column expander style="width: 3rem; padding-right: 0"> </Column>
    <Column style="padding-left: 0" field="header">
      <template #body="slotProps">
        <div style="padding: 17px" @dblclick="loadIncidents(slotProps.data.id)" @mouseover="slotProps.data.hover = true"
          @mouseleave="slotProps.data.hover = false" :class="{ flex: true }">
          <div class="col-9 flex flex-column justify-content-start" style="padding: 0px">
            <div class="flex align-items-center">
              <Checkbox v-if="buttonModeLocal == 'atribuir' && checkRoleDistribution()" v-model="checked" @click.stop
                :value="slotProps.data.id" style="margin-right: 10px" />
              <Skeleton v-if="slotProps.data.status != -1" v-tooltip.top="getMarioSeverity(slotProps.data.riskLevel)"
                shape="circle" size="16px" class="mr-2" :style="{
                  'background-color': getMarioSeverityColor(
                    slotProps.data.data.riskLevel
                  ),
                  'min-width': '16px',
                }">
              </Skeleton>
              <i v-else class="flex mr-2 pi pi-times-circle" v-tooltip.top="{ value: this.$langGetValue('status0') }"
                style="font-size: 1rem; color: red"></i>
              <div style="
                  white-space: nowrap;
                  color: #05132a;
                  font-size: 16px;
                  font-weight: 700;
                ">
                {{ slotProps.data.name }}
                <i v-show="slotProps.data.data.hover" style="
                    font-size: 1.1rem;
                    cursor: pointer;
                    color: rgb(73, 80, 87);
                  " @click="copyInc(slotProps.data.data.name)" class="pi pi-copy"></i>
              </div>

              <div class="flex flex-wrap ml-4" style="gap: 8px">
                <Chip class="primaryChip" v-if="slotProps.data.data.OBUChip != null"
                  style="margin-right: 3px; cursor: help" :label="slotProps.data.data.OBUChip.label" v-tooltip.top="{
                    value: slotProps.data.data.OBUChip.path,
                    class: 'edited-tooltip-incident',
                  }" />
                <div class="flex align-items-center mr-1" v-if="slotProps.data.data.OBUChip != null &&
                  slotProps.data.data.IBUsChip.length > 0
                ">
                  <i class="pi pi-angle-right" style="color: rgb(0, 0, 0)"></i>
                </div>
                <Chip class="primaryChip" v-for="selected in slotProps.data.data.IBUsChip" style="cursor: help"
                  :key="selected" :label="selected.label" v-tooltip.top="{
                    value: selected.path,
                    class: 'edited-tooltip-incident',
                  }" />
              </div>
            </div>

            <div class="flex align-items-center justify-content-start mt-2" :style="{
              width: '100% !important',
              overflow: 'hidden',
              'text-decoration-line':
                slotProps.data.status == -1 ? 'line-through' : 'none',
            }">
              <div class="resumeText" v-if="slotProps.data.data.tweet != '' &&
                slotProps.data.data.tweet != null &&
                slotProps.data.data.tweet != undefined
              ">
                {{ slotProps.data.data.tweet }}
              </div>
              <div class="resumeText" v-else-if="slotProps.data.data.tweetBrainSTORM != '' &&
                slotProps.data.data.tweetBrainSTORM != null &&
                slotProps.data.data.tweetBrainSTORM != undefined
              ">
                {{ slotProps.data.data.tweetBrainSTORM }}
              </div>
              <div class="resumeText" v-else>
                {{
                  slotProps.data.data.descriptionSanitized.length > 100
                    ? abbreviateDescription(slotProps.data.data.descriptionSanitized)
                    : slotProps.data.data.descriptionSanitized
                }}
              </div>
            </div>

          </div>

          <div class="flex justify-content-end align-items-center" style="width: 12.5%; padding: 5px;">
            <Chip :label="slotProps.data.data.integrationFrom.application" class="temporaryChip"
              v-tooltip.top="{ value: this.$langGetValue('applicationFromMode' + slotProps.data.data.integrationFrom.mode.capitalize()) }" />
          </div>
          <Divider layout="vertical" style="color: #cacaca; margin-left: 0px;" />

          <div class="flex align-items-center justify-content-center" style="padding: 0px; width: 12.5%;">
            <div v-if="buttonModeLocal == 'onlyView'" class="flex">
              <span class="mr-3">
                <i class="pi pi-search-plus" v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="loadIncidents(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>
            </div>

            <div v-if="buttonModeLocal == 'bdpo'" class="flex">
              <span class="mr-3">
                <i class="pi pi-pencil" v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="fillBDPO(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>
            </div>

            <div v-if="buttonModeLocal == 'visualizar'" class="flex">
              <span class="mr-3">
                <i class="pi pi-search-plus" v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="loadIncidents(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>

              <span class="mr-3">
                <i class="pi pi-pencil" v-tooltip.top="{ value: this.$langGetValue('buttonEdit') }"
                  @click="loadIncidentsMode(slotProps.data.id, 'edit')" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>
            </div>

            <div v-if="buttonModeLocal == 'atribuir'" class="flex">
              <span class="mr-3">
                <i class="pi pi-search-plus" v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="loadIncidents(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>

              <span v-if="checkRoleDistribution()" class="mr-3">
                <i class="pi pi-user-plus" v-tooltip.top="{ value: this.$langGetValue('buttonAssign') }"
                  @click="toggle(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>
              <Menu ref="menu" id="overlay_menu" :model="items" :popup="true"
                style="max-height: 250px; overflow-y: auto" />
            </div>

            <div v-if="buttonModeLocal == 'revisar'" class="flex">
              <span class="mr-3">
                <i class="pi pi-search-plus" v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="loadIncidents(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>

              <span class="mr-3">
                <i class="pi pi-pencil" v-tooltip.top="{ value: this.$langGetValue('buttonEdit') }"
                  @click="loadIncidentsMode(slotProps.data.id, 'edit')" style="font-size: 1.1rem; cursor: pointer"></i>
              </span>

              <span class="mr-3">
                <i class="pi pi-check" v-tooltip.top="{ value: this.$langGetValue('buttonReview') }"
                  @click="loadIncidentsMode(slotProps.data.id, 'review')"
                  style="font-size: 1.1rem; cursor: pointer"></i>
              </span>

              <div v-if="checkRoleDistribution()">
                <span class="mr-3">
                  <i class="pi pi-user-edit" v-tooltip.top="{
                    value: this.$langGetValue('buttonReAssign'),
                  }" @click="toggle(slotProps.data.id)" style="font-size: 1.1rem; cursor: pointer"></i>
                </span>
                <Menu ref="menu" id="overlay_menu" :model="items" :popup="true"
                  style="max-height: 250px; overflow-y: auto" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </Column>

    <template #expansion="slotProps">
      <div class="flex flex-column" style="padding: 24px 0px 24px 42px">
        <div class="flex">
          <div class="flex justify-content-start align-items-center">
            <div style="font-weight: 500">
              {{ this.$langGetValue("dateOfOccurrence") }}
            </div>
            <div class="flex" style="padding: 0px 0px 0px 8px">
              <b>{{ formatDate(slotProps.data.data.dateOfOccurrence) }}</b>
            </div>
          </div>
          <div class="flex justify-content-start align-items-center" style="padding-left: 32px">
            <div style="font-weight: 500">
              {{ this.$langGetValue("dateOfRegistration") }}
            </div>
            <div class="flex" style="padding: 0px 0px 0px 8px">
              <b>{{ formatDate(slotProps.data.data.submittedTimestamp) }}</b>
            </div>
          </div>
        </div>

        <div class="flex flex-column" style="padding-top: 24px">
          <div class="flex">
            <b>{{ this.$langGetValue("description") }}</b>
          </div>
          <div class="flex" style="padding-top: 8px; font-size: 14px">
            {{ this.addSpace(slotProps.data.data.descriptionSanitized) }}
          </div>
        </div>
        <div class="flex flex-column justify-content-start" style="padding-top: 24px">
          <b>{{ this.$langGetValue("basel") }}</b>
          <div class="flex align-items-center" style="padding-top: 8px">
            <Chip class="secondaryChip"
              :label="getBaselOne(slotProps.data.data.baselLevel.levelOne) != null ? getBaselOne(slotProps.data.data.baselLevel.levelOne) : '-'" />
            <i class="pi pi-angle-right" style="font-size: 1.2rem; padding: 0px 2px"></i>
            <Chip class="secondaryChip"
              :label="getBaselTwo(slotProps.data.data.baselLevel.levelOne, slotProps.data.data.baselLevel.levelTwo) != null ? getBaselTwo(slotProps.data.data.baselLevel.levelOne, slotProps.data.data.baselLevel.levelTwo) : '-'" />
          </div>
        </div>
        <div class="flex align-items-center" style="padding: 24px 0px 12px 0px; width: 100%; gap: 30px">
          <div class="flex flex-column justify-content-start col-4" style="padding: 0px; width: calc(33% - 10px)">
            <b>Risk Factor</b>
            <div style="padding: 8px 0px 0px 0px">
              <Chip class="secondaryChip" :label="getRiskFactor(slotProps.data.data.riskFactor)" />
            </div>
          </div>
          <div class="flex flex-column justify-content-start col-4" style="padding: 0px; width: calc(33% - 10px)"
            v-if="getCapability(slotProps.data.data.capability) != null">
            <b>Capability</b>
            <div style="padding: 8px 0px 0px 0px">
              <Chip class="secondaryChip" :label="getCapability(slotProps.data.data.capability)" />
            </div>
          </div>
          <div class="flex flex-column justify-content-start col-4" style="padding: 0px; width: calc(33% - 10px)">
            <b>{{ this.$langGetValue("company") }}</b>
            <div style="padding: 8px 0px 0px 0px">
              <Chip class="secondaryChip" :label="getCompany(slotProps.data.data.company)" />
            </div>
          </div>
        </div>
        <Divider />
        <div class="flex" style="padding: 12px 0px 0px 0px; width: 100%; gap: 40px">
          <div v-if="slotProps.data.data.system != null" class="flex flex-column justify-contet-start"
            style="padding: 0px; width: calc(50% - 20px)">
            <b>{{ this.$langGetValue("system") }}</b>
            <div class="flex flex-wrap" style="padding-top: 8px; gap: 8px">
              <Chip class="secondaryChip" v-for="selected in slotProps.data.data.system" :key="selected"
                :label="getSystem(selected) != null ? getSystem(selected) : '-'" />
            </div>
          </div>
          <div class="flex flex-column justify-contet-start" style="padding: 0px; width: calc(50% - 20px)">
            <b>{{ this.$langGetValue("products") }}</b>
            <div class="flex flex-wrap" style="padding-top: 8px; gap: 8px">
              <Chip class="secondaryChip" v-for="selected in slotProps.data.data.product" :key="selected"
                :label="getProduct(selected) != null ? getProduct(selected) : '-'" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </DataTable>
</template>

<script>
import UserService from "../service/UserService";
import IncidentService from "../service/IncidentService.js";
import FieldService from "../service/FieldService";
import Util from "../service/Util.js";
import EventBus from "../AppEventBus";

export default {
  data() {
    return {
      pages: 10,
      initialPageSize: null,

      numberOfRows: null,
      highlightIsActive: false,
      totalRecords: 0,
      loading: false,
      expandedRows: [],
      selectedRoUser: null,
      checked: [],
      areaHierarchy: null,
      hierarchyArray: [],
      resultLocalData: null,
      buttonModeLocal: this.buttonMode,
      sendIncidentModal: false,
      items: [],
      roUsers: UserService.roUsers,
      currentPageLocal: null,
    };
  },
  props: {
    buttonMode: String,
    query: Object,
    analyticsOnSearch: Boolean,
    currentPage: Number,
  },
  watch: {
    resultData(newValue) {
      this.resultLocalData = newValue;
    }
  },
  mounted() {
    this.areaHierarchy = FieldService.getField('incident', 'originatedArea');

    this.createArrayWithPath();

    if (this.query != null) {
      "page" in this.query
        ? this.currentPageLocal = this.query.page
        : this.currentPageLocal = 0

      this.paginate({ page: this.currentPageLocal })
    }
  },
  methods: {
    getSystem(id) {
      FieldService.getField('incident', 'system').filter((s) => s.id == id)[0]?.label
    },
    getProduct(id) {
      return FieldService.getField('incident', 'product').filter((s) => s.id == id)[0]?.label
    },
    getCompany(id) {
      for (let i = 0; i < FieldService.getField('incident', 'company').length; i++) {
        if (FieldService.getField('incident', 'company')[i].children.filter((s) => s.id == id).length > 0) {
          return FieldService.getField('incident', 'company')[i].children.filter((s) => s.id == id)[0]?.label
        }
      }
    },
    getCapability(id) {
      return FieldService.getField('incident', 'capability').filter((s) => s.id == id)[0]?.label
    },
    getRiskFactor(id) {
      return FieldService.getField('incident', 'riskFactor').filter((s) => s.id == id)[0]?.label
    },
    getBaselOne(id) {
      if (!id) {
        return null; 
      }

      return FieldService.getField('incident', 'baselLevel').filter((s) => s.id == id)[0]?.label
    },
    getBaselTwo(idOne, idTwo) {
      if (!idOne) {
        return null; 
      }

      const baselLevel = FieldService.getField('incident', 'baselLevel');
      const firstLevel = baselLevel.find((s) => s.id === idOne);

      if (!firstLevel || !firstLevel.children) {
        return null; 
      }

      const secondLevel = firstLevel.children.find((b) => b.id === idTwo);
      return secondLevel?.label; 
    },
    copyInc(inc) {
      Util.copyToClipboard(inc, inc);

      this.$toast.add({
        severity: "info",
        summary: this.$langGetValue("toast13"),
        // detail: this.$langGetValue('toast8Sub'),
        life: 1000,
      });
    },
    addSpace(texto) {
      const words = texto.split(" ");
      let result = "";

      words.forEach((word) => {
        if (word.length <= 50) {
          result = result + " " + word;
        } else {
          let tempWord = "";
          for (let i = 0; i < word.length; i++) {
            tempWord = tempWord + word[i];
            if (tempWord.length % 50 === 0) {
              tempWord = tempWord + " ";
            }
          }
          result = result + " " + tempWord;
        }
      });

      return result.trim();
    },
    abbreviateDescription(text) {
      if (/[.,]/.test(text[99])) {
        return text.substring(0, 100).replace(/[.,]$/, "...");
      } else if (text[99] === " ") {
        let lastWord = text.substring(0, 100).replace(/\s*\w*$/, "");

        lastWord = lastWord.replace(/[.,]$/, "");

        return lastWord + "...";
      } else {
        let completeWord = text.substring(0, 100).replace(/\s*\w*$/, "");

        return completeWord + "...";
      }
    },
    async paginate(event) {
      let tempQuery = { ...this.query };
      this.resultLocalData = null;
      tempQuery.page = event.page + 1;
      this.currentPageLocal = event.page;

      if (event.rows == undefined) {
        tempQuery.pageSize = parseInt(Util.pageSize)
        this.pages = parseInt(Util.pageSize)
      } else {
        await Util.setRemoteStorage("pagesize", event.rows).then(() => {
          tempQuery.pageSize = event.rows;
          Util.pageSize = event.rows
        });
      }

      IncidentService.incidentList(tempQuery, this.analyticsOnSearch).then(
        (response) => {
          if (response.success == true) {
            this.resultLocalData = response.data.records;
            if (
              response.data.records == null ||
              response.data.records.length == 0
            ) {
              if (this.buttonModeLocal == "onlyView") {
                this.$toast.add({
                  severity: "info",
                  summary: this.$langGetValue("toast8"),
                  detail: this.$langGetValue("toast8Sub"),
                  life: 1000,
                });
              }

              this.$emit("update-length", 0);
              return;
            }

            this.totalRecords = response.data.totalRecords;

            this.$emit("update-length", this.totalRecords);

            let idList = [];

            response.data.records.forEach((incident) => {
              idList.push(incident.id);
            });

            this.$emit("page-data", idList);

            for (let i = 0; i < this.resultLocalData.length; i++) {
              if (this.resultLocalData[i].data.hierarchy != null) {
                const resultado = this.parserAreas(
                  this.resultLocalData[i].data.hierarchy
                );

                this.resultLocalData[i].data.OBUChip = resultado[0];
                this.resultLocalData[i].data.IBUsChip = resultado[1];
              }
            }
          } else {
            if (this.currentPageLocal >= 1) {
              this.paginate({ page: this.currentPageLocal - 1 });
            }
          }
        }
      );
    },
    checkRoleDistribution() {
      return UserService.checkUserRoleAny([UserService.company + '.storm.incident.*.assign'])
    },
    assignIncident(incidentId, selectedRoUser) {
      IncidentService.distributeIncident(incidentId, selectedRoUser).then(
        (response) => {
          if (!response.success) {
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast9"),
              life: 1000,
            });
            return;
          }

          this.$toast.add({
            severity: "success",
            summary: this.$langGetValue("toast10"),
            life: 1000,
          });

          this.resultLocalData.forEach((incident) => {
            if (incidentId[0] == incident.id) {
              incident.status == 2
                ? EventBus.emit("force-update", {
                  tab: "toAssign",
                  currentPage: this.currentPageLocal,
                })
                : EventBus.emit("force-update", { tab: "assigned" });
              return;
            }
          });
        }
      );
    },
    toggle(idIncident) {
      this.items = []

      this.roUsers.forEach((user) => {
        this.items.push({
          label: Util.emailToName(user),
          command: () => {
            this.assignIncident([idIncident], user);
          },
        });
      });

      this.$refs.menu.toggle(event);
    },
    batchAssign() {
      if (this.checked.length < 1) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue("toast11"),
          life: 1000,
        });
        return;
      }

      if (this.selectedRoUser == null) {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue("toast12"),
          life: 1000,
        });
        return;
      }
      this.assignIncident(this.checked, this.selectedRoUser);
    },
    loadIncidents(id) {
      if (window.location.hash.includes("incident/search")) {
        EventBus.emit("searchIncidentByID", id);
      } else {
        // this.$router.push({ path: '/incident/search?', query: { id: id } });
        window.open(
          window.location.href + "incident/search?id=" + id,
          "_blank"
        );
      }
    },
    fillBDPO(id) {
      var newWindow = window.open(
        window.location.href + "incident/search?id=" + id + "&bdpo=true"
      );

      if (newWindow) {
        newWindow.onload = function () {
          newWindow.location.reload();
        };
      }
    },
    loadIncidentsMode(id, mode) {
      // this.$router.push({ path: '/incident/search?', query: { id: id, mode: mode } });
      window.open(
        window.location.href + "incident/search?id=" + id + "&mode=" + mode,
        "_blank"
      );
    },
    formatDate(date) {
      return Util.formatDate(date);
    },
    getMarioSeverityColor(inc) {
      if (inc == "low") {
        return "#307AE0";
      } else if (inc == "medium") {
        return "#FFCA43";
      } else if (inc == "high") {
        return "#EB3E48";
      } else if (inc == "critical") {
        return "#754CC5";
      }
    },
    getMarioSeverity(inc) {
      if (inc == "low") {
        return this.$langGetValue("lowMario");
      } else if (inc == "medium") {
        return this.$langGetValue("mediumMario");
      } else if (inc == "high") {
        return this.$langGetValue("highMario");
      } else if (inc == "critical") {
        return this.$langGetValue("criticalMario");
      }
    },
    createArrayWithPath() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
        };

        const result = [info];

        if (tree.children && tree.children.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy.length; i++) {
        this.hierarchyArray = this.hierarchyArray.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }
    },
    parserAreas(response) {
      let OBUChip = null;
      let IBUsChip = [];

      response.forEach((tempHierarchy) => {
        const area = this.hierarchyArray.find(
          (object) =>
            object.id === tempHierarchy.id &&
            tempHierarchy.type === "originated"
        );
        if (area) {
          OBUChip = {
            label: area.label,
            path: area.path,
            id: area.id,
          };
        }
      });

      response.forEach((tempHierarchy) => {
        const area = this.hierarchyArray.find(
          (object) =>
            object.id === tempHierarchy.id && tempHierarchy.type === "impacted"
        );
        if (area) {
          IBUsChip.push({
            label: area.label,
            path: area.path,
            id: area.id,
          });
        }
      });

      return [OBUChip, IBUsChip];
    },
    // download() {
    //   console.log("download", this.query);
    //   let payload = this.query;
    //   payload.page = null
    //   payload.pageSize = null
    //   IncidentService.downloadResult(payload).then((resp) => {
    //     var a = document.createElement("a");
    //     a.href = window.URL.createObjectURL(resp);
    //     a.download = "incident_result.xlsx";
    //     a.click();
    //   });
    // },
  },
};
</script>

<style>
.edited-tooltip-incident .p-tooltip-text {
  white-space: nowrap;
  width: fit-content;
}

.customDataTable th {
  display: none;
}

.customDataTable .p-datatable-tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.customDataTable .p-datatable-loading-overlay {
  display: none;
}

.p-button {
  background-color: #195ab4;
}

.p-button:hover {
  background-color: #2860ad;
}

.resumeText {
  margin-top: 8px;
  color: #484848;
  font-size: 12px;
}

.customDataTable .p-datatable-row-expansion {
  background: #f7f7f7 !important;
}
</style>
