<template>
  <Toast />
  <div style="padding: 16px 0px 0px 0px">
    <div class="card" style="padding: 32px 24px 32px 24px">
      <div class="flex mt-2" v-if="incidentIDLocal == null">
        <div class="p-float-label">
          <Dropdown style="height:100%" v-model="selectedField" :options="searchfields" optionLabel="name"
            class="w-full md:w-14rem" />
          <label>{{ this.$langGetValue("searchOptions") }}</label>
        </div>
        <div v-if="selectedField != null && selectedField.id == 1" class="p-float-label ml-3">
          <Chips style="height:100%" @keyup.enter="search" v-model="incidentID" separator="," @add="handlePaste"
            :addOnBlur="true" />
          <label for="incidentID"><i class="pi pi-search" style="font-weight: 900"></i>
            {{ this.$langGetValue("incidentID") }}</label>
        </div>
        <div v-if="selectedField != null && selectedField.id == 2" class="flex">
          <div class="p-float-label ml-3">
            <Dropdown v-model="selectedDate" style="height:100%" :options="dateOptions" optionLabel="name"
              class="w-full md:w-14rem" />
            <label>{{ this.$langGetValue("searchOptionDateSub") }}</label>
          </div>
          <div class="p-float-label ml-3">
            <Calendar style="height:100%" dateFormat="dd-mm-yy" id="minmax" v-model="initialDate" :maxDate="new Date()"
              :manualInput="false" ref="dateOccurrence" showIcon />
            <label>{{ this.$langGetValue("start") }}</label>
          </div>
          <div class="p-float-label ml-3">
            <Calendar style="height:100%" dateFormat="dd-mm-yy" id="minmax" v-model="finalDate" :maxDate="new Date()"
              :manualInput="false" ref="dateOccurrence" :minDate="initialDate" showIcon />
            <label>{{ this.$langGetValue("end") }}</label>
          </div>
        </div>
        <div v-if="selectedField != null && selectedField.id == 4" class="p-float-label ml-3 customLabel">
          <!-- <InputText v-model="generalSearch" class="customInput" /> -->
          <Chips style="height:100%" v-model="generalSearch" separator="," @keyup.enter="search" class="customInput"
            :addOnBlur="true" />
          <label for="incidentID"><i class="pi pi-comment" style="font-weight: 900"></i>
            {{ this.$langGetValue("searchOptionDescription") }}</label>
        </div>
        <div v-if="selectedField != null && selectedField.id == 5" class="p-float-label ml-3 customLabel">
          <AutoComplete style="height:100%" ref="selectedHashtags" class="customAutoComplete customInput" :multiple="true"
            v-model="selectedHashtags" :suggestions="filteredHashtags" @complete="searchHashtags($event)"
            :forceSelection="true" field="label" />
          <label for="selectedHashtags"><i class="pi pi-hashtag" style="font-weight: 900"></i>
            Hashtag</label>
        </div>
        <div v-if="selectedField != null && selectedField.id == 6" class="flex">
          <div class="p-float-label ml-3">
            <Dropdown style="height:100%" v-model="selectedPersonField" :options="personFieldsOptions"
              optionLabel="person" class="w-full md:w-14rem" />
            <label>{{ this.$langGetValue("field") }}</label>
          </div>
          <div class="p-float-label ml-3">
            <AutoComplete class="customAutoComplete customInput"
              :placeholder="this.selectedPerson.length == 0 ? 'Email' : ''" v-model="selectedPerson"
              :suggestions="filteredUsers" style="height:100%" @complete="searchUsers($event)" multiple
              :forceSelection="true" />
          </div>
        </div>
        <div v-if="selectedField != null && selectedField.id == 7" class="flex">
          <div class="p-float-label ml-3" style="width:600px">
            <MultiSelect style="height:100%" v-model="selectedCompany" :options="companyFieldsOptions" optionLabel="label"
              optionGroupLabel="label" optionGroupChildren="children" :filter="true" ref="selectedCompany" />
            <label>{{ this.$langGetValue("company") }}</label>
          </div>
        </div>
        <div v-if="selectedField != null" class="text-left ml-3">
          <button class="primaryButton" :disabled="loadingIconLocal" @click="search"
            :title="this.$langGetValue('menuButtonSearch')" severity="secondary">
            <i :class="{
              'pi pi-search': loadingIconLocal == false,
              'pi pi-spin pi-spinner': loadingIconLocal == true,
            }" style="font-weight: 900"></i>
          </button>
        </div>
      </div>
      <div v-if="selectedField != null && selectedField.id == 3" class="mt-3">
        <HierarchyComponent mode="search" :isInvalid="false" selection="multiple" size="430px" type="originated"
          @returned-areas="setAreas" :selectedAreas="selectedAreas"></HierarchyComponent>
      </div>
      <!-- <div>
                <Button style="color: rgb(121, 120, 120)" @click="buildFilter" class="p-button p-component p-button-link"
                    label="Filtro avançado" />
            </div> -->
      <div v-if="incidentListReturn" v-show="localLength > 0" class="mt-3">
        <div v-if="this.checkRole(['team.op-risk'])">

          <div class="card flex flex-column justify-content-start"
            style="margin: 24px 0px 24px 0px; background-color: #f8f8f8">
            <div class="flex align-items-center justify-content-between" @click="setSummaryIncidentsSwitch"
              style="cursor: pointer">
              <div class="flex align-items-center">
                <i v-if="summaryDisable == false" class="pi pi-bolt" style="
                  font-size: 1.5rem;
                  color: #195ab4;
                  padding: 0px 8px 0px 0px;
                "></i>
                <i v-else-if="summaryDisable == true" class="pi pi-bolt" style="
                  font-size: 1.5rem;
                  color: #7a7a7a;
                  padding: 0px 8px 0px 0px;
                "></i>
                <span v-if="summaryDisable == false" style="color: #373737; font-weight: 700; font-size: 16px">{{
                  this.$langGetValue("summaryTitleActive") }}</span>
                <span v-else-if="summaryDisable == true" style="color: #7a7a7a; font-weight: 700; font-size: 16px">{{
                  this.$langGetValue("summaryTitleDisabled") }}</span>
              </div>
              <div v-if="summaryDisable == false" class="flex align-items-center">
                <span v-if="summaryActive == false" style="
                  color: #484848;
                  font-weight: 500;
                  font-size: 14px;
                  padding-right: 8px;
                ">{{ this.$langGetValue("seeSummary") }}</span>
                <span v-else-if="summaryActive == true" style="
                  color: #484848;
                  font-weight: 500;
                  font-size: 14px;
                  padding-right: 8px;
                ">{{ this.$langGetValue("closeSummary") }}</span>
                <i v-if="summaryActive == false" class="pi pi-angle-down" style="font-size: 1.2rem; color: #373737"></i>
                <i v-else-if="summaryActive == true" class="pi pi-angle-up" style="font-size: 1.2rem; color: #373737"></i>
              </div>
            </div>
            <Divider style="margin-top: 24px; padding: 0px; color: #484848;" v-if="summaryActive == true" />
            <div v-if="summaryActive == true" style="
              margin-top: 8px;
              color: #484848;
              font-weight: 500;
              font-size: 14px;
              line-height: 150%;
            " v-html="incidentSummary"></div>
          </div>
          <Divider style="margin-bottom: 16px" />

        </div>

        <Accordion ref="results" class="customAccordionTab1" v-model:activeIndex="currentIndexTab">
          <AccordionTab style="background-color: #f9f9f9">
            <template #header>
              <div style="width: 100%" class="flex align-items-center justify-content-between">
                <div>
                  <b>{{ this.$langGetValue("result") }}:</b>
                </div>
                <div style="font-weight: normal">
                  <i>{{ this.localLength }}
                    {{ this.$langGetValue("correspondingIncidents") }}</i>
                </div>
              </div>
            </template>
            <!-- @page-items-length="printNumberPages" -->
            <IncidentResult :analyticsOnSearch="true" :query="query" buttonMode="onlyView" @update-length="updateLength"
              @page-data="handlePageData">
            </IncidentResult>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <div style="margin-top: 20px">
      <div id="builder"></div>
    </div>
  </div>
  <div>
    <div class="mt-2" v-if="searchIncidentReturn">
      <div v-if="isBdpo == false">
        <IncidentComponent :incidentData="incidentData" :incidentMode="incidentMode" @incident-data="handleIncidentData">
        </IncidentComponent>
      </div>
      <div v-else>
        <div @click="returnToIncident"
          style="display: flex; flex-direction: row; align-items: center; justify-content: start; gap: 8px; color: var(--color_brand_medium); margin: 24px 0px 24px 0px; cursor: pointer">
          <i class="pi pi-angle-left"></i>
          <div class="body2" style="color: var(--color_brand_medium)">
            {{ this.$langGetValue("returnToIncident") }}
          </div>
        </div>
        <div
          style="display: flex; flex-direction: column; justify-content: center; align-items: center; padding: 24px 0px;">
          <h2 class="h2">
            <!-- Incidente -->
            {{ this.$langGetValue("incident") }}
          </h2>
          <div div style="display: flex; flex-direction: row; justify-content: center; align-items: center;">
            <Skeleton v-if="this.incidentRisk != null" v-tooltip.top="{ value: this.incidentRisk }" shape="circle"
              size="1rem" class="mr-2 mt-1" :style="{
                'background-color': this.incidentRiskColor, 'margin-left': '8px'
              }">
            </Skeleton>
            <div class="h3">
              {{ this.incidentLabel }}
            </div>
          </div>
        </div>
        <Accordion :activeIndex=1>
          <AccordionTab>
            <template #header style="display: flex; align-items: center;">
              <div class="h3" style="padding-left: 8px;">
                {{ this.$langGetValue("generalData") }}
              </div>
            </template>
            <p>
              <IncidentComponent :incidentData="incidentData" :incidentMode="incidentMode"
                @incident-data="handleIncidentData"></IncidentComponent>
            </p>
          </AccordionTab>
          <AccordionTab v-if="checkBDPORole()">
            <template #header>
              <div class="h3" style="padding-left: 8px;">
                {{ this.$langGetValue("bdpoField") }}
              </div>
            </template>
            <p style="padding-bottom: 40px;">
              <BdpoComponent :query="incidentData" />
            </p>
          </AccordionTab>

        </Accordion> 
      </div>
    </div>
  </div>
</template>

<script>
import HierarchyComponent from "../components/HierarchyComponent.vue";
import IncidentComponent from "../components/IncidentComponent.vue";
import IncidentService from "../service/IncidentService.js";
import FieldService from "../service/FieldService.js";
import $ from "jquery";
import "jQuery-QueryBuilder";
import IncidentResult from "../components/IncidentResult.vue";
import EventBus from "../AppEventBus";
import router from "../router.js";
import AnalyticsService from "../service/AnalyticsService.js";
import UserService from "../service/UserService.js";
import LlmService from "../service/LlmService.js";
import GraphService from "../service/GraphService"
import BdpoComponent from "../components/BdpoComponent.vue"

export default {
  components: {
    IncidentComponent,
    IncidentResult,
    HierarchyComponent,
    BdpoComponent,
  },
  data() {
    return {
      isBdpo: false,
      text: "Texto aleatório para testar a rota do summary",
      summaryActive: false,
      summaryDisable: false,
      loadingIconLocal: false,
      summaryIDs: [],
      pageNumber: null,
      localLength: 0,
      firstClickSummarySwitch: false,
      newPageResults: false,
      query: null,
      selectedHashtags: [],
      filteredHashtags: [],
      incidentListReturn: false,
      hashtags: [],
      incidentMode: "view",
      dateOptions: [
        { name: this.$langGetValue("discovery"), field: "dateOfDiscovery" },
        { name: this.$langGetValue("occurrence"), field: "dateOfOccurrence" },
        { name: this.$langGetValue("record"), field: "submittedTimestamp" },
      ],
      personFieldsOptions: [
        { person: this.$langGetValue("requestedBy"), field: "requestedBy" },
        { person: this.$langGetValue("registeredBy"), field: "registeredBy" }
      ],
      selectedPerson: [],
      selectedPersonField: null,
      companyFieldsOptions: [],
      selectedCompany: null,
      filteredUsers: [],
      selectedDate: null,
      currentIndexTab: null,
      initialDate: null,
      finalDate: null,
      selectedAreas: null,
      advancedFilter: false,
      incidentID: [],
      areaHierarchy: null,
      searchIncidentReturn: false,
      incidentData: null,
      selectedField: { name: "ID", id: 1 },
      generalSearch: [],
      searchfields: [
        { name: "ID", id: 1 },
        { name: this.$langGetValue("searchOptionDate"), id: 2 },
        { name: this.$langGetValue("searchOptionArea"), id: 3 },
        { name: this.$langGetValue("searchOptionDescription"), id: 4 },
        { name: "Hashtag", id: 5 },
        { name: this.$langGetValue("person"), id: 6 },
        { name: this.$langGetValue("company"), id: 7 },
      ],
      incidentList: [],
      selectAreaChip: [],
      expandedAreas: {},
      intervalExpandKeys: null,
      lastFilterValueIBUs: "",
      hierarchyArray: [],
      incidentSummary: "",
      intervalOne: null,
      intervalTwo: null,
      incidentLabel: "",
      incidentRiskColor: "",
      incidentRisk: "",
    };
  },
  created() {
    EventBus.on("searchIncidentByID", this.searchIncidentByID);
  },
  unmounted() { },
  mounted() {
    this.hasUrlId();
    this.hashtags = FieldService.getField('incident','hashtag');

    this.companyFieldsOptions = FieldService.getField('incident','company');

    if (UserService.checkUserRoleAny(['team.op-risk'])) {
      this.personFieldsOptions.push({ person: this.$langGetValue("reviewedBy"), field: "reviewedBy" })
    }
  },

  watch: {
    initialDate() {
      this.finalDate = null;
    },
    selectedField() {
      this.incidentListReturn = false;
      this.searchIncidentReturn = false;
    },
  },
  methods: {
    checkRole(array) {
      return UserService.checkUserRoleAny(array)
    },
    returnToIncident() {
      location.reload();
    },
    checkBDPORole() {
      if (
        this.checkRole(['*.storm.incident.*.bdpo-fill'])
      ) {
        return true;
      }
      return false;
    },
    handleIncidentData(incidentLabel, incidentRisk, incidentRiskColor) {
      this.incidentLabel = incidentLabel;
      this.incidentRisk = incidentRisk;
      this.incidentRiskColor = incidentRiskColor;
    },
    searchUsers(event, filter = "users") {
      let tempArray = [];

      GraphService.searchUser(event.query, filter).then((response) => {
        response.forEach((user) => {
          if (user.mail != "") {
            tempArray.push(user.mail.toLowerCase());
          }
        });
        this.filteredUsers = tempArray;
      });
    },
    handlePageData(list) {
      this.resultNumberOfRows = list.length;
      this.summaryIDs = list.slice();

      if (this.summaryIDs.length > 2 && this.summaryIDs.length <= 50) {
        this.summaryDisable = false;
        if (this.summaryActive == true) {
          this.summaryIncidents(this.summaryIDs);
        }
      } else {
        this.summaryActive = false;
        this.summaryDisable = true;
        this.incidentSummary = "";
      }
    },

    setSummaryIncidentsSwitch() {
      if (this.summaryDisable != true) {
        this.summaryActive = !this.summaryActive;
      }

      if (this.summaryActive == true && this.incidentSummary == "") {
        this.summaryIncidents(this.summaryIDs);
      }
    },

    handlePaste() {
      let tempArray = [];

      this.incidentID.forEach((id) => {
        if (id.includes("\r")) {
          let brokenIds = id.split("\r");

          brokenIds.forEach((brokenId) => {
            // let tempVar = brokenId.replace(/\n/g, '')

            let tempVar = brokenId.replace(/[^0-9]/g, "");

            if (tempVar != "") {
              tempArray.push(tempVar);
            }
          });
        } else {
          tempArray.push(id.replace(/[^0-9]/g, ""));
        }
      });
      this.incidentID = tempArray;
    },
    // Areas
    setAreas(value) {
      this.selectedAreas = value;
    },
    //
    buildFilter() {
      var rules_basic = {
        condition: "AND",
        rules: [
          {
            id: "price",
            operator: "less",
            value: 10.25,
          },
          {
            condition: "OR",
            rules: [
              {
                id: "category",
                operator: "equal",
                value: 2,
              },
              {
                id: "category",
                operator: "equal",
                value: 1,
              },
            ],
          },
        ],
      };

      $("#builder").queryBuilder({
        filters: [
          {
            id: "name",
            label: "Name",
            type: "string",
          },
          {
            id: "category",
            label: "Category",
            type: "integer",
            input: "select",
            values: {
              1: "Books",
              2: "Movies",
              3: "Music",
              4: "Tools",
              5: "Goodies",
              6: "Clothes",
            },
            operators: [
              "equal",
              "not_equal",
              "in",
              "not_in",
              "is_null",
              "is_not_null",
            ],
          },
          {
            id: "in_stock",
            label: "In stock",
            type: "integer",
            input: "radio",
            values: {
              1: "Yes",
              0: "No",
            },
            operators: ["equal"],
          },
          {
            id: "price",
            label: "Price",
            type: "double",
            validation: {
              min: 0,
              step: 0.01,
            },
          },
          {
            id: "id",
            label: "Identifier",
            type: "string",
            placeholder: "____-____-____",
            operators: ["equal", "not_equal"],
            validation: {
              format: /^.{4}-.{4}-.{4}$/,
            },
          },
        ],

        rules: rules_basic,
      });

      this.advancedFilter = true;
    },
    updateLength(value) {
      this.localLength = value;
      this.loadingIconLocal = false;
    },
    summaryIncidents(list) {
      clearInterval(this.intervalOne);
      clearInterval(this.intervalTwo);

      this.incidentSummary = " .";

      let index = 0;

      this.intervalOne = setInterval(() => {
        this.incidentSummary = this.incidentSummary + ".";

        index = index + 1;

        if (index == 4) {
          index = 0;
          this.incidentSummary = " .";
        }
      }, 200);

      LlmService.summaryIncidents(list).then((response) => {
        clearInterval(this.intervalOne);
        this.incidentSummary = "";
        let tempSummary = response.data;

        this.intervalTwo = setInterval(() => {
          let index = this.incidentSummary.length;

          this.incidentSummary =
            this.incidentSummary +
            tempSummary.substring(
              index,
              index + Math.floor(Math.random() * 6 + 6)
            );

          if (this.incidentSummary.length == tempSummary.length) {
            clearInterval(this.intervalTwo);
          }
        }, Math.floor(Math.random() * 50 + 40));
      });
    },
    searchHashtags(event) {
      setTimeout(() => {
        if (!event.query.trim()?.length) {
          this.filteredHashtags = this.incidentOptions.hashtags;
        } else {
          let tempArray = [];
          this.hashtags.forEach((hashtag) => {
            if (!hashtag.suggestion) {
              if (
                hashtag.filter.keyword.some((filter) =>
                  filter.includes(event.query.toLowerCase())
                )
              ) {
                tempArray.push(hashtag);
              }
            }
          });
          this.filteredHashtags = tempArray;
        }
      }, 250);
    },
    hasUrlId() {
      if (this.$route.query.id != undefined) {
        this.incidentID.push(this.$route.query.id);
        this.selectedField = { name: "ID", id: 1 };

        if (this.$route.query.mode != undefined && this.$route.query.bdpo === undefined) {
          this.incidentMode = this.$route.query.mode;
        }

        if (this.$route.query.bdpo != undefined) {
          this.isBdpo = this.$route.query.bdpo;
        }

        AnalyticsService.send(
          router.currentRoute._value.name,
          UserService.user,
          { id: this.incidentID, mode: this.incidentMode }
        );

        this.searchIncidentByID(this.incidentID, this.incidentMode);
      }
    },
    search() {
      this.loadingIconLocal = true;

      this.incidentListReturn = false;
      this.searchIncidentReturn = false;

      switch (this.selectedField.id) {
        //ID
        case 1:
          if (this.incidentID == null || this.incidentID.length == 0) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          if (this.incidentID.length == 1) {
            this.searchIncidentByID(this.incidentID);
          } else {
            let tempId = [];

            this.incidentID.forEach((id) => {
              tempId.push(parseInt(id));
            });

            var payloadId = {
              sqlWhereClause: {
                rules: [
                  {
                    field: "id",
                    type: "integer",
                    operator: "in",
                    value: tempId,
                  },
                ],
              },
            };

            this.query = payloadId;

            this.currentIndexTab = 0;
            this.loadSearchResult();
          }

          break;

        // Data
        case 2:
          if (
            this.initialDate == null ||
            this.initialDate == "" ||
            this.finalDate == null ||
            this.finalDate == "" ||
            this.selectedDate == null
          ) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var payloadDate = {
            sqlWhereClause: {
              rules: [
                {
                  condition: "AND",
                  field: this.selectedDate.field,
                  type: "date",
                  input: "text",
                  operator: "between",
                  value: [this.initialDate, this.finalDate],
                },
              ],
            },
          };

          this.query = payloadDate;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

        // area
        case 3:
          if (this.selectedAreas == null || this.selectedAreas.length == 0) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var tempArrayArea = [];

          this.selectedAreas.forEach((area) => {
            tempArrayArea.push(area.id);
          });

          var payloadArea = {
            sqlWhereClause: {
              condition: "AND",
              rules: [
                {
                  id: "id",
                  field: "hierarchy",
                  type: "integer",
                  input: "text",
                  operator: "in",
                  value: tempArrayArea,
                },
              ],
            },
          };

          this.query = payloadArea;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

        // Descrição
        case 4:
          if (this.generalSearch.length == 0) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var payloadDescription = {
            sqlWhereClause: {
              condition: "AND",
              rules: [],
            },
          };

          this.generalSearch.forEach((word) => {
            payloadDescription.sqlWhereClause.rules.push({
              field: "description",
              type: "string",
              input: "text",
              operator: "contains",
              value: word,
            });
          });

          this.query = payloadDescription;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

        //Hashtag
        case 5:
          if (this.selectedHashtags.length == 0) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var tempArrayHashtag = [];

          this.selectedHashtags.forEach((hashtag) => {
            tempArrayHashtag.push(hashtag.id);
          });

          var payloadHashtag = {
            sqlWhereClause: {
              rules: [
                {
                  id: "id",
                  field: "hashtag",
                  type: "integer",
                  input: "text",
                  operator: "in",
                  value: tempArrayHashtag,
                },
              ],
            },
          };

          this.query = payloadHashtag;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

        //Pessoa
        case 6:
          if (
            this.selectedPerson.length == 0 ||
            this.selectedPersonField == null
          ) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var payloadPerson = {
            sqlWhereClause: {
              condition: "OR",
              rules: [],
              orderBy: [
                {
                  field: "id",
                  direction: "DESC"
                }
              ],
            },
          };

          this.selectedPerson.forEach((person) => {
            payloadPerson.sqlWhereClause.rules.push({
              field: this.selectedPersonField.field,
              type: "string",
              operator: "equal",
              value: person,
            });
          });

          this.query = payloadPerson;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

        //Empresa
        case 7:
          if (
            this.selectedCompany == null
          ) {
            this.loadingIconLocal = false;
            this.$toast.add({
              severity: "error",
              summary: this.$langGetValue("toast5"),
              detail: this.$langGetValue("toast5Sub"),
              life: 3000,
            });
            return;
          }

          var tempArrayCompany = []

          this.selectedCompany.forEach((company) => {
            tempArrayCompany.push(company.id)
          })

          var payloadCompany = {
            sqlWhereClause: {
              rules: [{
                field: "company",
                type: "integer",
                operator: "in",
                value: tempArrayCompany,
              }],
            },
          };


          this.query = payloadCompany;

          this.currentIndexTab = 0;
          this.loadSearchResult();

          break;

      }
    },
    searchIncidentByID(incidentID, incidentMode) {
      if (this.selectedField.id != 1) {
        this.currentIndexTab = null;
      }

      IncidentService.getIncident(incidentID).then((resp) => {
        this.loadingIconLocal = false;
        if (resp.success == true) {
          this.incidentData = resp.data;
          this.incidentMode = incidentMode;
          this.loadIncident(incidentID);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$langGetValue("toast8"),
            detail: this.$langGetValue("toast8Sub"),
            life: 3000,
          });
          this.searchIncidentReturn = false;
          return;
        }
      });
    },
    async loadIncident(incidentID) {
      this.$router.push({ query: { id: incidentID } });

      // Remove MyComponent from the DOM
      this.searchIncidentReturn = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.searchIncidentReturn = true;
    },
    async loadSearchResult() {
      // Remove MyComponent from the DOM
      this.incidentListReturn = false;

      // Wait for the change to get flushed to the DOM
      await this.$nextTick();

      // Add the component back in
      this.incidentListReturn = true;
    },
  },
};
</script>

<style>
.customLabel,
.customInput,
.p-inputtext {
  width: 100% !important;
}

.customAccordionTab1 .p-accordion-tab .p-toggleable-content .p-accordion-content {
  padding: 0px;
}

.query-builder,
.query-builder * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.query-builder {
  font-family: sans-serif;
}

.query-builder .hide {
  display: none;
}

.query-builder .pull-right {
  float: right !important;
}

.query-builder .btn {
  text-transform: none;
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.query-builder .btn.focus,
.query-builder .btn:focus,
.query-builder .btn:hover {
  color: #333;
  text-decoration: none;
}

.query-builder .btn.active,
.query-builder .btn:active {
  background-image: none;
  outline: 0px none;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.125) inset;
}

.query-builder .btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.query-builder .btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.query-builder .btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.query-builder .btn-success.active,
.query-builder .btn-success.focus,
.query-builder .btn-success:active,
.query-builder .btn-success:focus,
.query-builder .btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.query-builder .btn-primary.active,
.query-builder .btn-primary.focus,
.query-builder .btn-primary:active,
.query-builder .btn-primary:focus,
.query-builder .btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.query-builder .btn-danger.active,
.query-builder .btn-danger.focus,
.query-builder .btn-danger:active,
.query-builder .btn-danger:focus,
.query-builder .btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.query-builder .btn-group {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.query-builder .btn-group>.btn {
  position: relative;
  float: left;
}

.query-builder .btn-group>.btn:first-child {
  margin-left: 0px;
}

.query-builder .btn-group>.btn:first-child:not(:last-child) {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.query-builder .btn-group>.btn:last-child:not(:first-child) {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.query-builder .btn-group .btn+.btn,
.query-builder .btn-group .btn+.btn-group,
.query-builder .btn-group .btn-group+.btn,
.query-builder .btn-group .btn-group+.btn-group {
  margin-left: -1px;
}

.query-builder .btn-xs,
.query-builder .btn-group-xs>.btn {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.p-accordion .p-accordion-tab {
  margin: 0px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
  background-color: var(--color_gray_xlight);
  border-color: var(--color_gray_light);
  padding: 24px 24px
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
  background-color: var(--color_brand_xlight);
  border-color: var(--color_gray_light);
}

.p-accordion .p-accordion-content {
  border-radius: 0px;
  border-width: 0px 0px 1px 0px;
  background-color: transparent;
}

.customAutoComplete .p-autocomplete-multiple-container {
  gap: 4px;
}
</style>
