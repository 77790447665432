<template>
    <div style="display: flex; flex-direction: column;">
        <div class="h2" style="display: flex; justify-content: center; margin: 48px 0px 64px 0px ">
            {{ this.$langGetValue("biaCampaign") }}
        </div>
        <div style="display: flex; flex-direction: column;">
            <div style="display: flex; width: 100%; border-width: 0px 0px 3px 0px; align-items: center;">
                <SelectButton v-model="biaMenuSelected" :options="biaMenu" dataKey="id" aria-labelledby="custom"
                    style="width: 100%">
                    <template #option="slotProps">
                        <div class="mr-2 font-medium">
                            {{ slotProps.option.name }}
                        </div>
                        <i v-badge.info="slotProps.option.size" class="pi pi-inbox"></i>
                    </template>
                </SelectButton>
            </div>
            <div style="display: flex; flex-direction: column;">
                <div v-show="biaMenuSelected != null && biaMenuSelected.id == 1">
                    <Button
                        v-if="this.filterOpenedOnGoing == false && this.filterActiveOnGoing == false && this.biaMenu[0].size > 0"
                        @click="setFilterState" class="secondaryButton" :label="this.$langGetValue('filter')" icon="pi pi-filter"
                        style="margin-top: 16px" />
                    <div v-if="this.filterOpenedOnGoing == true"
                        style="display: flex; gap: 16px; align-items: center; width: 100%; justify-content: space-between; margin-top: 16px;">
                        <Button @click="removeFilter" class="secondaryButton" icon="pi pi-times"
                            style="height: 44px; width: 48px;" />
                        <MultiSelect v-model="areasFilterSelectedOnGoing" display="chip" :options="campaignAreas" filter
                            optionLabel="label" :placeholder="this.$langGetValue('biaFilterAreas')"
                            style="width: 100%;" />
                        <Button @click="filterAreas" class="primaryButton" icon="pi pi-search"
                            style="height: 44px; width: 48px;" />
                    </div>
                    <div v-if="this.filterOpenedOnGoing == false && this.filterActiveOnGoing == true"
                        style="display: flex; align-items: center; width: 100%; justify-content: start; margin-top: 16px;">
                        <Chip icon="pi pi-trash" style="
                          background-color: transparent;
                          color: #eb3d47;
                          padding: 0px;
                          font-weight: 600;
                          cursor: pointer;
                        " label="LIMPAR" @click="removeFilter" />
                        <Divider layout="vertical" style="
                          height: 100%;
                        " />
                        <div v-if="this.areasFilterSelectedOnGoing.length > 0"
                            style="width: 80%; display: flex; flex-direction: column; gap: 8px">
                            <div class="body3">
                                {{ this.$langGetValue('biaFilteredAreas') }}
                            </div>
                            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%;">
                                <Chip class="secondaryChip" v-for="(area, index) in areasFilterSelectedOnGoing" :key="index"
                                    :label="area.label"></Chip>
                            </div>
                        </div>
                    </div>
                    <Divider v-if="this.filterOpenedOnGoing == false && this.filterActiveOnGoing == true"
                        style=" width: 100%; " />

                    <DataTable class="customDataTable" :value="biaQueryAndamento" v-show="biaMenuSelected != null && biaMenuSelected.id == 1"
                        v-if="this.biaQueryAndamento.length > 0"
                        style="display: flex; flex-direction: column; width: 100%; margin-top: 24px; gap: 1px">
                        <Column field="header">
                            <template #body="slotProps" style=" width: 100%;">
                                <div style=" width: 100%;">
                                    <BiaCampaingResults :dataCampaign="slotProps.data" :hierarchy="allAreasList" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>

                    <div v-show="biaMenuSelected != null && biaMenuSelected.id == 1 && this.biaQueryAndamento.length == 0"
                        style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px; padding-top: 16px;" />
                        <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                            {{ this.$langGetValue('biaEmpty') }}
                        </div>
                    </div>
                </div>
                <div v-show="biaMenuSelected != null && biaMenuSelected.id == 2">
                    <Button
                        v-if="this.filterOpenedFinalized == false && this.filterActiveFinalized == false && this.biaMenu[1].size > 0"
                        @click="setFilterState" class="secondaryButton" label="Filtrar" icon="pi pi-filter"
                        style="margin-top: 16px" />
                    <div v-if="this.filterOpenedFinalized == true"
                        style="display: flex; gap: 16px; align-items: center; width: 100%; justify-content: start; margin-top: 16px;">
                        <Button @click="removeFilter" class="secondaryButton" icon="pi pi-times"
                            style="height: 44px; width: 48px;" />
                        <MultiSelect v-model="areasFilterSelectedFinalized" display="chip" :options="campaignAreas" filter
                            optionLabel="label" :placeholder="this.$langGetValue('biaFilterAreas')"
                            style="width: 100%;" />
                        <Button @click="filterAreas" class="primaryButton" icon="pi pi-search"
                            style="height: 44px; width: 48px;" />
                    </div>
                    <div v-if="this.filterOpenedFinalized == false && this.filterActiveFinalized == true"
                        style="display: flex; align-items: center; width: 100%; justify-content: start; margin-top: 16px;">
                        <Chip icon="pi pi-trash" style="
                          background-color: transparent;
                          color: #eb3d47;
                          padding: 0px;
                          font-weight: 600;
                          cursor: pointer;
                        " label="LIMPAR" @click="removeFilter" />
                        <Divider layout="vertical" style="
                          height: 100%;
                        " />
                        <div v-if="this.areasFilterSelectedFinalized.length > 0"
                            style="width: 80%; display: flex; flex-direction: column; gap: 8px">
                            <div class="body3">
                                {{ this.$langGetValue('biaFilteredAreas') }}
                            </div>
                            <div class="flex-wrap" style="display: flex; gap: 8px; width: 100%;">
                                <Chip class="secondaryChip" v-for="(area, index) in areasFilterSelectedFinalized"
                                    :key="index" :label="area.name"></Chip>
                            </div>
                        </div>
                    </div>
                    <Divider v-if="this.filterOpenedFinalized == false && this.filterActiveFinalized == true"
                        style=" width: 100%; " />

                    <DataTable class="customDataTable" :value="biaQueryFinalizado" v-show="biaMenuSelected != null && biaMenuSelected.id == 2"
                        v-if="this.biaQueryFinalizado.length > 0"
                        style="display: flex; flex-direction: column; width: 100%; margin-top: 24px; gap: 1px">
                        <Column field="header">
                            <template #body="slotProps" style=" width: 100%;">
                                <div style=" width: 100%;">
                                    <BiaCampaingResults :dataCampaign="slotProps.data" :hierarchy="allAreasList" />
                                </div>
                            </template>
                        </Column>
                    </DataTable>

                    <div v-show="biaMenuSelected != null && biaMenuSelected.id == 2 && this.biaQueryFinalizado.length == 0"
                        style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px; padding-top: 16px;" />
                        <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                            {{ this.$langGetValue('biaEmpty') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import "../Global.css";
import BiaCampaingResults from "../components/BiaCampaingResults.vue"
import UserService from "../service/UserService";
import BiaService from "../service/BiaService.js"
import Util from "../service/Util";
// import IncidentService from "../service/IncidentService.js"
import FieldService from "../service/FieldService"

export default {
    components: {
        BiaCampaingResults,
    },
    data() {
        return {
            biaMenuSelected: null,
            biaMenu: [
                { name: this.$langGetValue("biaCampaignStatus1"), id: 1, size: 0 },
                { name: this.$langGetValue("biaCampaignStatus2"), id: 2, size: 0 },
            ],
            emAndamentoSize: 0,
            finalizadoSize: 0,
            areasFilterSelectedOnGoing: [],
            areasFilterSelectedFinalized: [],
            allAreasList: [],
            areaHierarchy: FieldService.getField('bia','associatedArea'),
            campaignAreas: null,
            filterActiveOnGoing: false,
            filterOpenedOnGoing: false,
            filterActiveFinalized: false,
            filterOpenedFinalized: false,
            biaQuery: null,
            biaQueryAndamento: [],
            biaQueryFinalizado: [],
        };
    },
    watch: {
        biaQueryAndamento(newValue) {
            this.biaMenu[0].size = newValue.length
        },
        biaQueryFinalizado(newValue) {
            this.biaMenu[1].size = newValue.length
        },
    },
    async mounted() {

        await this.populateHierarchyArray();

        await BiaService.getBias().then((response) => {
            if (response.data != null) {
                var tempCampaignAreas = []

                response.data.forEach((campaign) => {
                    campaign.data.areaId.forEach((area) => {
                        tempCampaignAreas.push(area)
                    })
                })

                const uniqueCampaignAreas = [...new Set(tempCampaignAreas)];

                this.campaignAreas = this.allAreasList.filter(area => uniqueCampaignAreas.includes(area.id));

                if (UserService.checkUserRoleAny(['*.storm.bia.*.manage'])) {
                    this.biaQuery = response.data
                }
                else {
                    var tempArray = []

                    response.data.forEach((campaign) => {
                        if (this.checkPermission(campaign.data)) {
                            tempArray.push(campaign)
                        }
                    })

                    this.biaQuery = tempArray
                }

                this.filterQueries();
            }
        })

        this.biaMenuSelected = this.biaMenu[0];

    },
    methods: {
        async populateHierarchyArray() {
            function visitNodes(tree, path = []) {
                if (!tree) {
                    return [];
                }

                path.push(tree.label);
                const info = {
                    id: tree.id,
                    label: tree.label,
                    path: path.join(" -> "),
                    selectable: tree.selectable,
                };

                const result = [info];

                if (tree.children && tree.children?.length > 0) {
                    for (const children of tree.children) {
                        result.push(...visitNodes(children, path.slice()));
                    }
                }

                return result;
            }

            for (let i = 0; i < this.areaHierarchy?.length; i++) {
                this.allAreasList = this.allAreasList.concat(
                    visitNodes(this.areaHierarchy[i])
                );
            }

            return true
        },
        checkPermission(data) {
            if (data.campaignUsers.includes(UserService.user)) {
                return true
            }

            return false
        },
        filterQueries() {
            Util.loading = true

            if (this.biaQuery != null) {
                this.biaQueryAndamento = this.biaQuery.filter(item => item.status == 1)
                this.biaQueryFinalizado = this.biaQuery.filter(item => item.status == 2)
            }

            Util.loading = false
        },
        setFilterState() {
            if (this.biaMenuSelected.id == 1) {
                this.filterOpenedOnGoing = !this.filterOpenedOnGoing;
                this.areasFilterSelectedOnGoing = [];
            } else if (this.biaMenuSelected.id == 2) {
                this.filterOpenedFinalized = !this.filterOpenedFinalized;
                this.areasFilterSelectedFinalized = [];
            }
        },
        removeFilter() {
            if (this.biaMenuSelected.id == 1) {
                this.filterOpenedOnGoing = false;
                this.filterActiveOnGoing = false;
                this.areasFilterSelectedOnGoing = [];
                this.biaQueryAndamento = []

            } else if (this.biaMenuSelected.id == 2) {
                this.filterActiveFinalized = false;
                this.filterOpenedFinalized = false;
                this.areasFilterSelectedFinalized = [];
                this.biaQueryFinalizado = []
            }

            setTimeout(() => {
                this.filterQueries()
            }, 200)
        },
        filterAreas() {
            var tempQuery = [];

            if (this.areasFilterSelectedOnGoing.length > 0 && this.biaMenuSelected.id == 1 && this.biaMenu[0].size > 0) {
                this.filterOpenedOnGoing = false;
                this.filterActiveOnGoing = true;
                this.filterActiveFinalized = false;
                this.areasFilterSelectedFinalized = [];

                this.biaQueryAndamento.forEach((campaign) => {
                    if (this.areasFilterSelectedOnGoing.some(area => campaign.data.areaId.includes(area.id))) {
                        tempQuery.push(campaign)
                    }
                })

                this.biaQueryAndamento = []

                setTimeout(() => {
                    this.biaQueryAndamento = tempQuery;
                }, 200)

            } else if (this.areasFilterSelectedFinalized.length > 0 && this.biaMenuSelected.id == 2 && this.biaMenu[1].size > 0) {
                this.filterOpenedFinalized = false;
                this.filterActiveOnGoing = false;
                this.filterActiveFinalized = true;
                this.areasFilterSelectedOnGoing = [];

                this.biaQueryFinalizado.forEach((campaign) => {
                    if (this.areasFilterSelectedFinalized.some(area => campaign.data.areaId.includes(area.id))) {
                        tempQuery.push(campaign)
                    }
                })

                this.biaQueryFinalizado = []

                setTimeout(() => {
                    this.biaQueryFinalizado = tempQuery;
                }, 200)

            }
        },
    },
};
</script>
<style>
.customDataTable th {
    display: none;
}

.customDataTable .p-datatable-tbody tr td {
    padding-top: 16px;
    padding-bottom: 16px;
}

.customDataTable .p-datatable-loading-overlay {
    display: none;
}

.customDataTable .p-datatable-row-expansion {
    background: #f7f7f7 !important;
}

.p-buttonset .p-button {
    color: var(--color_brand_xdark);
    min-width: 25%;
    border-radius: 0px;
    font-size: 16px;
    font-weight: regular;
    justify-content: center;
    border-width: 0px 0px 2px 0px;
    border-color: var(--color_gray_light);
    border-style: solid;
    padding: 24px 0px 24px 0px
}

.p-selectbutton,
.p-buttonset {
    border-width: 0px 0px 2px 0px;
    border-color: var(--color_brand_medium);
}

.p-badge.p-badge-info {
    background-color: var(--color_brand_medium);
}

.p-buttonset .p-button:focus,
.buttonTab:focus {
    outline: none;
    box-shadow: none;
}

.p-selectbutton .p-button.p-highlight,
.p-selectbutton .p-button.p-highlight:hover {
    color: var(--color_brand_xdark);
    background-color: white;
    border-color: var(--color_brand_medium);
}

.p-selectbutton .p-button.p-highlight:hover {
    background-color: var(--color_brand_xlight);
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    background-color: var(--color_gray_xlight);
    color: var(--color_gray_xdark);
    border: 1px solid var(--color_gray_light);
    font-size: 12px;
    font-weight: 500;
    padding: 8px 16px;
    border-radius: 40px;
    width: fit-content;
    justify-content: center;
    align-items: center;
}

.p-chip .p-chip-text {
    margin: 0px
}
</style>
  