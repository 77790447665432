import BackendService from './BackendService';
import IncidentService from './IncidentService';

export default class UserService {

    static user = null
    static company = null
    static roles = {}
    static roUsers = []

    static getUser() {
        return BackendService.get("/auth/user").then(resp => {

            UserService.user = resp.data["mail"].toLowerCase()
            UserService.roles = resp.data.data.role
            UserService.company = Object.keys(UserService.roles)[0]
            console.log(UserService.company)
            console.log(UserService.user, UserService.roles)

            // BackendService.post("/auth/create", { mail: 'iude.meneses@btgpactual.com' }).then(resp => {
            //     localStorage.setItem("token", resp.data.accessToken)
            //     console.log(resp)
            // })
        })
    }

    static getRoUsers() {
        IncidentService.incidentForReviewCount().then((response) => {
            UserService.roUsers = Object.keys(response[UserService.company])
        })
    }

    static checkUserRoleAll(paths) {
        // let isAdmin = paths[0].split('.')
        // if (UserService.hasPermission('*.storm.' + isAdmin[2] + '.admin.*')) {
        //     return true
        // }

        let permissions = []

        for (let i = 0; i < paths.length; i++) {
            permissions.push(UserService.hasPermission(paths[i]))
        }

        if (permissions.includes(false)) {
            return false
        }

        return true
    }

    static checkUserRole(path) {
        // let isAdmin = paths[0].split('.')
        // if (UserService.hasPermission('*.storm.' + isAdmin[2] + '.admin.*')) {
        //     return true
        // }


        return UserService.hasPermission(path)
    }

    static checkUserRoleAny(paths) {
        // let isAdmin = paths[0].split('.')
        // if (UserService.hasPermission('*.storm.' + isAdmin[2] + '.admin.*')) {
        //     return true
        // }

        let permissions = []

        for (let i = 0; i < paths.length; i++) {
            permissions.push(UserService.hasPermission(paths[i]))
        }

        if (permissions.includes(true)) {
            return true
        }

        return false
    }

    static hasPermission(permissionPath) {
        // Divide a string de permissão em partes
        const parts = permissionPath.split('.');

        // Função interna para verificar a permissão
        function checkPermission(obj, idx) {
            if (idx >= parts.length) {
                return true;  // Chegou ao fim do caminho e a permissão existe
            }

            const part = parts[idx];

            if(Object.prototype.hasOwnProperty.call(obj, 'admin') && idx == 3 ) {
                return true
            }

            // Verifica se o trecho atual é '*'
            if (part === '*') {
                // Verifica todos os filhos do objeto atual
                for (const key in obj) {
                    if (checkPermission(obj[key], idx + 1)) {
                        return true;  // Permissão encontrada com wildcard
                    }
                }
                return false; // Nenhuma permissão encontrada
            } else {
                // Verifica se a parte atual é uma chave válida
                if (Object.prototype.hasOwnProperty.call(obj, part)) {
                    
                    return checkPermission(obj[part], idx + 1); // Avança para o próximo nível
                } else {
                    return false; // Chave não existe
                }
            }
        }
        return checkPermission(UserService.roles, 0);
    }

}
