
<template>
  <div
    class="card flex flex-column"
    style="background-color: rgb(39, 40, 34); color: white;min-height:88vh"
  >
    <div style="margin-bottom: 20px">Somente GET funcionando por enquanto!</div>
    <div style="margin-bottom: 20px; display: flex">
      <Dropdown
        v-model="selectedMethod"
        :options="methods"
        class="custom-dropdown"
        optionLabel="name"
        style="width: 118px; background-color: rgb(93 93 93)"
      />
      <InputText
        type="text"
        v-model="url"
        style="
          width: calc(100% - 284px);
          margin-left: 20px;
          margin-right: 20px;
          background-color: rgb(93 93 93);
          color: white;
        "
      />
      <Button label="Submit" @click="runRequest" style="width: 118px" />
    </div>
    <div class="grid flex-row" v-show="result != null">
      <VueJsonView style="width: calc(100% - 136px);" v-show="result != null" :src="result" />
    <img
        :src="dogImage"
        style="object-fit: cover; height: 200px;width: 126px; "
    />
    </div>
  </div>
</template>


<script>
import axios from "axios";
import TokenService from "../service/TokenService";
import VueJsonView from "@matpool/vue-json-view";
import Util from "../service/Util";

export default {
  components: {
    VueJsonView,
  },
  data() {
    return {
      url: "",
      dogImage: "",
      lastId: -1,
      result: null,
      selectedMethod: { name: "GET" },
      methods: [
        { name: "GET" },
        { name: "POST" },
        { name: "PUT" },
        { name: "PATCH" },
        { name: "DELETE" },
      ],
    };
  },
  mounted() {},
  unmounted() {},
  methods: {
    getDogImage() {
      let id = Util.randomInt(0, 4);
      for (; id == this.lastId; ) {
        id = Util.randomInt(0, 4);
      }
      this.dogImage = "images/brutus/brutus_" + id + ".png";
      this.lastId = id;
    },
    runRequest() {
        this.result=null;
      const config = {
        headers: {},
      };
      if (window.location.href.includes("localhost")) {
        config.headers["S-Auth"] = TokenService.token;
      }
      axios
        .get(this.url, config)
        .then((res) => {
          this.result = res.data;
      this.getDogImage();
        })
        .catch((error) => {
          console.log(error);
          this.result = error.response.data;
          this.getDogImage();
        });
    },
  },
};
</script>
<style>
.custom-dropdown .p-dropdown-label {
  color: white;
}
</style>