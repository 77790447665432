
import AnalyticsService from './AnalyticsService';
import BackendService from './BackendService';
import UserService from './UserService';

export default class IncidentService {
    static local = false
    static port = '4991'

    static incidentFields = null

    static getIncidentInfo(incident) {
        return BackendService.get("/incident/" + incident + "?fields=[\"incident_description\",\"risk_level\"]").then(resp => {
            return resp
        })
    }

    static linkHashtagSuggestion(incident, hashtags) {
        var payload = {
            "incident": incident,
            "hashtag": hashtags
        }
        return BackendService.post("/incident/hashtag/link/suggestion", payload).then(resp => {
            return resp
        })
    }

    static filterByHashtag(hashtags) {
        var payload = {
            "hashtags": hashtags
        }
        return BackendService.post("/incident/hashtag/filter", payload).then(resp => {
            return resp
        })
    }


    static incidentList(payload, analyticsOnSearch = true) {
        if (analyticsOnSearch) {
            AnalyticsService.send("search-query", UserService.user, { query: JSON.stringify(payload) })
        }
        return BackendService.post("/incident/list", payload).then(resp => {
            return resp
        })
    }

    static fowardIncident(list, id) {
        var payload = {
            "to": list,
            "incidentId": id
        }
        return BackendService.post("/incident/email/", payload).then(resp => {
            return resp
        })
    }

    static uploadAttachment(filename, content) {
        var payload = {
            name: filename,
            forceCreationDev: true,
            bytes: content
        }
        return BackendService.post("/incident/attachment/", payload).then(resp => {
            return resp
        })
    }

    static getAttachment(id) {
        return BackendService.get("/incident/attachment/" + id, true).then(resp => {
            return resp
        })
    }

    static getTableOptions(table) {
        return BackendService.get("/incident/field/storm/" + table).then(resp => {
            return resp
        })
    }

    static getFields() {
        return BackendService.get("/incident/field/storm/all").then(resp => {
            IncidentService.incidentFields = resp.data
            return true
        })
    }

    static getIncident(IncidentID) {
        return BackendService.get("/incident/" + IncidentID).then(resp => {
            return resp
        })
    }

    static deleteIncident(IncidentID) {
        return BackendService.delete("/incident/" + IncidentID).then(resp => {
            return resp
        })
    }

    static acceptedFiles() {
        return Promise.resolve([
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel.sheet.macroenabled.12",
            "application/vnd.ms-excel",
            "text/",
            "image/",
            "application/pdf",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
        ])
    }

    static distributeIncident(ids, emailUser) {
        let payload = {
            "ids": ids,
            "responsibleForReview": emailUser
        }

        return BackendService.patch("/incident/distribution", payload).then(resp => {
            return resp
        })

    }

    static notepad(IncidentID, notepad) {
        let payload = {
            text: notepad
        }

        return BackendService.patch("/incident/" + IncidentID + "/notepad", payload).then(resp => {
            return resp
        })
    }

    static analitycs() {
        return BackendService.get("/incident/statistics/home").then(resp => {
            return resp.data
        })
    }

    static createIncident(payload) {
        return BackendService.post("/incident/storm", payload).then(resp => {
            return resp
        })
    }

    static updateIncident(payload, incidentID) {
        // Alterar para patch quando o fluxo disparar emails 
        return BackendService.patch("/incident/" + incidentID, payload).then(resp => {
            return resp
        })
    }

    static reviewIncident(payload, incidentID) {
        return BackendService.patch("/incident/" + incidentID + "/review", payload).then(resp => {
            return resp
        })
    }

    static incidentForReviewCount() {
        return BackendService.get("/incident/statistics/responsible-for-review").then(resp => {
            return resp.data
        })
    }

    static patchIncident(payload, incidentID) {

        // Alterar para patch quando o fluxo disparar emails 
        return BackendService.patch("/incident/" + incidentID, payload).then(resp => {
            return resp
        })
    }

    static batchIncident(payload) {
        // Entubar incidente 
        return BackendService.post("/incident/storm/entuba", payload).then(resp => {
            return resp
        })
    }

    static downloadResult(payload) {
        var url = "/incident/list/download"
        if (IncidentService.local) {
            url = "/list/download"
        }

        return BackendService.post(url,payload, true, IncidentService.local, IncidentService.port).then(resp => {
            return resp
        })
    }
}


