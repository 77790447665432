import BackendService from './BackendService';
import UserService from './UserService';

export default class FieldService {
    static dbName = "FieldCacheDB";
    static storeName = "fieldCache";
    static keyName = "fullData"
    static fullData = {};
    static db = null;
    static callbackUpdateData = [];
    static lastLang = "";
    static dataSize = 1000;
    static lock = Promise.resolve(); // Inicializa o lock como uma Promise resolvida

    static async getFieldSafe(packageName, fieldName) {
        let returnData=[]
        await FieldService.withLock(async () => {
            // TODO: check null
            returnData =  FieldService.fullData[UserService.company].storm[packageName][fieldName]
        });
        return returnData
    }

    static getField(packageName, fieldName) {
        return FieldService.fullData[UserService.company].storm[packageName][fieldName]
    }

    // Gerencia locks para operações assíncronas
    static withLock(fn) {
        FieldService.lock = FieldService.lock.then(fn).catch((error) => {
            console.error("Error in locked operation:", error);
        });
        return FieldService.lock;
    }

    // Inicializa o banco IndexedDB
    static async initDB() {
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(FieldService.dbName, 1);

            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                if (!db.objectStoreNames.contains(FieldService.storeName)) {
                    db.createObjectStore(FieldService.storeName, { keyPath: "id" });
                }
            };

            request.onsuccess = (event) => {
                FieldService.db = event.target.result;
                resolve(FieldService.db);
            };

            request.onerror = (event) => {
                console.error("Error initializing IndexedDB:", event.target.error);
                reject(event.target.error);
            };
        });
    }

    // Salva um data no IndexedDB
    static async saveDataToDB() {
        const db = FieldService.db || await FieldService.initDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(FieldService.storeName, "readwrite");
            const store = transaction.objectStore(FieldService.storeName);

            store.put({ id: FieldService.keyName, data: FieldService.fullData });
            transaction.oncomplete = () => resolve();
            transaction.onerror = (event) => reject(event.target.error);
        });
    }

    // Obtém um data do IndexedDB
    static async getDataFromDB(key) {
        const db = FieldService.db || await FieldService.initDB();
        return new Promise((resolve, reject) => {
            const transaction = db.transaction(FieldService.storeName, "readonly");
            const store = transaction.objectStore(FieldService.storeName);

            const request = store.get(key);
            request.onsuccess = (event) => resolve(event.target.result?.data || null);
            request.onerror = (event) => reject(event.target.error);
        });
    }

    // Salva dados no cache
    static async saveCache() {
        await FieldService.withLock(async () => {
            if (!FieldService.fullData) return;
            console.log("caioo to cache", FieldService.keyName, FieldService.fullData)
            await FieldService.saveDataToDB(FieldService.fullData);
        });
    }

    // Obtém dados do cache
    static async getCache() {
        return FieldService.withLock(async () => {
            FieldService.fullData = {};
            const db = FieldService.db || await FieldService.initDB();

            const transaction = db.transaction(FieldService.storeName, "readonly");
            const store = transaction.objectStore(FieldService.storeName);

            const allItems = await new Promise((resolve, reject) => {
                const request = store.getAll();
                request.onsuccess = (event) => resolve(event.target.result);
                request.onerror = (event) => reject(event.target.error);
            });

            allItems.forEach(({ id, data }) => {
                console.log("caioo from cache", id, data)
                if (id == FieldService.keyName) {
                    FieldService.fullData = data;
                }
            });

            return allItems.length > 0;
        });
    }

    static async ForceFetch() {
        console.log("Force fetching data...");
        const resp = await BackendService.get(`/fields/option?lang=${FieldService.lastLang}`);
        const newData = resp.data; // Evita acesso direto ao FieldService.fullData

        await FieldService.withLock(async () => {
            FieldService.fullData = newData; // Atualiza FieldService.fullData de forma segura
        });
        await FieldService.saveCache();
        FieldService.callUpdateData();
    }

    static async fetch(lang) {
        FieldService.lastLang = lang;
        const hasCache = await FieldService.getCache();
        console.log("caioo hasCache", hasCache)

        if (hasCache) {
            console.log("Loaded fields from cache, fetching updated data...");
            BackendService.get(`/fields/option?lang=${lang}`).then(async (resp)=>{

                const newData = resp.data;

                await FieldService.withLock(async () => {
                    console.log("entrou")
                    FieldService.fullData = newData; // Atualiza FieldService.fullData de forma segura
                });
                await FieldService.saveCache();
                FieldService.callUpdateData();
            });
        } else {
            console.log("No cache found, fetching data...");
            const resp = await BackendService.get(`/fields/option?lang=${lang}`);
            const newData = resp.data;

            await FieldService.withLock(async () => {
                FieldService.fullData = newData; // Atualiza FieldService.fullData de forma segura
            });
            await FieldService.saveCache();
        }
    }

    // Gerencia callbacks
    static registerCallbackUpdateData(input) {
        FieldService.callbackUpdateData.push(input);
    }

    static callUpdateData() {
        FieldService.callbackUpdateData.forEach((f) => f());
    }

    static unregisterCallbackUpdateData(input) {
        const index = FieldService.callbackUpdateData.indexOf(input);
        if (index > -1) {
            FieldService.callbackUpdateData.splice(index, 1);
        }
    }
}
