<template>
  <DataTable
    :value="userFiles"
    tableStyle="min-width: 50rem; width: 100%; margin-bottom: 30px; background-color:#FFF"
    class="card customDataTableFileShare"
    :loading="userFiles == undefined || userFiles.length == 0"
  >
    <template #header>
      <div
        class="flex flex-wrap align-items-center justify-content-between gap-2"
      >
        <div>
          <Button
            class="linkButton customButton"
            @click="newFile()"
            label="New File"
            icon="pi pi-cloud-upload"
          />
        </div>
        <Button
          class="linkButton customButtonNoText customButton"
          icon="pi pi-refresh"
          @click="listUserFiles"
        />
      </div>
    </template>
    <template #loading>
      <div v-for="index in 5" :key="index">
        <div class="grid">
          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>

          <div class="col">
            <Skeleton style="width: 70%; height: 20px" class="mr-2"></Skeleton>
          </div>
        </div>
        <Divider layout="horizontal" />
      </div>
    </template>
    <Column field="filename" header="Name"></Column>
    <Column field="createdBy" header="Created By"></Column>
    <Column field="createdTimestamp" header="Created at"></Column>
    <Column field="lastUpdatedBy" header="Last Updated by"></Column>
    <Column field="updatedTimestamp" header="Last Updated at"></Column>
    <Column field="updatedTimestamp" header="Last Updated at"></Column>
    <Column header="Action">
      <template #body="slotProps">
        <div class="text-left">
          <Button
            class="linkButton customButtonNoText customButton mr-2"
            @click="download(slotProps.data.id, slotProps.data.filename)"
            title="Download"
            icon="pi pi-cloud-download"
          />
          <Button
            class="linkButton customButtonNoText customButton mr-2"
            @click="replaceFile(slotProps.data.id, slotProps.data.filename)"
            title="New version"
            icon="pi pi-cloud-upload"
          />
        </div> </template
    ></Column>
  </DataTable>

  <Dialog
    v-model:visible="dialogUpload.show"
    modal
    :header="dialogUpload.header"
    class="customDialogUpload"
    :style="{ width: '50rem' }"
  >
    <template #default>
      <AttachmentComponent
        :attachments="[]"
        :attachmentsMode="'edit'"
        :callbackSelectFile="uploadForm"
      ></AttachmentComponent>
    </template>
    <template #footer>
      <div style="display: flex; width: 100%; justify-content: center"></div>
    </template>
  </Dialog>
</template>
<script>
import AttachmentComponent from "../components/AttachmentComponent.vue";
import FileShareService from "../service/FileShareService";
import Util from "../service/Util";
export default {
  components: {
    AttachmentComponent,
  },

  data() {
    return {
      dialogUpload: {
        show: false,
        header: "",
        replace: {
          id: null,
          filename: "",
        },
        type: "",
      },
      dialogTypeNew: "new",
      dialogTypeEdit: "edit",
      tmpInterval: null,
      userFiles: [],
      userFilesLoading: [
        {
          id: "",
          filename: "",
          createdBy: "",
          createdTimestamp: "",
          lastUpdatedBy: "",
          updatedTimestamp: "",
        },
        {
          id: "",
          filename: "",
          createdBy: "",
          createdTimestamp: "",
          lastUpdatedBy: "",
          updatedTimestamp: "",
        },
        {
          id: "",
          filename: "",
          createdBy: "",
          createdTimestamp: "",
          lastUpdatedBy: "",
          updatedTimestamp: "",
        },
      ],
    };
  },
  mounted() {
    this.listUserFiles();
  },
  unmounted() {},

  methods: {
    resetDialog() {
      this.dialogUpload = {
        show: false,
        header: "",
        replace: {
          id: null,
          filename: "",
        },
        type: "",
      };
    },
    newFile() {
      this.resetDialog();
      this.dialogUpload.header = "Upload new file";
      this.dialogUpload.show = true;
      this.dialogUpload.type = this.dialogTypeNew;
    },
    replaceFile(id, filename) {
      this.resetDialog();
      this.dialogUpload.header = "Replace file: " + filename;
      this.dialogUpload.show = true;
      this.dialogUpload.replace.id = id;
      this.dialogUpload.replace.filename = filename;
      this.dialogUpload.type = this.dialogTypeEdit;
    },
    uploadForm(files) {
      console.log(files);
      Util.loading = true;
      if (this.dialogUpload.type == this.dialogTypeNew) {
        FileShareService.upload(files)
          .then((resp) => {
            console.log("Page:", resp);
            this.listUserFiles();
          })
          .finally(() => {
            Util.loading = false;
            this.dialogUpload.show = false;
          });
          return
      }
      if (this.dialogUpload.type == this.dialogTypeEdit) {
        FileShareService.replace(files, this.dialogUpload.replace.id)
          .then((resp) => {
            console.log("Page:", resp);
            this.listUserFiles();
          })
          .finally(() => {
            Util.loading = false;
            this.dialogUpload.show = false;
          });
          return
      }
    },
    listUserFiles() {
      this.userFiles = [];
      FileShareService.list().then((resp) => {
        console.log("Page:", resp);
        this.userFiles = resp.data;
        this.userFiles.forEach((file) => {
          file.createdTimestamp = Util.formatDate(file.createdTimestamp, true);
          file.updatedTimestamp = Util.formatDate(file.updatedTimestamp, true);
        });
      });
    },
    download(id, filename) {
      console.log("download", id);
      FileShareService.download(id).then((resp) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(resp);
        a.download = filename;
        a.click();
      });
    },
  },
};
</script>

<style>
.customDataTableFileShare .p-datatable-loading-overlay {
  display: none;
}
.customDialogUpload .p-dialog-content {
  padding-bottom: 0px;
}
.customButtonNoText .p-button-label {
  display: none;
}
.customButton {
  -webkit-box-shadow: 0 0 1px 0px #373737;
  box-shadow: 0 0 1px 0px #373737;
}
</style>