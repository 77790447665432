<template>
    <div class="card flex justify-content-end">
        <Button label="Gerar resumo" @click="generateSummary" class="primaryButton" style="width:150px" />
    </div>
    <div class="card">
        <DataTable class="customDataTable" v-if="incidentList != null && incidentList.length > 0"
            v-model:expandedRows="expandedRows" :value="incidentList" style="margin-top: 24px">
            <Column expander style="width: 3rem; padding-right: 0"> </Column>
            <Column style="padding-left: 0" field="header">
                <template #body="slotProps">
                    <div class="flex" style="justify-content: space-between; align-items: center;">
                        <div class="flex ml-2" style="gap: 10px;">
                            <Chip style="height: fit-content;" class="secondaryChip" :label="slotProps.data.name" />

                            <div class="flex" style="align-items: center; gap: 5px; font-weight: 800;">
                                <i class="pi pi-calendar"></i>
                                {{ convertDate(slotProps.data.data.requestTimestamp) }}
                            </div>
                        </div>
                        <div class="flex justify-content-between align-items-center" style="padding: 15px">
                            <div class="flex" style="gap: 10px; background: #F0F4F9;
                            padding: 10px;
                            border-radius: 20px;">
                                <Skeleton shape="circle" size="2rem" :style="{
                                    'background-color': getColor(slotProps.data.status),
                                }">
                                </Skeleton>

                                <div v-if="slotProps.data.status == 2">
                                    <button style="width: 2rem; height: 2rem;"
                                        @click="downloadSummary(slotProps.data.name, slotProps.data.id)"
                                        class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                        <i class="pi pi-download"></i>
                                    </button>
                                </div>
                                <div v-else>
                                    <button style="width: 2rem; height: 2rem;" disabled
                                        class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                        <i class="pi pi-download"></i>
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                </template>
            </Column>

            <template #expansion="slotProps">
                <!-- {{ slotProps }} -->

                <div class="flex flex-column" style="padding: 24px 42px 24px 42px">
                    <div v-if="1 == 2">
                        {{ slotProps }}
                    </div>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script>
import "../Global.css";
import BdpoService from "../service/BdpoService";
import Util from "../service/Util";


export default {
    components: {

    },
    data() {
        return {
            incidentList: null,
            expandedRows: [],
        };
    },
    created() {
        this.getList();
        this.autoRefreshInterval = setInterval(() => {
            this.getList();
        }, 90000);
    },
    beforeUnmount() {
        clearInterval(this.autoRefreshInterval);
    },
    mounted() {

    },
    methods: {
        async getList() {
            BdpoService.listBdpo().then((response) => {
                if (response.success) {
                    this.refreshData(response.data)
                }
            })
        },
        refreshData(data) {
            this.incidentList = null

            this.$nextTick(() => {
                this.incidentList = data
            })
        },
        getColor(status) {
            if (status == 1) {
                return '#ffbc00' //amarelo
            }
            if (status == 2) {
                return 'green'
            }
            if (status == 3) {
                return 'red'
            }
        },
        convertDate(date) {
            return Util.formatDate(date, true)
        },
        generateSummary() {
            Util.loading = true
            BdpoService.generateSummary().then(() => {
                Util.loading = false
                this.getList()
            })
        },
        downloadSummary(name, id) {
            BdpoService.downloadSummary(id).then((resp) => {
                const fileBlob = new Blob([resp], { type: 'application/xml' });

                var a = document.createElement("a");
                a.href = window.URL.createObjectURL(fileBlob);

                var fileName = name + ".xml";
                a.download = fileName;

                a.click();
            }).catch((error) => {
                console.error("Erro ao baixar o arquivo:", error);
            });
        }

    },
};
</script>
<style>
.customDataTable th {
    display: none;
}

.customDataTable .p-datatable-tbody tr td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.customDataTable .p-datatable-loading-overlay {
    display: none;
}

.customDataTable .p-datatable-row-expansion {
    background: #f7f7f7 !important;
}
</style>