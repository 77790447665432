<template>
    <DataView v-if="logObjectLocal != null" :value="logObjectLocal" paginator :rows="5">
        <template #list="slotProps">
            <div class="col-12" v-if="slotProps.data.changelog != null || slotProps.data.type == 1">
                <div class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4">
                    <Avatar :image="getUserPhoto(slotProps.data.user)" class="mr-2" size="large" shape="circle" />
                    <div style="width: 100%">
                        <div class="flex align-items-center justify-content-between ml-2" style="width: 100%">
                            <div>
                                <b>{{ emailToName(slotProps.data.user) }}</b>
                            </div>
                            <div>
                                {{ convertDate(slotProps.data.date, true) }}
                            </div>
                        </div>
                        <template v-if="$slots.body">
                            <slot name="body" v-bind="slotProps"></slot>
                        </template>
                        <div class="mt-2 ml-1" v-else>
                            <div v-if="slotProps.data.type == 1" style="margin-left: 3px">
                                <li>{{ this.$langGetValue("log0") }} <b>{{ logNameLocal }}</b> {{ this.$langGetValue("log1")}} <b v-if="migratedLocal">{{ this.$langGetValue("log9") }}</b> 
                                <b v-else>{{this.$langGetValue("log2") }}</b></li>
                            </div>
                            <div v-else v-for="selected in slotProps.data.changelog" style="margin-left: 3px"
                                :key="selected">
                                <div v-if="selected.field != ''">
                                    <div v-if="fieldTreatmentLocal[selected.field].showChanges">
                                        <div v-if="fieldTreatmentLocal[selected.field].isDate == true">
                                            <li v-if="selected.from != ''">
                                                {{ this.$langGetValue("log3") }} <b> {{
                                                    fieldTreatmentLocal[selected.field].label }}</b> {{ this.$langGetValue("log4") }} "<b>{{ convertDate(selected.from, false) }}</b>" {{ this.$langGetValue("log5") }}
                                                "<b>{{ convertDate(selected.to, false) }}</b>"
                                            </li>
                                            <li v-else-if="selected.from == ''">
                                                {{ this.$langGetValue("log6") }} {{ this.$langGetValue("log7") }}" <b>{{
                                                    convertDate(selected.to, false) }}</b>" {{ this.$langGetValue("log8") }}
                                                <b>{{fieldTreatmentLocal[selected.field].label }}</b>
                                            </li>

                                        </div>
                                        <div v-if="fieldTreatmentLocal[selected.field].isDate == false">
                                            <div>
                                                <li v-if=" selected.from != '' && selected.to != ''">
                                                    {{ this.$langGetValue("log3") }} <b>{{ fieldTreatmentLocal[selected.field].label}}</b> {{ this.$langGetValue("log4") }} "<b> {{ selected.from }}</b>" {{this.$langGetValue("log5") }} "<b>{{ selected.to }}</b>"
                                                </li>
                                                <li
                                                    v-else-if=" selected.from != '' && selected.to == ''">
                                                    {{ this.$langGetValue("log10") }} <b>"{{ selected.from }}" </b>{{ this.$langGetValue("log4") }} <b>{{fieldTreatmentLocal[selected.field].label }}</b>
                                                </li>
                                                <li
                                                    v-else-if=" selected.from == ''">
                                                    {{ this.$langGetValue("log6") }} "<b>{{ selected.to }}</b>" {{ this.$langGetValue("log8") }} <b>{{ fieldTreatmentLocal[selected.field].label }}</b>
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <li>
                                            {{ this.$langGetValue("log3") }} <b>{{ fieldTreatment[selected.field].label
                                            }}</b>
                                        </li>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </DataView>
</template>

<script>

import { ref } from 'vue';
import Util from "../service/Util";
import GraphService from '../service/GraphService';

export default {
    props: {
        logObject: Object,
        logName: String,
        fieldTreatment: Object,
        migrated: Boolean,
    },
    setup(props) {
        const logNameLocal = ref(props.logName)
        const fieldTreatmentLocal = ref(props.fieldTreatment)
        const migratedLocal = ref(props.migrated)
        return {
            logNameLocal,
            fieldTreatmentLocal,
            migratedLocal
        };
    },
    data() {
        return {
            logObjectLocal: null,
        };
    },
    mounted() {
        this.treatFieldMap()
    },
    methods: {
        treatFieldMap() {
            Object.keys(this.fieldTreatmentLocal).forEach(key => {
                if (this.fieldTreatmentLocal[key].isDate == undefined) {
                    this.fieldTreatmentLocal[key].isDate = false
                }
            });

            this.logObjectLocal = this.logObject
        },
        getUserPhoto(user) {
            return GraphService.getPhoto(user)
        },
        emailToName(email) {
            return Util.emailToName(email)
        },
        convertDate(date, hour) {
            if (hour) {
                return new Date(date).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                })
            }
            return new Date(date).toLocaleDateString('pt-BR', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            })
        },
    },
};

</script>