import Util from './Util';

export default class ContentService {

    static currentLang = null

    static data = {
        "en_us": {
            "invalidSystem" : "The backup system must be different from the selected system",
            "reviewedDescriptionSublabel": "Field intended for description review by the Operational Risk team.",
            "reviewedDescription": "Reviewed Decription",
            "includeRelatedIdsIssue": "Include related issue ids",
            "relatedIssues": "Related issues",
            "includeRelatedIds": "Include related incident ids",
            "relatedIncidents": "Related incidents",
            "insertValidId": "Insert a valid id",
            "notFound": " not found",
            "stormNewVersionAvailable": "A new version of STORM is available",
            "clickToUpdatePage": "Click the button to update the page",
            "person": "Person",
            "refreshToken": "Reauthenticate",
            "field": "Field",

            "fieldRequired": "Field Required",
            "newActionPlan": "New Action Plan",
            "buttonnewActionPlan": "Create New Action Plan",
            "buttonsaveActionPlan": "Save",
            "editActionPlan": "Edit Action Plan",
            "linkOldActionPlan": "Link Old Action Plan",
            "owner": "Owner",
            "action": "Action",
            "accountingAccount": "Accounting Account",
            "accountingAccountSub": "Text field to accounting accounts",
            "applicationFromModeForm": "Form",
            "applicationFromModeEntuba": "Data Import",
            "applicationFromModeMigrate": "Migrated",
            "returnToIncident": "Return to incident",
            "previous": "Previous",
            "modified": "Modified",
            "clean": "Clean",
            "extraFieldsIncident": "Extra fields",
            "file": "File",
            "labelGuide": "Help",
            "stormGuide": "Storm User Guide",
            "issue": "Issue",
            "Issue": "Issue",

            "lowriskLevel": "Low",
            "mediumriskLevel": "Medium",
            "highriskLevel": "High",
            "improvement opportunityriskLevel": "Improvement Opportunity",
            "issuetitle": "Issue title",
            "issuetitlemoreissueorigin": "Title + Control Area",
            "summary": "Summary",
            "on time": "On time",
            "overdue": "Overdue",
            "draft": "Draft",
            "risk accepted": "Risk Accepted",
            "closed on time": "Closed on time",
            "closed overdue": "Closed overdue",


            "myIssues": "My Issues",
            "yes": "Yes",
            "no": "No",
            "controlAreaIssues": "Control Area Issues",
            "issuesPendingapproval": "Issues Pending Approval",
            "issuestatus": "Issue status",
            "issuestatusmoreissueorigin": "Status + Control Area",
            "issueprogress": "Issue progress",
            "issuedescription": "Describe the issue in detail",
            "issuedescriptionmoreissueorigin": "Description + Control Area",
            "issueOrigin": "Responsible Controle Area",
            "yourissueOrigin": "Fill your Control Area",
            "regulator": "Regulator",
            "issuerecurring": "Is it a recurring issue?",
            "idissuerecurring": "Recurring issue ID",
            "textidissuerecurring": "Click to see the issue",
            "risk": "Risk",
            "issuerisklevel": "What is the risk level?",
            "issueriskfactor": "What is the risk factor?",
            "issuerisk": "What is the risk involved?",
            "issueriskaccepted": "Is it a risk accepted?",
            "issueriskaccepteddate": "Risk accepted deadline",
            "issuejustificationriskaccepted": "Justification of risk accepted",
            "report": "Report",
            "issuereportname": "Report name",
            "issuereportnumber": "Report number",
            "issuereportdate": "Report date",
            "structure": "Structure",
            "issuecompany": "Select the company related to the issue",
            "issuehierarchy": "Select the areas related to the issue",
            "systemriskfactor": "System (risk factor)",
            "product": "Product",
            "involved": "Involved in",
            "focalPoint": "Focal point",
            "descfocalPoint": "The focal points are the people in the area who will answer questions about the issue and the progress of the action plans",
            "issueoriginresponsible": "Responsible of control area",
            "descissueoriginresponsible": "It is the person in the control area (Operational Risk, Audit, CTB) who will be responsible for monitoring the progress of the issue",
            "issueowner": "Issue owner",
            "descissueowner": "It is the responsible of the area or business for the issue",
            "supplierinvolved": "Supplier involved",
            "supplierinvolvedQuestion": "Select the supplier involved",
            "actionplan": "Action plan",
            "actionplans": "Action plans",
            "actionplansub": "Register the action plans for this issue",
            "dates": "Dates",
            "estimatedCompletionDate": "Estimated completion date",
            "estimatedCompletionDateTitle": "Non-editable, automatically calculated field based on action plans. <br><br>First estimate date for resolve all action plans",
            "actualCompletionDate": "Actual completion date",
            "actualCompletionDateTitle": "Non-editable, automatically calculated field based on action plans. <br><br>Actual estimate date for resolve all action plans. That is, if the first estimated date is changed, this field will be filled in.",
            "remediationDate": "Remediation date",
            "remediationDateTitle": "Editable. <br><br> Date on which the issue's risk level decreased - for example, it went from high to medium",
            "mitigationDate": "Mitigation date",
            "mitigationDateTitle": "Issue closing date",
            "otherinfos": "Additional information",
            "followup": "Follow Up",
            "followupMinCaract": "Follow Up with a minimum of 20 characters",
            "lastfollowup": "Last Follow Up",
            "fup": "FUP",
            "issueidincident": "Incident ID",
            "clickissueidincident": "Click to see then incident",
            "hasissueidincident": "Are there incidents that materialized the issue?",
            "hasidpro3": "Are there PRO3 solution to link?",
            "hassupplierinvolved": "Are there supplier involved with the issue?",
            "hasissueidindicator": "Are there PRO3 indicators to the issue?",
            "issueidindicator": "PRO3 Indicator ID",
            "issueupload": "Uploads: Attach documents related to the registered issue",
            "apupload": "Attach documents related to the registered action plan",
            "register": "Register",
            "edit": "Edit",
            "issueapproveClosure": "Approve issue closure",
            "issuedisapproveClosure": "Disapprove issue closure",
            "issuerequestClouse": "Request closure",
            "issuehistory": "Issue history",
            "issueCantBeRiskAccept": "Issue cannot be risk accepted",
            "issueCantBeDraft": "Issue cannot be draft",
            "issueCantHaveOpenAp": "Close all action plans",
            "issueCreateAp": "Create the action plans",
            "issuePendingToClose": "The missing points to close the issue:",
            "popupUpdateRemediationDate": "As the risk level has decreased, the remediation date was set for today. If you wish, you can manually change this date in the Dates section.",
            "issuedescriptionhistory": "Issue description history",
            "aphistory": "Action Plan History",
            "idissue": "ID Issue",
            "aptitle": "AP Title",
            "apstatus": "Status",
            "approgress": "AP Progress",
            "apdescription": "Describe the action plan in detail",
            "progress": "Progress",
            "apdate": "Estimated Completion Date",
            "apestimateddate": "Estimated Completion Date",
            "apeactualdate": "Real Completion Date",
            "apfinaldate": "Final Date",
            "type": "Type",
            "tatic": "Tatic",
            "strategic": "Strategic",
            "idPRO3integration": "PRO3 ID to integration",
            "apresponsibletodev": "Responsible for the action plan",
            "area": "Area",
            "areas": "Areas",
            "editissue": "Edit Issue",
            "fuphistory": "Follow Up History",
            "date": "Date",
            "justificationOfDisapproveClouse": "Justification of Disapprove Issue Closure",
            "justification": "Justification",
            "sendJustificationAndDisapprove": "Send justification and disapprove",
            "changeAPresultchangeIssue": "The change to the AP resulted in the following changes to the Issue:",
            "issuechange": "Issue change",
            "issueclosure": "Issue Closure",
            "issueclosuretext": "Given the status of the action plans, the issue can be closed. Do you want to request closure from the head area?",
            "emailSendToApprover": "An email was sent to the heads of the control area requesting its closure.",
            "deniedRequestEmailSend": "Justification for refusal was sent by email to the requester.",
            "requestClosure": "Request Closure",
            "validation": "Validation",
            "open": "Open",
            "opening": "At opening",
            "canceled": "Canceled",
            "riskaccepted": "Risk Accepted",
            "pendingrequesttoclose": "Request Closure",
            "pendingapprovaltoclose": "Head Approval",
            "closed": "Closed",
            "timelineissue": "Issue Timeline",
            "closeall": "Close all ",
            "approvalfrom": "approval from ",
            "theareahead": "the area head",
            "obstimelineissue": "Note: If all Action Plans are cancelled, the Issue will also be cancelled.",
            "statuschangedof": "- Status changed of '",
            "to": "' to '",
            "progresschangedof": "- Progress changed of '",
            "datechangedof": "- Estimated date to close issue changed to ",
            "removeddate": "- Estimated date to close issue removed",
            "duetochangeof": "due to change of ",
            "toast8SubIssue": "No matching issue",
            "toast8SubAP": "No matching action plan",
            "attention": "Attention!",
            "attentionActionPlan": "Changes to the Action Plan will only be permanently saved when saving the",

            "title": "Title",
            "filter": "Filter",
            "titleminap": "Title (min. 5 caracters)",
            "bdpoPending": "Pending",
            "bdpoFilled": "Filled",
            "attachmentsEmpty": "Drag or click to upload files",
            "bdpoIncidentsEmpty": "Oops, it seems there are no BDPO incidents around here.",
            "myIncidentsEmpty": "Oops, it seems there are no incidents of yours around here.",
            "reviewedByMeEmpty": "Oops, it seems there are no incidents reviewed by you.",
            "biaEmpty": "Oops, it looks like there are no BIA campaigns here.",
            "fillBdpo": "Fill BDPO",
            "generalData": "General data",
            "bdpoConfirmButton": "Confirm completion",
            "bdpoCancelButton": "Cancel filling",
            "bdpoAccountedPlaceholder": "Select the accountability",
            "bdpoAccountedDescription": "Choose whether the loss is accounted for in the PSL",
            "bdpoAccounted": "Accounted for in PSL",
            "bdpoLossStatusPlaceholder": "Choose a status",
            "bdpoLossStatusDescription": "Define the loss status",
            "bdpoLossStatus": "Loss status",
            "bdpoBusinessFunctionPlaceholder": "Select a business function",
            "bdpoBusinessFunctionDescription": "Select a business function for the incident",
            "bdpoBusinessFunction": "Business function",
            "bdpoCourtOrderPlaceholder": "Select below one of the options",
            "bdpoCourtOrderDescription": "Define if there is a judicial order and the characterization of your loss",
            "bdpoCourtOrder": "Court order",
            "bdpoBaselLevelPlaceholder": "Select a Basel Level",
            "bdpoBaselLevelDescription": "Choose a Basel Level below for the incident",
            "bdpoBaselLevel": "Basel Level",
            "bdpoRootIncidentRegistrationDescription": "Date the root loss incident was recorded",
            "bdpoRootIncidentRegistration": "Root loss registry",
            "bdpoRootIncidentNumber": "Root loss incident number",
            "bdpoRootIncident": "Root loss incident",
            "bdpoRootDescription": "Choose an option that characterizes the incident",
            "bdpoRootLoss": "Root loss",
            "bdpoEventTypePlaceholder": "Select the type of event",
            "bdpoEventTypeDescription": "Choose an option that characterizes the incident",
            "bdpoEventType": "Event type",
            "bdpoEventNumber": "Event number",
            "bdpoField": "BDPO data",
            "summaryTitleActive": "Summary of the incidents presented in the list below",
            "summaryTitleDisabled": "To access the summary, your list needs to encompass a minimum of 3 incidents and a maximum of 50 incidents.",
            "seeSummary": "View summary",
            "closeSummary": "Close summary",
            "refreshAuth": "Refresh authentication",
            "incidentID": "Incident ID",
            "correspondingIncidents": "corresponding incidents",
            "correspondingIssues": "corresponding issues",
            "incident": "Incident",
            "myIncidents": "My Incidents",
            "assignIncidents": "Assign Incidents",
            "assignedIncidents": "Assigned Incidents",
            "reviewedByMe": "Reviewed by me",
            "dateOfOccurrence": " Date of Occurrence",
            "dateOfOccurrenceSub": "On what date did the incident occur?",
            "dateOfDiscovery": " Date of Discovery",
            "dateOfDiscoverySub": "On what date was the event discovered?",
            "dateOfRegistration": "Submission Date",
            "dateOfUpdate": "Last Update",
            "description": " Description",
            "descriptionMinCaract": " Description (min. 100 characters)",
            "descriptionAPMinCaract": " Description (min. 50 characters)",
            "descriptionSub": "Report the incident highlighting the description of the impacts, the cause of the problem that occurred, how it was discovered, and what actions were taken to resolve it. <br> <b>The Description field accepts images.</b> You can paste (CTRL+V) screenshots below. <b>(The field must be at least 100 characters long)</b>",
            "descriptionText": "Describe the incident in detail",
            "hashtag": " Hashtags",
            "hashtagSub": " Include keywords here, in # format,",
            "hashtagSuggestion": " Hashtag Suggestion",
            "hashtagSuggestionSub": " Can't find the hashtag you'd like? Make your suggestion for us to evaluate the inclusion of #hashtag in our database.",
            "basel": " Basel Level",
            "baselSub": " What is the type of operational failure that the incident falls under according to Basel levels?",
            "riskFactorSub": "What was the factor that led to the incident?",
            "system": " System",
            "systemSub": "Which systems were involved in the incident?",
            "products": " Products",
            "productsSub": "What flow of products or services was impacted?",
            "capabilitySub": "Choose the related macro process",
            "company": " Company",
            "companySub": "Which BTG entity was impacted by the incident?",
            "originatedArea": " Originated Area",
            "originatedAreaSub": "What was the area that originated the incident?",
            "impactedAreas": " Impacted Areas",
            "impactedAreasSub": "What were the areas impacted by the incident?",
            "impacts": " Impacts",
            "impactsSub": "Answer questions about the impacts of the incident",
            "op": "Operational",
            "reg": "Regulatory",
            "rep": "Reputational",
            "fin": "Financial",
            "op01": "How long did the problem take to be resolved tactically? (in hours)",
            "op02": "How many areas were involved to solve the problem?",
            "reg01": "Were there any regulatory impacts or sanctions from this incident?",
            "rep01": "How many customers were directly impacted by the incident? (counterparties/partners)",
            "rep01Input": "Exact number of customers",
            "marioInputToolTip": "Click outside the input box to save!",
            "marioStatus": "Not calculated",
            "rep02": "What is the nature of the reputational impact of this incident?",
            "fin01": "What was the nature of the main financial impact?",
            "fin02": "Was there movement between accounts or impact on the current account?",
            "fin03": "What are the value and currency associated with this impact?",
            "fin04": "Is there a possibility of recovery/reversal of the value?",
            "forceRisk": "Force Risk",
            "forceRiskJustification": "Justification",
            "forceRiskJustificationSub": "Include justification for risk change",
            "eventOriginSub": "Include incident source event",
            "accountingDate": "Accounting Date",
            "accountingDateSub": "On what date was it recorded?",
            "managerialImpact": "Managerial Impact",
            "managerialImpactSub": "Net Loss (R$): A negative sign (-) indicates a gain, while the absence of a sign represents a loss.",
            "financialTransfer": "Financial Transfer",
            "financialTransferTotal": "transfer(s), totaling",
            "financialTransferSub": "For this incident, will you need to make financial transfers? <br> (Reversal, Refund, Advance, Fine or Internal transfer)",
            "financialTransferModalHead": "Instructions",
            "financialTransferModalHeadSub": `<p></p>Inform the transfers to be made in the context of this incident.<br /> If you prefer, you can copy an Excel table (Ctrl + C) and Click on the <b>Paste button</b> on the right. <ul> <li>Column format: text, text, number, list, list.</li> <li>Do not use commas, the decimal separator is a period.</li> <li>You can download a filling template by clicking on the button below.</li> </ul>`,
            "financialTransferModalCurrency": "Currency",
            "financialTransferModalApplyCurrency": "Apply currency to all",
            "financialTransferModalCategory": "Category",
            "financialTransferModalApplyCategory": "Apply Category to all",
            "financialTransferModalOriginAccount": "Origin Account",
            "financialTransferModalDestinationAccount": "Destination Account",
            "financialTransferModalValue": "Value",
            "financialTransferModalAddLine": "Add Line",
            "financialTransferModalDeleteLine": "Delete Line",
            "transfersResume": "Transfer Summary",
            "uploadSub": "Attach documents related to the recorded incident.",
            "uploadText": "Drag and drop files to upload.",
            "cc": "With copy to",
            "ccSub": "Who should be copied on the incident registration email?",
            "registeredBy": "Registered By",
            "requestedBy": "Requested By",
            "buttonSend": "Send",
            "buttonSave": "Save",
            "buttonCancel": "Cancel",
            "buttonReview": "Review",
            "buttonClose": "Close",
            "buttonSelect": "Select",
            "buttonClean": "Clean",
            "buttonPaste": "Paste",
            "buttonGenerate": "Generate",
            "buttonAccept": "Accept",
            "buttonView": "View",
            "buttonAssign": "Assign",
            "buttonReAssign": "Reassign",
            "buttonBatchAssign": "Batch Assign",
            "buttonReload": "Reload",
            "buttonRefuse": "Refuse",
            "buttonYes": "Yes",
            "buttonNo": "No",
            "confirmRefuse": "Confirm Refuse",
            "menuButtonNew": "New",
            "generatedTweet": "Generated Tweet",
            "menuButtonSearch": "Search",
            "optionYes": "Yes",
            "optionNo": "No",
            "extraFields": "Extra Fields",
            "incidentHistory": "Incident history",
            "status-2": "Retired",
            "status0": "Rejected",
            "status1": "Closed",
            "status2": "Pending Distribution",
            "status3": "Pending Review",
            "status4": "Reviewed",
            "searchOptions": "Search Options",
            "searchOptionDate": "Date",
            "searchOptionDateSub": "which date?",
            "searchOptionArea": "Area",
            "searchOptionDescription": "Description",
            "start": "Start",
            "end": "End",
            "occurrence": "Occurrence",
            "discovery": "Discovery",
            "record": "Submission",
            "result": "RESULTS",
            "lowMario": "Low",
            "mediumMario": "Medium",
            "highMario": "High",
            "criticalMario": "Critical",
            "fowardButton": "Foward Incident",
            "downloadButton": "Download",
            "buttonEdit": "Edit",
            "buttonEdit2": "Edits allowed for RO and registration user",
            "editTweet": "Edit Tweet",
            "reviewDisabled": "Low Risk Level",
            "users": "Users",
            "user": "User",
            "hierarchy": "Hierarchy",
            "reviewedBy": "Reviewed By",
            "responsibleToReview": "Responsible To Review",
            "log0": "The",
            "log1": "was",
            "log2": "created",
            "log3": "Changed",
            "log4": "from",
            "log5": "to",
            "log6": "Added",
            "log7": "the date",
            "log8": "to the field",
            "log9": "migrated from Archer",
            "log10": "Removed",
            "statusCreated": "created",
            "statusUpdated": "updated",
            "statusReviewed": "Reviewed",
            "dialog0": "Success",
            "dialog1": "Your incident was",
            "dialogIssue1": "Your issue was",
            "toast0": "You are now editing the incident",
            "toast1": "You are now reviewing the incident",
            "toast2": "Transfers incomplete",
            "toast3": "Format not supported!",
            "toast3Sub": "The file format is not supported: ",
            "toast4": "Tweet should have more characters",
            "toast5": "Empty field!",
            "toast5Sub": "The field cannot be empty",
            "toast6": "Something went wrong",
            "toast6Sub": "Please contact the Operational Risk team",
            "toast7": "Required fields in blank",
            "toast7Sub": "Please fill in the following fields:",
            "toast8": "Error fetching",
            "toast8Sub": "No matching incident",
            "toast9": "Error assigning!",
            "toast10": "Incident assigned!",
            "toast11": "Select at least 1 incidents to batch!",
            "toast12": "Select a user!",
            "toast13": "Incident name copied successfully",
            "issueCopied": "Issue ID copied successfully",
            "toast14": "Incident description copied successfully",
            "toast15": "Fill in all fields highlighted as mandatory",
            "toast16": "The BDPO fields were completed successfully",

            //bia translation
            "generalDataBia": "General Information",
            "companyAssociatedWithProcess": "Company associated with the process",
            "capability": "Capability",
            "capabilityAssociatedWithProcess": "Capability associated with the process",
            "areaToWhichProcessBelongs": "Area to which the process belongs",
            "processDescription": "Process Description",
            "processNarrative": "Process narrative, outlining the activities and controls relevant to the process execution",
            "involvedEmployees": "Involved Employees",
            "employeesInvolvedInProcess": "Employees involved in the day-to-day execution of the process (Search by name or email)",
            "backupEmployees": "Backup Employees",
            "employeesAbleToExecute": "Employees able to execute the process in the absence of those involved (Search by name or email)",
            "executionLocations": "Execution Locations",
            "officeLocations": "Office(s) and/or locations where the process is normally executed",
            "processExecutionFrequency": "Process Execution Frequency",
            "executionPeriod": "Process execution Period",
            "executions": "Executions",
            "dailyExecutionCount": "How many times a day is the process executed?",
            "weekDays": "Day(s) of the week",
            "weekDaysExecution": "On which day(s) of the week the process usually be executed?",
            "addExecutionTimes": "Add execution time(s)",
            "addExecutionTime": "Add execution time",
            "addCriticalExecutionTime": "Add critical execution time",
            "executionTimes": "Execution time(s)",
            "indicateExecutionPeriods": "Indicate the time periods during which the process is executed",
            "addCriticalExecutionTimes": "Add critical execution time(s)",
            "indicateCriticalExecutionPeriods": "Indicate the time periods during which process execution is critical",
            "selectPeriodCriticalExecution": "Select the period in which the critical execution is inserted",
            "addTimeRange": "Add time range",
            "timeWindow": "Time window",
            "periodsWhenExecuted": "Periods when the process is executed during the day",
            "normalHours": "Normal Hours",
            "criticalHours": "Critical Hours",
            "systems": "Systems",
            "doesProcessUseSystem": "Does the process use any system?",
            "addSystem": "Add system",
            "editSystem": "Edit system",
            "findSystemInList": "Find the desired system from the list (If the system is not found in the list, please inform the operational risk team)",
            "selectSystem": "Select the system",
            "systemDescription": "Provide a brief description of how the system is employed in the process execution. (minimum of 30 characters)",
            "potentialToHaltProcess": "Potential to halt the process",
            "couldInterruptionForceContingency": "Could an interruption force the use of a contingency procedure?",
            "answerQuestion": "Answer the question",
            "contingencyStrategy": "Contingency Strategy",
            "contingencyDescription": "Contingency description",
            "systemUsageDescription": "System usage description",
            "contingencyDescriptionSubLabel": "Provide a brief description of how the system is employed in the process execution. (minimum of 30 characters)",
            "typesOfContingencyStrategies": "Types of contingency strategies available in case the system becomes unavailable",
            "backupSystem": "Backup System",
            "backupSystemSubLabel": "Identify in the list the system that would act as a backup for the main system if the contingency strategy includes such a measure",
            "suppliers": "Suppliers",
            "supplier": "Supplier",
            "supplierRoleDescription": "Supplier role description",
            "doesProcessDependOnSupplier": "Does process execution depend on any supplier? (If using a system provided by a third party, please include the supplier. You can search using the system name. If unknown, please contact the operational risk team)",
            "addSupplier": "Add supplier",
            "editSupplier": "Edit supplier",
            "typeCharacterizingSupplier": "Type characterizing the supplier's involvement in the process",
            "supplierName": "Supplier Name",
            "enterSupplierName": "Enter the name of the supplier involved in the process execution",
            "selectType": "Select the type",
            "describeSupplierInvolvement": "Briefly describe the supplier's involvement in the process execution, i.e., their role within it",
            "couldSupplierAbsenceForceContingency": "Could the supplier's absence force the use of a contingency procedure?",
            "typesOfContingencyStrategiesSupplier": "Types of contingency strategies available in case the chosen supplier is absent.",
            "internalDependencies": "Internal Dependencies",
            "internalDependency": "Internal Dependency",
            "doesProcessDependOnOtherAreas": "Does process execution depend on the involvement of other areas of the company? (Examples: exchange of information, IT support, other)",
            "addDependency": "Add dependency",
            "editDependency": "Edit dependency",
            "dependencyType": "Dependency type",
            "dependencyRoleDescription": "Dependency role description",
            "selectAreaForDependency": "Select the area for which you want to determine the type of dependency with the process",
            "chooseDependencyType": "Choose the type of dependency the selected area has with the process",
            "describeDependency": "Briefly describe the type of dependency the process has with the selected area",
            "personalDataHandling": "Personal Data Handling",
            "personalDataHandlingLGPD": "Personal Data Handling - LGPD/GDPR/ADPPA",
            "personalDataHandlingSublabel": "The processing of personal data refers to any operation performed with data that identifies a person, such as collection, storage, processing, or sharing.",
            "personalInformation": "Personal Information",
            "doesProcessHandlePersonalInformation": "Does the process handle any personal information?",
            "sensitiveInformation": "Sensitive Information",
            "doesProcessHandleSensitiveInformation": "Does the process handle any sensitive information?",
            "confidentialInformation": "Confidential Information",
            "doesProcessHandleConfidentialInformation": "Does the process handle any confidential information?",
            "criticalityClassification": "Criticality Classification",
            "oneHour": "1 hour",
            "threeHours": "3 hours",
            "sixHours": "6 hours",
            "twelveHours": "12 hours",
            "twentyFourHours": "24 hours",
            "addJustification": "Add justification",
            "financial": "Financial",
            "regulatory": "Regulatory",
            "reputational": "Reputational",
            "operational": "Operational",
            "customers": "Customers",
            "immaterial": "Immaterial",
            "low": "Low",
            "medium": "Medium",
            "high": "High",
            "extreme": "Extreme",
            "describeHighFinancialImpact": "Describe why the financial impact is high or extreme during process execution",
            "openJustification": "Open justification",
            "calculatedImpact": "Calculated Impact",
            "calculatedImpactFromValues": "Impact calculated from the values entered in the table",
            "roImpact": "Op Risk Impact",
            "roTeamAnalysisImpact": "Impact determined by the Op Risk team analysis",
            "justifyImpactValue": "Justify the choice of Impact value (minimum of 30 characters)",
            "finalImpact": "Final Impact",
            "finalImpactValue": "Value defined from the comparison of the calculated impact and RO",
            "rtoRpo": "RTO / RPO",
            "recoveryTimeObjective": "Recovery Time Objective",
            "calculatedRTOValue": "RTO value calculated from previous data",
            "userRecoveryTimeObjective": "User Recovery Time Objective",
            "chooseRTOValue": "Choose an RTO value if you disagree with the calculated value",
            "justifyRTOValue": "Justify the choice of RTO value",
            "recoveryPointObjective": "Recovery Point Objective",
            "maxTolerableDataLoss": "Maximum tolerable time for data loss in case of failure",
            "contingency": "Contingency",
            "minimumNumberOfPeople": "Minimum Number of People",
            "minimumPeopleForContingency": "Minimum number of people required to execute the process in a contingency situation",
            "keyPersonnel": "Key Personnel",
            "keyPersonnelForCrisis": "Essential personnel for process execution in a crisis scenario (Search by name or email)",
            "saveDraft": "Save Draft",
            "submitForm": "Submit Form",
            "approve": "Approve",
            "reject": "Reject",
            "submitFormInstructions": "After clicking 'Confirm submission', the form will be sent for BPM approval. Only in case of rejection, the REX can edit the form again.",
            "deleteProcessInstructions": "After clicking 'Delete', the form will be deleted from the campaign and the process deactivated.",
            "confirmDeletion": "Delete",
            "confirmApprovalNote": "After clicking 'Confirm Approval', the form will be sent for RO validation. You will no longer be able to edit it.",
            "confirmApproval": "Confirm approval",
            "confirmFinalizationNote": "After clicking 'Confirm approval', the form will be finalized.",
            "confirmSubmission": "Confirm submission",
            "returnToCampaignPage": "Return to campaign page",
            "returnToCampaignList": "Return to campaign list",
            "navigation": "Navigation",
            "addPerson": "Add person",
            "selectLocation": "Select a location",
            "selectPeriod": "Select a period",
            "selectOption": "Select an option",
            "saveTime": "Save time",
            "daysOfMonth": "Days of the month",
            "daysOfMonthExecution": "On which day(s) of the month is the process usually executed?",
            "firstSemester": "First semester",
            "firstSemesterExecution": "On which day and month of the first semester is the process usually executed?",
            "secondSemester": "Second semester",
            "secondSemesterExecution": "On which day and month of the second semester is the process usually executed?",
            "dayAndMonth": "Day and month",
            "dayAndMonthSelection": "Select the day and month of the year when the process is executed",
            "otherOption": "Other option",
            "otherScenarios": "Write below the scenarios in which the process is executed",
            "timeConflict": "There cannot be a time conflict",
            "criticalTimeExceedsRange": "Critical time cannot exceed the range",
            "january": "January",
            "february": "February",
            "march": "March",
            "april": "April",
            "may": "May",
            "june": "June",
            "july": "July",
            "august": "August",
            "september": "September",
            "october": "October",
            "november": "November",
            "december": "December",
            "impactDescription": "Describe why the impact is high or extreme during process execution. (minimum of 30 characters)",
            "rejectionJustification": "Rejection justification",
            "rejectionReasonDescription": "Describe the reason(s) for rejecting the form (minimum of 30 characters)",
            "formRejectedByBPM": "Form rejected by BPM",
            "formRejectedByRO": "Form rejected by Op Risk",
            "viewJustification": "View justification",
            "biaFieldFormStatus": "Status",
            "biaFieldDailyExecutionTeam": "Involved Employees",
            "biaFieldBackupExecutionTeam": "Backup Employees",
            "biaFieldNormalExecutionLocations": "Execution Locations",
            "biaFieldExecutionHours": "Execution Hours",
            "biaFieldCriticalExecutionHours": "Critical Execution Hours",
            "biaFieldSystem": "Systems",
            "biaFieldSupplier": "Suppliers",
            "biaFieldInternalDependency": "Internal Dependencies",
            "biaFieldPersonalInfo": "Personal Information",
            "biaFieldSensitiveInfo": "Sensitive Information",
            "biaFieldConfidentialInfo": "Confidential Information",
            "biaFieldImpact": "Criticality Table",
            "biaFieldCalculatedImpact": "Calculated Criticality",
            "biaFieldRoImpactJustification": "RO Impact Justification",
            "biaFieldUserRtoJustification": "User RTO Justification",
            "biaFieldContingencyMinimumRequiredPeople": "Minimum Number of People",
            "biaFieldContingencyEmployees": "Key Personnel",
            "biaFieldPeriodType": "Process Execution Frequency",
            "biaFieldBpmRejectionJustification": "BPM Rejection Justification",
            "biaFieldRoRejectionJustification": "Op Risk Rejection Justification",
            "biaForm": "Bia form",
            "biaStatus1": "Fill REX",
            "biaStatus2": "BPM Approval",
            "biaStatus3": "Op Risk Approval",
            "biaStatus4": "Finished",
            "biaStatus5": "Review REX",
            "invalidJustification": "Invalid justification",
            "invalidJustificationLength": "The justification must be at least 30 characters long",
            "timeAdded": "Time added!",
            "blankFields": "Blank fields",
            "fillFields": "Fill in the fields: ",
            "systemAdded": "System added",
            "systemEdited": "System edited",
            "supplierAdded": "Supplier added",
            "supplierEdited": "Supplier edited",
            "dependencyAdded": "Dependency added",
            "dependencyEdited": "Dependency edited",
            "atLeastOneExecutionTime": "There must be at least one execution time",
            "formSaved": "Form saved!",
            "saveError": "Error saving! Contact the Op Risk team",
            "sendError": "Error sending! Contact the Op Risk team",
            "rejectError": "Error rejecting! Contact the Op Risk team",
            "approvalError": "Error approving! Contact the Op Risk team",
            "tooltipIndicateInvolvedEmployees": "Indicate the employees normally involved in the execution of the process.",
            "tooltipIndicateBackupEmployees": "Indicate the employees capable of executing the process if the usual employees are unavailable.",
            "tooltipIndicateExecutionLocations": "Indicate the locations where the process is normally carried out.",
            "tooltipIndicateCriticalPeriods": "If any, indicate critical periods within the process execution window, i.e., periods where an interruption would cause greater impact.",
            "tooltipMapCriticalSystems": "This information is used to map critical systems, i.e., vital systems for the execution of process-related activities, and whose interruption would significantly affect the ability to execute a critical process.",
            "tooltipMapCriticalSuppliers": "This information is used to map critical suppliers or third parties. Critical suppliers are those whose failure to provide products or services can significantly disrupt the ability to execute the process. Dependence on these suppliers requires the company to have plans to mitigate risks associated with any supply interruptions.",
            "tooltipIndicateInternalDependencies": "Internal dependencies refer to interactions and interrelationships between different areas or departments within an organization that are essential to maintaining uninterrupted operations. A disruption in one area can negatively impact other dependent areas, compromising the continuity of overall operations.",
            "tooltipPersonalInfoDescription": "Information that allows the identification of a natural person or makes it possible to identify them. Examples include name, address, phone number, CPF, email, and location data.",
            "tooltipSensitiveInfoDescription": "Special category of personal data revealing information about racial or ethnic origin, religious beliefs, political opinions, union membership or membership in religious, philosophical, or political organizations, health or sexual life data, genetic or biometric data.",
            "tooltipConfidentialInfoDescription": "Any information that must be kept confidential for privacy or security reasons, encompassing both personal and business confidential information.",
            "tooltipImpactMap": "Impact map",
            "tooltipJustifyHighImpact": "If high or extreme impacts are identified, a brief explanation is required to justify the impact.",
            "tooltipRtoDefinition": "Recovery Time Objective (RTO): The Recovery Time Objective is the maximum time it should take for your systems and services to be up and running after a problem or disaster. For example, if your RTO is 4 hours, this means that if there is an issue with a necessary resource for process execution (e.g., a critical system), the problem must be resolved within 4 hours of the interruption.",
            "tooltipRpoDefinition": "Recovery Point Objective (RPO): The Recovery Point Objective refers to how much data can be lost in the event of a major incident or disaster. It measures the maximum time that can pass between the last data backup and a possible failure. For example, if your RPO is 1 hour, data backups used in the process must be carried out within a maximum of 1 hour to avoid impacts in case of data loss.",
            "tooltipIndicateMinimumRequiredPeople": "Indicate the minimum number of people necessary to execute a process in a contingency situation.",
            "tooltipClientImpact": "Client impact is characterized by dissatisfaction, loss of trust, and potential loss of customers due to failures or interruptions in the delivery of products or services. Therefore, select the impact of a process interruption for each period.",
            "tooltipOperationalImpact": "Operational impact is characterized by the disruption of daily activities, causing delays, inefficiencies, and possible stoppages in production or service delivery. Therefore, select the impact of a process interruption for each period.",
            "tooltipReputationalImpact": "Reputational impact is characterized by the loss of trust and credibility among customers, investors, and other stakeholders, affecting the company's public image. Therefore, select the impact of a process interruption for each period.",
            "tooltipRegulatoryImpact": "Regulatory impact is characterized by non-compliance with rules, laws, or regulations, which can result in fines, sanctions, or other legal actions. Therefore, select the impact of a process interruption for each period.",
            "tooltipFinancialImpact": "Financial impact is characterized by loss of revenue, increased costs, or other adverse economic consequences resulting from a process interruption. Therefore, select the impact of a process interruption for each period.",
            "toBia": "to",
            "biaMenu1": "Campaigns",
            "biaMenu2": "New Campaign",
            "biaCampaignStatus1": "In Progress",
            "biaCampaignStatus2": "Completed",
            "biaCampaign": "BIA Campaigns",
            "biaFilterAreas": "Select the area(s) you want to filter",
            "biaFilteredAreas": "Filtered areas",
            "biaCreation": "Creation",
            "biaExpectedConclusion": "Expected Conclusion",
            "biaSelectStatus": "Select one or more statuses",
            "biaSelectRex": "Select one or more REX's",
            "biaSelectBpm": "Select one or more BPM's",
            "biaStatusView": "Status View",
            "biaFilteredStatus": "Filtered statuses",
            "biaFilteredRex": "Filtered REX's",
            "biaFilteredBpm": "Filtered BPM's",
            "deletedField": "The value of the field has been deleted",
            "newCampaign": "New campaign",
            "campaignName": "Campaign Name",
            "chooseCampaignName": "Choose a name to identify the campaign",
            "rexBpmDeadline": "REX and BPM deadline",
            "chooseRexBpmDeadline": "Choose a deadline, in days, for REX and BPM to complete their tasks",
            "roTeamDeadline": "Op Risk team deadline",
            "chooseRoTeamDeadline": "Choose a deadline, in days, for the RO team to approve the BIAs",
            "areasProcesses": "Areas and Processes",
            "searchAreas": "Search the areas you want to find",
            "registerNewCampaign": "Register new campaign",
            "chooseAreasProcesses": "Choose the areas and their processes that will be part of the campaign you are creating",
            "campaignCreatedSuccess1": "The campaign",
            "campaignCreatedSuccess2": "was created successfully!",
            "goToCampaignPage": "Go to campaign page",
            "createNewCampaign": "Create new campaign",
            "selectAtLeastOneBPM": "Select at least one BPM!",
            "selectAtLeastOneREX": "Select at least one REX!",
            "selectAtLeastOneStatus": "Select at least one status!",
            "selectAtLeastOneArea": "Select at least one area!",
            "entubaInstructionHeadSub": `<p></p>Each line corresponds to an incident to be intubated.<br /> If you prefer, you can copy from the template with the <b>Copy Spreadsheet</b> button in Excel and click the <b >Paste button</b> at the top right.<ul><li>In the spreadsheet, there will be instructions on how it can be used. <b>Pay attention to them!</b></li><li>After downloading, right-click on the spreadsheet file, go to properties and enable macro unlocking in it (in the bottom right corner) and finally click OK.</li><li>In the spreadsheet itself, there are images to help with these steps. Click below to download the template:</li></ul>`,
            "invalidValue": "Invalid value",
            "postoneReauthenticate": "Postpone 15min",
            "changeRolesTextBody": "Your permissions have been changed. When you click 'Reauthenticate', ALL STORM tabs will be reloaded. Make sure you save your work before you have to!",
            "changeRolesManualTextBody": "When you click 'Reauthenticate', ALL STORM tabs will be reloaded. Make sure you save your work before you have to!",
            "reauthenticateRoles": "Reauthenticate Roles"
        },
        "pt_br": {
            "invalidSystem" : "O sistema de backup deve ser diferente do sistema selecionado",
            "person": "Pessoa",
            "stormNewVersionAvailable": "Uma nova versão do STORM está disponível",
            "clickToUpdatePage": "Clique no botão para atualizar a página",
            "refreshToken": "Reautenticar",
            "field": "Campo",

            "fieldRequired": "Campo Obrigatório",
            "newActionPlan": "Novo Action Plan",
            "buttonnewActionPlan": "Criar Novo Action Plan",
            "editActionPlan": "Editar Action Plan",
            "buttonsaveActionPlan": "Salvar",
            "linkOldActionPlan": "Linkar Action Plan Existente",
            "owner": "Responsável",
            "action": "Ação",
            "accountingAccount": "Contas Contábeis",
            "accountingAccountSub": "Campo de texto para as contas contábeis",
            "applicationFromModeForm": "Formulário",
            "applicationFromModeEntuba": "Entuba",
            "applicationFromModeMigrate": "Migrado",
            "returnToIncident": "Retornar ao incidente",
            "previous": "Anterior",
            "modified": "Modificado",
            "clean": "Limpar",
            "extraFieldsIncident": "Campos extras",
            "file": "Arquivo",
            "labelGuide": "Ajuda",
            "stormGuide": "Guia do usuário Storm",
            "issue": "Issue",
            "Issue": "Issue",
            "title": "Título",
            "filter": "Filtrar",
            "titleminap": "Título (mín. 5 caracteres)",

            "lowriskLevel": "Baixo",
            "mediumriskLevel": "Médio",
            "highriskLevel": "Alto",
            "improvement opportunityriskLevel": "Melhoria Operacional",
            "issuetitle": "Título do Issue",
            "issuetitlemoreissueorigin": "Título + Área de Controle",
            "summary": "Sumário",
            "on time": "Em dia",
            "overdue": "Atrasado",
            "draft": "Draft",
            "risk accepted": "Risco Assumido",
            "closed on time": "Fechado em dia",
            "closed overdue": "Fechado em atraso",


            "myIssues": "Meus Issues",
            "yes": "Sim",
            "no": "Não",
            "controlAreaIssues": "Issues da área de controle",
            "issuesPendingapproval": "Issues pendentes de aprovação",
            "issuestatus": "Status",
            "issuestatusmoreissueorigin": "Status + Área de Controle",
            "issueprogress": "Progresso",
            "issuedescription": "Descreva o issue com detalhes",
            "issuedescriptionmoreissueorigin": "Descrição + Área de Controle",
            "issueOrigin": "Área de controle responsável",
            "yourissueOrigin": "Preencha sua área de controle",
            "regulator": "Regulador",
            "issuerecurring": "É um issue recorrente?",
            "idissuerecurring": "ID do issue recorrente",
            "textidissuerecurring": "Clique para ver o issue",
            "risk": "Risco",
            "issuerisklevel": "Qual é o nível de risco?",
            "issueriskfactor": "Qual é o fator de risco?",
            "issuerisk": "Qual é o risco envolvido?",
            "issueriskaccepted": "É um risco assumido?",
            "issueriskaccepteddate": "Data limite de aceite de risco",
            "issuejustificationriskaccepted": "Justificativa do aceite de risco",
            "report": "Relatório",
            "issuereportname": "Nome do relatório",
            "issuereportnumber": "Número do relatório",
            "issuereportdate": "Data do relatório",
            "structure": "Estrutura",
            "issuecompany": "Selecione a entidade relacionada ao issue",
            "issuehierarchy": "Selecione a área relacionada ao issue",
            "systemriskfactor": "Sistema (risk factor)",
            "product": "Produto",
            "involved": "Envolvidos",
            "focalPoint": "Pontos focais",
            "descfocalPoint": "Os pontos focais são as pessoas da área que tirarão dúvidas sobre o issue e o andamento dos action plans",
            "issueoriginresponsible": "Responsável da área de controle",
            "descissueoriginresponsible": "É a pessoa da área de controle (Risco Operacional, Auditoria, CTB) que será responsável por acompanhar o andamento do issue",
            "issueowner": "Dono do issue",
            "descissueowner": "É o responsável da área ou business pelo issue",
            "supplierinvolved": "Fornecedor",
            "supplierinvolvedQuestion": "Selecione o fornecedor envolvido",
            "actionplan": "Action plan",
            "actionplans": "Action plans",
            "actionplansub": "Registre os action plans para este issue",
            "dates": "Datas",
            "estimatedCompletionDate": "Data de conclusão prevista",
            "estimatedCompletionDateTitle": "Não editável, campo calculado automaticamente com base nos action plans. <br><br>Primeira data de conclusão prevista para completar todos os action plans",
            "actualCompletionDate": "Data de conclusão ajustada",
            "actualCompletionDateTitle": "Não editável, campo calculado automaticamente com base nos action plans. <br><br>Data de conclusão ajustada para completar todos os action plans. Ou seja, se a primeira data  estimada for alterada, esse campo vai ser preenchido.",
            "remediationDate": "Data de remediação",
            "remediationDateTitle": "Editável.<br><br> Data na qual o nível de risco diminuiu - por exemplo, foi de risk level alto para médio.",
            "mitigationDate": "Data de mitigação",
            "mitigationDateTitle": "Não editável. <br><br>Data do fechamento do issue",
            "otherinfos": "Informações adicionais",
            "followup": "Follow Up",
            "followupMinCaract": "Follow Up com mínimo de 20 caracteres",
            "lastfollowup": "Último Follow Up",
            "fup": "FUP",
            "issueidincident": "IDs dos incidentes",
            "clickissueidincident": "Clique para abrir o incidente",
            "hasissueidincident": "Tem incidentes que materializaram o issue?",
            "hasidpro3": "Tem soluções no PRO3 para integrar?",
            "hassupplierinvolved": "Tem fornecedor envolvido no issue?",
            "hasissueidindicator": "Tem indicadores no PRO3 para o issue?",
            "issueidindicator": "IDs dos indicadores do PRO3",
            "issueupload": "Uploads: anexe documentos relacionados ao issue registrado",
            "apupload": "Anexe documentos relacionados ao action plan registrado",
            "register": "Registrar",
            "edit": "Editar",
            "issueapproveClosure": "Aprovar fechamento",
            "issuedisapproveClosure": "Reprovar fechamento",
            "issuerequestClouse": "Solicitar fechamento",
            "issuehistory": "Histórico do Issue",
            "issueCantBeRiskAccept": "Issue deixar de ser risco assumido",
            "issueCantBeDraft": "Issue deixar de ser draft",
            "issueCantHaveOpenAp": "Fechar todos os planos de ação",
            "issueCreateAp": "Criar action plans",
            "issuePendingToClose": "O que falta para fechar o issue:",
            "popupUpdateRemediationDate": "Como o nível de risco diminuiu, a data de remediação foi definida para hoje. Se desejar, você pode alterar manualmente esta data na seção de Datas.",
            "issuedescriptionhistory": "Histórico da descrição do issue",
            "aphistory": "Histórico dos actions plans",
            "idissue": "ID Issue",
            "aptitle": "Título do AP",
            "apstatus": "Status",
            "approgress": "Progresso do AP",
            "apdescription": "Descreva detalhadamente o AP",
            "progress": "Progresso",
            "apdate": "Data de conclusão prevista",
            "apestimateddate": "Data de conclusão prevista",
            "apeactualdate": "Data de conclusão real",
            "apfinaldate": "Data Final",
            "type": "Tipo",
            "tatic": "Tático",
            "strategic": "Estratégico",
            "idPRO3integration": "ID PRO3 para integração",
            "apresponsibletodev": "Responsável pelo plano de ação",
            "area": "Área",
            "editissue": "Editar Issue",
            "fuphistory": "Histórico do Follow Up",
            "date": "Data",
            "justificationOfDisapproveClouse": "Justificativa de reprovação do fechamento do issue",
            "justification": "Justificativa",
            "sendJustificationAndDisapprove": "Enviar justificativa e reprovar",
            "changeAPresultchangeIssue": "A mudança no action plan resultou nas seguintes mudanças no issue:",
            "issuechange": "Mudança no issue",
            "issueclosure": "Fechamento do issue",
            "issueclosuretext": "Dado o status dos action plans, o issue pode ser fechado. Você deseja solicitar o fechamento ao responsável da área?",
            "emailSendToApprover": "Um email foi enviado aos heads da área de controle solicitando seu fechamento.",
            "deniedRequestEmailSend": "Justificativa de recusa foi enviada por e-mail ao solicitante.",
            "requestClosure": "Solicitar fechamento",
            "validation": "Validação",
            "open": "Aberto",
            "opening": "Em abertura",
            "canceled": "Cancelado",
            "riskaccepted": "Risco Assumido",
            "pendingrequesttoclose": "Solicitar Fechamento",
            "pendingapprovaltoclose": "Aprovação Head",
            "closed": "Fechado",
            "timelineissue": "Timeline do Issue",
            "closeall": "Fechar os ",
            "approvalfrom": "aprovação do ",
            "theareahead": "head da área",
            "obstimelineissue": "Obs.: Se todos os Action Plans forem cancelados, o Issue também será cancelado.",
            "statuschangedof": "- Status alterado de '",
            "to": "' para '",
            "progresschangedof": "- Progresso alterado de '",
            "datechangedof": "- Data estimada para fechar o issue alterada para ",
            "removeddate": "- Data estimada para fechar o issue removida",
            "duetochangeof": "devido a alteração de ",
            "toast8SubIssue": "Issue não encontrado",
            "toast8SubAP": "Action Plan não encontrado",
            "attentionActionPlan": "As alterações do Action Plan só serão realmente salvas ao salvar o",
            "attention": "Atenção!",

            "bdpoPending": "Pendente",
            "bdpoFilled": "Preenchido",
            "attachmentsEmpty": "Arraste ou clique para enviar arquivos.",
            "bdpoIncidentsEmpty": "Ops, parece que não há nenhum incidente BDPO por aqui.",
            "myIncidentsEmpty": "Ops, parece que não há nenhum incidente seu por aqui.",
            "reviewedByMeEmpty": "Ops, parece que não há nenhum incidente revisado por você.",
            "biaEmpty": "Ops, parece que não há nenhuma campanha BIA por aqui.",
            "fillBdpo": "Preencher BDPO",
            "generalData": "Dados gerais",
            "bdpoConfirmButton": "Confirmar preenchimento",
            "bdpoCancelButton": "Cancelar preenchimento",
            "bdpoAccountedPlaceholder": "Selecione a contabilização",
            "bdpoAccountedDescription": "Escolha se a perda está contabilizada na PSL",
            "bdpoAccounted": "Contabilizada na PSL",
            "bdpoLossStatusPlaceholder": "Escolha um status",
            "bdpoLossStatusDescription": "Defina se há perda e qual o seu status",
            "bdpoLossStatus": "Status da perda",
            "bdpoBusinessFunctionPlaceholder": "Selecione uma função de negócio",
            "bdpoBusinessFunctionDescription": "Escolha abaixo uma função de negócio para o incidente",
            "bdpoBusinessFunction": "Função de negócio",
            "bdpoCourtOrderPlaceholder": "Selecione abaixo uma das opções",
            "bdpoCourtOrderDescription": "Defina se há uma ordem judicial e a caracterização de sua perda",
            "bdpoCourtOrder": "Ordem judicial",
            "bdpoBaselLevelPlaceholder": "Selecione o nível de Basiléia",
            "bdpoBaselLevelDescription": "Escolha abaixo o nível de Basiléia para o incidente",
            "bdpoBaselLevel": "Nível de Basiléia",
            "bdpoRootIncidentRegistrationDescription": "Data em que o incidente perda raiz foi registrado",
            "bdpoRootIncidentRegistration": "Registro perda raiz",
            "bdpoRootIncidentNumber": "Número do incidente perda raiz",
            "bdpoRootIncident": "Incidente perda raiz",
            "bdpoRootDescription": "Escolha uma opção que caracteriza o incidente",
            "bdpoRootLoss": "Perda raiz",
            "bdpoEventTypePlaceholder": "Selecione o tipo de evento",
            "bdpoEventTypeDescription": "Escolha uma opção que caracteriza o incidente",
            "bdpoEventType": "Tipo do evento",
            "bdpoEventNumber": "Número do evento",
            "bdpoField": "Dados BDPO",
            "summaryTitleActive": "Resumo dos incidentes apresentados na lista abaixo",
            "summaryTitleDisabled": "Para ver o resumo, sua lista deve ter pelo menos 3 incidentes e no máximo 50 incidentes.",
            "seeSummary": "Ver resumo",
            "closeSummary": "Fechar resumo",
            "refreshAuth": "Atualizar autenticação",
            "incidentID": "ID do Incidente",
            "correspondingIncidents": "incidentes correspondentes",
            "correspondingIssues": "issues correspondentes",
            "incident": "Incidente",
            "myIncidents": "Meus Incidentes",
            "assignIncidents": "Atribuir Incidentes",
            "assignedIncidents": "Incidentes Atribuídos",
            "reviewedByMe": "Revisados por mim",
            "dateOfOccurrence": " Data de Ocorrência",
            "dateOfOccurrenceSub": "Em qual data ocorreu o incidente?",
            "dateOfDiscovery": " Data de Descoberta",
            "dateOfDiscoverySub": "Em qual data o evento foi descoberto?",
            "dateOfRegistration": "Data de Registro",
            "dateOfUpdate": "Data da última atualização",
            "description": " Descrição",
            "descriptionMinCaract": " Descrição (min. 100 caracteres)",
            "descriptionAPMinCaract": " Descrição (min. 50 caracteres)",
            "descriptionSub": "Relate o incidente dando destaque na descrição dos impactos, na causa do problema ocorrido, como foi descoberto, e que ações foram executadas para resolução. <br> <b>O campo de Descrição aceita imagem.</b> Você pode colar (CTRL + V) prints abaixo. <b>(O campo deve ter no mínimo 100 caracteres)</b>",
            "descriptionText": "Descreva o incidente com detalhes",
            "hashtag": " Hashtags",
            "hashtagSub": " Inclua aqui, em formato de #, palavras-chaves",
            "hashtagSuggestion": " Sugestão de Hashtag",
            "hashtagSuggestionSub": "Não encontrou a hashtag que gostaria? Faça sua sugestão para avaliarmos a inclusão da #hashtag em nossa relação.",
            "basel": " Nível de Basiléia",
            "baselSub": "Qual é o tipo de falha operacional em que o incidente se enquadra de acordo com os níveis de Basileia?",
            "riskFactorSub": "Qual foi o fator que originou o incidente?",
            "system": " Sistema",
            "systemSub": "Quais os sistemas envolvidos no incidente?",
            "products": " Produtos",
            "productsSub": " Qual esteira de produtos ou serviços foi impactada?",
            "capabilitySub": "Escolha o macro processo relacionado",
            "company": " Empresa",
            "companySub": "Qual entidade do BTG foi impactada pelo incidente?",
            "originatedArea": " Área que originou",
            "originatedAreaSub": "Qual foi a área que originou o incidente?",
            "impactedAreas": " Áreas Impactadas",
            "impactedAreasSub": "Quais foram as áreas impactadas pelo incidente?",
            "impacts": " Impactos",
            "impactsSub": "Responda as perguntas sobre os impactos do incidente",
            "op": "Operacional",
            "reg": "Regulatório",
            "rep": "Reputacional",
            "fin": "Financeira",
            "op01": "Quanto tempo o problema demorou para ser resolvido taticamente? (em horas)",
            "op02": "Quantas áreas foram envolvidas para resolver o problema?",
            "reg01": "Houve algum impacto ou sanção regulatória neste incidente?",
            "rep01": "O incidente impactou quantos clientes diretamente? (contrapartes/parceiros)",
            "rep01Input": "Número exato de clientes",
            "marioInputToolTip": "Clique fora da caixinha para salvar!",
            "marioStatus": "Não calculado",
            "rep02": "Qual a natureza do impacto reputacional desse incidente?",
            "fin01": "Qual foi a natureza do principal impacto financeiro?",
            "fin02": "Houve movimentação entre contas ou impacto em conta corrente?",
            "fin03": "Quais são o valor e a moeda associados a esse impacto?",
            "fin04": "Há possibilidade de recuperação/reversão do valor?",
            "forceRisk": "Forçar Risco",
            "forceRiskJustification": "Justificativa",
            "forceRiskJustificationSub": "Incluir justificativa para alteração do risco",
            "eventOriginSub": "Incluir evento de origem do incidente",
            "accountingDate": "Data de contabilização",
            "accountingDateSub": "Em qual data foi contabilizado?",
            "managerialImpact": "Impacto Gerencial",
            "managerialImpactSub": "Perda Líquida (R$): O sinal negativo (-) indica ganho, enquanto a ausência de sinal representa perda.",
            "financialTransfer": "Transferência Financeira",
            "financialTransferTotal": "transferencia(s), totalizando",
            "financialTransferSub": "Para este incidente, você vai precisar fazer transferências financeiras? <br> (Estorno, Reembolso, Antecipação, Multa ou Transferência interna)",
            "financialTransferModalHead": "Instruções",
            "financialTransferModalHeadSub": `<p></p>Informar as transferências a serem feitas no contexto deste incidente.<br /> Se preferir, você pode copiar uma tabela Excel (Ctrl + C) e clicar no botão <b >Botão Colar</b> à direita. <ul> <li>Formato da coluna: texto, texto, número, lista, lista.</li> <li>Não use vírgulas, o separador decimal é um ponto.</li> <li>Você pode baixar um preenchimento modelo clicando no botão abaixo.</li> </ul>`,
            "financialTransferModalCurrency": "Moeda",
            "financialTransferModalAddLine": "Adicionar Linha",
            "financialTransferModalDeleteLine": "Excluir Linha",
            "financialTransferModalApplyCurrency": "Aplicar moeda a todos",
            "financialTransferModalCategory": "Categoria",
            "financialTransferModalApplyCategory": "Aplicar categoria a todos",
            "financialTransferModalOriginAccount": "Conta Origem",
            "financialTransferModalDestinationAccount": "Conta Destino",
            "financialTransferModalValue": "Valor",
            "transfersResume": "Resumo das Transferências",
            "uploadSub": "Anexe os documentos relacionados ao incidente registrado.",
            "uploadText": "Arraste e solte arquivos para subir.",
            "cc": "Com cópia para",
            "ccSub": "Quem deve estar em cópia no email de registro do incidente?",
            "registeredBy": "Registrado por",
            "requestedBy": "Requerido por",
            "buttonSend": "Enviar",
            "buttonSave": "Salvar",
            "buttonCancel": "Cancelar",
            "buttonReview": "Revisar",
            "buttonClose": "Fechar",
            "buttonSelect": "Selecionar",
            "buttonClean": "Limpar",
            "buttonPaste": "Colar",
            "buttonGenerate": "Gerar",
            "buttonAccept": "Aceitar",
            "buttonView": "Visualizar",
            "buttonAssign": "Atribuir",
            "buttonReAssign": "Reatribuir",
            "buttonBatchAssign": "Atribuir em lote",
            "buttonReload": "Recarregar",
            "buttonRefuse": "Rejeitar",
            "buttonYes": "Sim",
            "buttonNo": "Não",
            "confirmRefuse": "Confirmar Rejeição",
            "menuButtonNew": "Novo",
            "generatedTweet": "Tweet Gerado",
            "menuButtonSearch": "Buscar",
            "optionYes": "Sim",
            "optionNo": "Não",
            "extraFields": "Campos Extras",
            "incidentHistory": "Histórico do incidente",
            "status-2": "Aposentado",
            "status0": "Rejeitado",
            "status1": "Fechado",
            "status2": "Pendente Distribuição",
            "status3": "Pendente Revisão",
            "status4": "Revisado",
            "searchOptions": "Opções de busca",
            "searchOptionDate": "Data",
            "searchOptionDateSub": "Qual data quer olhar?",
            "searchOptionArea": "Área",
            "searchOptionDescription": "Descrição",
            "start": "Início",
            "end": "Fim",
            "occurrence": "Ocorrência",
            "discovery": "Descoberta",
            "record": "Registro",
            "result": "RESULTADOS",
            "lowMario": "Baixo",
            "mediumMario": "Médio",
            "highMario": "Alto",
            "criticalMario": "Crítico",
            "fowardButton": "Encaminhar Incidente",
            "downloadButton": "Download",
            "buttonEdit": "Editar",
            "buttonEdit2": "Edições permitidas para RO e usuário de registro",
            "editTweet": "Editar Tweet",
            "reviewDisabled": "Nível de Risco Baixo",
            "users": "Usuários",
            "user": "Usuário",
            "hierarchy": "Hierarquia",
            "reviewedBy": "Revisado Por",
            "responsibleToReview": "Responsável pela Revisão",
            "log0": "O",
            "log1": "foi",
            "log2": "criado",
            "log3": "Alterou",
            "log4": "de",
            "log5": "para",
            "log6": "Adicionou",
            "log7": "a data",
            "log8": "ao campo",
            "log9": "migrado do Archer",
            "log10": "Removeu",
            "statusCreated": "criado",
            "statusUpdated": "atualizado",
            "statusReviewed": "Revisado",
            "dialog0": "Sucesso",
            "dialog1": "Seu incidente foi",
            "dialogIssue1": "Seu issue foi",
            "toast0": "Você está agora editando o incidente",
            "toast1": "Você está agora revisando o incidente",
            "toast2": "Transferências incompletas",
            "toast3": "Formato não suportado!",
            "toast3Sub": "O formato do arquivo não é suportado: ",
            "toast4": "O Tweet deve ter mais caracteres",
            "toast5": "Campo vazio!",
            "toast5Sub": "O campo não pode ficar vazio",
            "toast6": "Algo deu errado",
            "toast6Sub": "Favor entrar em contato com o time de Risco Operacional",
            "toast7": "Campos obrigatórios em branco",
            "toast7Sub": "Por favor preencher seguintes campos:",
            "toast8": "Erro ao buscar",
            "toast8Sub": "Nenhum incidente correspondente",
            "toast9": "Erro ao atribuir!",
            "toast10": "Incidente atribuído!",
            "toast11": "Selecione pelo menos 1 incidentes para distribuir em lote!",
            "toast12": "Selecione um usuário!",
            "toast13": "Nome do incidente copiado com sucesso",
            "issueCopied": "ID do issue copiado com sucesso",
            "toast14": "Descrição do incidente copiada com sucesso",
            "toast15": "Preencha todos os campos destacados como obrigatórios",
            "toast16": "Os campos de BDPO foram preenchidos com sucesso",
            "includeRelatedIds": "Incluir id's de incidentes relacionados",
            "relatedIncidents": "Incidentes relacionados",
            "insertValidId": "Insira um id válido",
            "notFound": " não encontrado",

            //bia translation
            "generalDataBia": "Dados gerais",
            "companyAssociatedWithProcess": "Empresa associada ao processo",
            "capability": "Capability",
            "capabilityAssociatedWithProcess": "Capability associada ao processo",
            "areaToWhichProcessBelongs": "Área a qual o processo pertence",
            "processDescription": "Descrição do processo",
            "processNarrative": "Narrativa do processo, que apresenta as atividades e controles pertinentes a execução do processo",
            "involvedEmployees": "Colaboradores envolvidos",
            "employeesInvolvedInProcess": "Colaboradores envolvidos na execução do processo no dia a dia (Buscar por nome ou email)",
            "backupEmployees": "Colaboradores backup",
            "employeesAbleToExecute": "Colaboradores aptos a executar o processo na ausência dos envolvidos (Buscar por nome ou email)",
            "executionLocations": "Locais de execução",
            "officeLocations": "Escritório(s) e/ou locais onde o processo é executado normalmente",
            "processExecutionFrequency": "Frequência de execução do processo",
            "executionPeriod": "Período de ocorrência de execução do processo",
            "executions": "Execuções",
            "dailyExecutionCount": "Quantas vezes ao dia o processo é executado?",
            "weekDays": "Dia(s) da semana ",
            "weekDaysExecution": "Em qual(is) dia(s) da semana o processo costuma ser executado?",
            "addExecutionTimes": "Adicionar horário(s) de execução",
            "addExecutionTime": "Adicionar horário de execução",
            "addCriticalExecutionTime": "Adicionar horário de execução crítico",
            "executionTimes": "Horário(s) de execução",
            "indicateExecutionPeriods": "Indique os períodos de horários em que o processo é executado",
            "addCriticalExecutionTimes": "Adicionar horário(s) de execução crítico(s)",
            "indicateCriticalExecutionPeriods": "Indique os períodos de horários em que a execução do processo é crítica",
            "selectPeriodCriticalExecution": "Selecione o período que a execução crítica está inserida",
            "addTimeRange": "Adicionar faixa de horário",
            "timeWindow": "Janela de horários",
            "periodsWhenExecuted": "Períodos em que o processo é executado no dia",
            "normalHours": "Horários normais",
            "criticalHours": "Horários críticos",
            "systems": "Sistemas",
            "doesProcessUseSystem": "O processo utiliza algum sistema?",
            "addSystem": "Adicionar sistema",
            "editSystem": "Editar sistema",
            "findSystemInList": "Encontre na lista o sistema desejado (Se não encontrar o sistema na lista, favor informar o time de risco operacional)",
            "selectSystem": "Selecione o sistema",
            "systemDescription": "Forneça uma breve descrição sobre como o sistema é empregado na execução do processo. (mínimo de 30 caracteres)",
            "potentialToHaltProcess": "Potencial de paralisar o processo",
            "couldInterruptionForceContingency": "Uma interrupção poderia forçar o uso de um procedimento de contingência?",
            "answerQuestion": "Responda a pergunta",
            "contingencyStrategy": "Estratégia de contingência",
            "contingencyDescription": "Descrição da contingência",
            "systemUsageDescription": "Descrição do uso do sistema",
            "contingencyDescriptionSubLabel": "Forneça de informações detalhadas sobre a estratégia de contingência. (mínimo de 30 caracteres)",
            "typesOfContingencyStrategies": "Tipos de estratégias de contingência disponíveis em caso de indisponibilidade do sistema",
            "backupSystem": "Sistema backup",
            "backupSystemSubLabel": "Identifique na lista o sistema que atuaria como backup do sistema principal, caso a estratégia de contingência inclua tal medida",
            "suppliers": "Fornecedores",
            "supplier": "Fornecedor",
            "supplierRoleDescription": "Descrição do papel do fornecedor",
            "doesProcessDependOnSupplier": "Execução do processo depende de algum fornecedor? (Caso utilize um sistema que é fornecido por um terceiro, favor incluir o fornecedor. É possível pesquisar utilizando o nome do sistema. Se não souber, entrar em contato com o time de risco operacional)",
            "addSupplier": "Adicionar fornecedor",
            "editSupplier": "Editar fornecedor",
            "typeCharacterizingSupplier": "Tipo que caracteriza o envolvimento do fornecedor no processo",
            "supplierName": "Nome do fornecedor",
            "enterSupplierName": "Insira o nome do fornecedor envolvido na execução do processo",
            "selectType": "Selecione o tipo",
            "describeSupplierInvolvement": "Descreva brevemente o envolvimento do fornecedor na execução do processo, ou seja, sua função dentro do mesmo",
            "couldSupplierAbsenceForceContingency": "A ausência do fornecedor poderia forçar o uso de um procedimento de contingência?",
            "typesOfContingencyStrategiesSupplier": "Tipos de estratégias de contingência disponíveis em caso de ausência do fornecedor escolhido.",
            "internalDependencies": "Dependências internas",
            "internalDependency": "Dependência interna",
            "doesProcessDependOnOtherAreas": "A execução desse processo depende do envolvimento de outras áreas da empresa? (Exemplos: troca de informações, suporte IT, outro)",
            "addDependency": "Adicionar dependência",
            "editDependency": "Editar dependência",
            "dependencyType": "Tipo da dependência",
            "dependencyRoleDescription": "Descrição do papel da dependência",
            "selectAreaForDependency": "Selecione a área na qual deseja determinar o tipo de dependência com o processo",
            "chooseDependencyType": "Escolha o tipo de dependência da área escolhida com o processo",
            "describeDependency": "Descreva brevemente o tipo de dependência que o processo tem com a área selecionada",
            "personalDataHandling": "Tratamento de Dados Pessoais",
            "personalDataHandlingLGPD": "Tratamento de Dados Pessoais - LGPD/GDPR/ADPPA",
            "personalDataHandlingSublabel": "O tratamento de dados pessoais refere-se a qualquer operação realizada com dados que identifiquem uma pessoa, como coleta, armazenamento, processamento ou compartilhamento.",
            "personalInformation": "Informações pessoais",
            "doesProcessHandlePersonalInformation": "O processo manipula alguma informação pessoal?",
            "sensitiveInformation": "Informações sensíveis",
            "doesProcessHandleSensitiveInformation": "O processo manipula alguma informação sensível?",
            "confidentialInformation": "Informações confidenciais",
            "doesProcessHandleConfidentialInformation": "O processo manipula alguma informação confidencial?",
            "criticalityClassification": "Classificação de criticidade",
            "oneHour": "1 hora",
            "threeHours": "3 horas",
            "sixHours": "6 horas",
            "twelveHours": "12 horas",
            "twentyFourHours": "24 horas",
            "addJustification": "Adicionar justificativa",
            "financial": "Financeiro",
            "regulatory": "Regulatório",
            "reputational": "Reputacional",
            "operational": "Operacional",
            "customers": "Clientes",
            "immaterial": "Imaterial",
            "low": "Baixo",
            "medium": "Médio",
            "high": "Alto",
            "extreme": "Extremo",
            "describeHighFinancialImpact": "Descreva o motivo para o impacto financeiro ser alto ou extremo durante a execução do processo",
            "openJustification": "Abrir justificativa",
            "calculatedImpact": "Impacto calculado",
            "calculatedImpactFromValues": "Impacto calculado a partir dos valores inseridos na tabela",
            "roImpact": "Impacto RO",
            "roTeamAnalysisImpact": "Impacto determinado pela análise do time de RO",
            "justifyImpactValue": "Justificar a escolha do valor do Impacto (mínimo de 30 caracteres)",
            "finalImpact": "Impacto final",
            "finalImpactValue": "Valor definido a partir da comparação do impacto calculado e RO",
            "rtoRpo": "RTO / RPO",
            "recoveryTimeObjective": "Recovery Time Objective",
            "calculatedRTOValue": "Valor de RTO calculado a partir dos dados anteriores",
            "userRecoveryTimeObjective": "Recovery Time Objective Usuário",
            "chooseRTOValue": "Escolha um valor para RTO caso discorde do valor calculado",
            "justifyRTOValue": "Justificar a escolha do valor de RTO",
            "recoveryPointObjective": "Recovery Point Objective",
            "maxTolerableDataLoss": "Tempo máximo tolerável para perda de dados em caso de falha",
            "contingency": "Contingência",
            "minimumNumberOfPeople": "Quantidade mínima de pessoas",
            "minimumPeopleForContingency": "Quantidade mínima necessária para executar o processo em uma situação de contingência",
            "keyPersonnel": "Pessoas chaves",
            "keyPersonnelForCrisis": "Pessoas essenciais para execução do processo em um cenário de crise (Buscar por nome ou email)",
            "saveDraft": "Salvar rascunho",
            "submitForm": "Enviar formulário",
            "approve": "Aprovar",
            "reject": "Recusar",
            "submitFormInstructions": "Após clicar em 'Confirmar envio', o formulário será encaminhado para a aprovação do BPM. Somente em caso de rejeição, o REX poderá editar o formulário novamente.",
            "confirmDeletion": "Deletar",
            "deleteProcessInstructions": "Após clicar em ‘Deletar’, o formulário será excluído da campanha e o processo desativado.",
            "confirmApprovalNote": "Após clicar em 'Confirmar aprovação', o formulário será encaminhado para a validação de RO. Você não conseguirá mais editá-lo.",
            "confirmApproval": "Confirmar aprovação",
            "confirmFinalizationNote": "Após clicar em 'Confirmar aprovação', o formulário será finalizado.",
            "confirmSubmission": "Confirmar envio",
            "returnToCampaignPage": "Retornar à página da campanha",
            "returnToCampaignList": "Retornar à lista de campanhas",
            "navigation": "Navegação",
            "addPerson": "Adicionar pessoa",
            "selectLocation": "Selecione um local",
            "selectPeriod": "Selecione um período",
            "selectOption": "Selecione uma opção",
            "saveTime": "Salvar horário",
            "daysOfMonth": "Dia(s) do mês",
            "daysOfMonthExecution": "Em qual(is) dia(s) do mês o processo costuma ser executado?",
            "firstSemester": "Primeiro semestre",
            "firstSemesterExecution": "Em qual dia e mês do primeiro semestre o processo costuma ser executado?",
            "secondSemester": "Segundo semestre",
            "secondSemesterExecution": "Em qual dia e mês do segundo semestre o processo costuma ser executado?",
            "dayAndMonth": "Dia e mês",
            "dayAndMonthSelection": "Selecione o dia e mês do ano em que o processo é executado",
            "otherOption": "Outra opção",
            "otherScenarios": "Escreva abaixo os cenários em que o processo é executado",
            "timeConflict": "Não pode haver conflito de horários",
            "criticalTimeExceedsRange": "Horário crítico não pode ser maior que o range",
            "january": "Janeiro",
            "february": "Fevereiro",
            "march": "Março",
            "april": "Abril",
            "may": "Maio",
            "june": "Junho",
            "july": "Julho",
            "august": "Agosto",
            "september": "Setembro",
            "october": "Outubro",
            "november": "Novembro",
            "december": "Dezembro",
            "impactDescription": "Descreva o motivo para o impacto ser alto ou extremo durante a execução do processo. (mínimo de 30 caracteres)",
            "rejectionJustification": "Justificativa de rejeição",
            "rejectionReasonDescription": "Descreva o(s) motivo(s) para rejeição do formulário (mínimo de 30 caracteres)",
            "formRejectedByBPM": "Formulário rejeitado pelo BPM",
            "formRejectedByRO": "Formulário rejeitado por RO",
            "viewJustification": "Ver justificativa",
            "biaFieldFormStatus": "Status",
            "biaFieldDailyExecutionTeam": "Colaboradores envolvidos",
            "biaFieldBackupExecutionTeam": "Colaboradores backup",
            "biaFieldNormalExecutionLocations": "Locais de execução",
            "biaFieldExecutionHours": "Horários de execução",
            "biaFieldCriticalExecutionHours": "Horários de execução críticos",
            "biaFieldSystem": "Sistemas",
            "biaFieldSupplier": "Fornecedores",
            "biaFieldInternalDependency": "Dependências internas",
            "biaFieldPersonalInfo": "Informações pessoais",
            "biaFieldSensitiveInfo": "Informações sensíveis",
            "biaFieldConfidentialInfo": "Informações confidenciais",
            "biaFieldImpact": "Tabela de criticidade",
            "biaFieldCalculatedImpact": "Criticidade calculada",
            "biaFieldRoImpactJustification": "Justificativa de alteração da criticidade",
            "biaFieldUserRtoJustification": "Justificativa de alteração do RTO",
            "biaFieldContingencyMinimumRequiredPeople": "Quantidade mínima de pessoas",
            "biaFieldContingencyEmployees": "Pessoas chaves",
            "biaFieldPeriodType": "Frequência de execução do processo",
            "biaFieldBpmRejectionJustification": "Justificativa de rejeição (BPM)",
            "biaFieldRoRejectionJustification": "Justificativa de rejeição (RO)",
            "biaForm": "Formulário bia",
            "biaStatus1": "Preencher REX",
            "biaStatus2": "Aprovação BPM",
            "biaStatus3": "Aprovação RO",
            "biaStatus4": "Finalizado",
            "biaStatus5": "Revisar REX",
            "invalidJustification": "Justificativa inválida",
            "invalidJustificationLength": "A justificativa deve ter pelo menos 30 caracteres",
            "timeAdded": "Horário adicionado!",
            "blankFields": "Campos em branco",
            "fillFields": "Preencher os campos: ",
            "systemAdded": "Sistema adicionado",
            "systemEdited": "Sistema editado",
            "supplierAdded": "Fornecedor adicionado",
            "supplierEdited": "Fornecedor editado",
            "dependencyAdded": "Dependência adicionada",
            "dependencyEdited": "Dependência editada",
            "atLeastOneExecutionTime": "Deve haver pelo menos um horário de execução",
            "formSaved": "Formulário salvo!",
            "saveError": "Erro ao salvar! Contacte o time de RO",
            "sendError": "Erro ao enviar! Contacte o time de RO",
            "rejectError": "Erro ao rejeitar! Contacte o time de RO",
            "approvalError": "Erro ao aprovar! Contacte o time de RO",
            "tooltipIndicateInvolvedEmployees": "Indique os colaboradores que normalmente estão envolvidos na execução do processo.",
            "tooltipIndicateBackupEmployees": "Indique os colaboradores que estão aptos a executar o processo, caso os colaboradores usuais não estejam disponíveis.",
            "tooltipIndicateExecutionLocations": "Indique os locais em que o processo normalmente é realizado.",
            "tooltipIndicateCriticalPeriods": "Se houver, indique nesse campo períodos críticos compreendidos dentro da janela de execução do processo ou seja períodos em que uma interrupção no processo causariam um maior impacto.",
            "tooltipMapCriticalSystems": "Essa informação é utilizada para mapear sistemas críticos, ou seja, sistemas vitais para a execução das atividades relativas ao processo e cuja interrupção afetaria significativamente a capacidade de executar um processo crítico.",
            "tooltipMapCriticalSuppliers": "Essa informação é utilizada para mapear fornecedores ou terceiros críticos, fornecedores críticos são aqueles cuja falha em fornecer produtos ou serviços pode interromper significativamente a capacidade de executar o processo. A dependência desses fornecedores requer que a empresa tenha planos para mitigar os riscos associados a qualquer interrupção no fornecimento.",
            "tooltipIndicateInternalDependencies": "Dependências internas referem-se às interações e inter-relações entre diferentes áreas ou departamentos dentro de uma organização que são essenciais para manter as operações ininterruptas. A interrupção em uma área pode impactar negativamente outras áreas dependentes, comprometendo a continuidade das operações gerais.",
            "tooltipPersonalInfoDescription": "Informações que permitem identificar uma pessoa física ou torna possível a sua identificação. Exemplos incluem nome, endereço, número de telefone, CPF, e-mail, e dados de localização.",
            "tooltipSensitiveInfoDescription": "Categoria especial de dados pessoais que revelam informações sobre origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou organização de caráter religioso, filosófico ou político, dados relacionados à saúde ou à vida sexual, dados genéticos ou biométricos.",
            "tooltipConfidentialInfoDescription": "Qualquer informação que deve ser mantida em sigilo por envolver privacidade ou segurança, abrangendo tanto dados pessoais quanto informações empresariais sigilosas.",
            "tooltipImpactMap": "Impact map",
            "tooltipJustifyHighImpact": "Caso sejam identificados impactos altos ou extremos é necessário fornecer uma breve explicação para justificar o impacto.",
            "tooltipRtoDefinition": "Objetivo de Tempo de Recuperação (RTO): O Objetivo de Tempo de Recuperação é o tempo máximo que deve levar para que seus sistemas e serviços voltem a funcionar após um problema ou desastre. Por exemplo, se seu RTO é de 4 horas, isso significa que se houver um problema em algum recurso necessário para execução do processo (por exemplo, um sistema crítico) é necessário que o problema seja solucionado até no máximo 4 horas após a interrupção.",
            "tooltipRpoDefinition": "Objetivo de Ponto de Recuperação (RPO): O Objetivo de Ponto de Recuperação se refere a quanto de dados podem ser perdidos em caso de um incidente relevante ou desastre. Ele mede o tempo máximo que pode passar entre o último backup de dados e uma possível falha. Por exemplo, se seu RPO é de 1 hora, os backups dos dados utilizados no processo devem ser realizados em no máximo 1 hora para evitar impactos em caso de dados.",
            "tooltipIndicateMinimumRequiredPeople": "Indique o número mínimo de pessoas necessário para executar um processo em uma situação que exija a atuação em contingência.",
            "tooltipClientImpact": "Impacto nos clientes é caracterizado pela insatisfação, perda de confiança e potencial perda de clientes devido a falhas ou interrupções na entrega de produtos ou serviços. Dessa forma, selecione o impacto de uma interrupção do processo para cada um dos períodos.",
            "tooltipOperationalImpact": "Impacto operacional é caracterizado pela interrupção das atividades diárias, causando atrasos, ineficiências e possíveis paralisações na produção ou prestação de serviços. Dessa forma, selecione o impacto de uma interrupção do processo para cada um dos períodos.",
            "tooltipReputationalImpact": "Impacto reputacional é caracterizado pela perda de confiança e credibilidade junto a clientes, investidores e outros stakeholders, afetando a imagem pública da empresa. Dessa forma, selecione o impacto de uma interrupção do processo para cada um dos períodos.",
            "tooltipRegulatoryImpact": "Impacto regulatório é caracterizado pelo não cumprimento de normas, leis ou regulamentos, o que pode resultar em multas, sanções ou outras ações legais. Dessa forma, selecione o impacto de uma interrupção do processo para cada um dos períodos.",
            "tooltipFinancialImpact": "Impacto financeiro é caracterizado pela perda de receita, aumento de custos ou outras consequências econômicas adversas resultantes de uma interrupção do processo. Dessa forma, selecione o impacto de uma interrupção do processo para cada um dos períodos.",
            "toBia": "até",
            "biaMenu1": "Campanhas",
            "biaMenu2": "Nova campanha",
            "biaCampaignStatus1": "Em andamento",
            "biaCampaignStatus2": "Finalizado",
            "biaCampaign": "Campanhas BIA",
            "biaFilterAreas": "Selecione a(s) área(s) que deseja filtrar",
            "biaFilteredAreas": "Áreas filtradas",
            "biaCreation": "Criação",
            "biaExpectedConclusion": "Conclusão prevista",
            "biaSelectStatus": "Selecione um ou mais status",
            "biaSelectRex": "Selecione um ou mais REX's",
            "biaSelectBpm": "Selecione um ou mais BPM's",
            "biaStatusView": "Visualização Status",
            "biaFilteredStatus": "Status filtrados",
            "biaFilteredRex": "REX's filtrados",
            "biaFilteredBpm": "BPM's filtrados",
            "deletedField": "O valor do campo foi deletado",
            "newCampaign": "Nova campanha",
            "campaignName": "Nome da campanha",
            "chooseCampaignName": "Escolha um nome para identificar a campanha",
            "rexBpmDeadline": "Prazo REX e BPM",
            "chooseRexBpmDeadline": "Escolha um prazo, em dias, para o REX e BPM realizarem suas tarefas",
            "roTeamDeadline": "Prazo time RO",
            "chooseRoTeamDeadline": "Escolha um prazo, em dias, para o time de RO aprovar os BIAs",
            "areasProcesses": "Áreas e processos",
            "searchAreas": "Pesquise as áreas que deseja encontrar",
            "registerNewCampaign": "Registrar nova campanha",
            "chooseAreasProcesses": "Escolha as áreas e seus processos que farão parte da campanha que está criando",
            "campaignCreatedSuccess1": "A campanha",
            "campaignCreatedSuccess2": "foi criada com sucesso!",
            "goToCampaignPage": "Ir para a página da campanha",
            "createNewCampaign": "Criar nova campanha",
            "selectAtLeastOneBPM": "Selecione pelo menos um BPM!",
            "selectAtLeastOneREX": "Selecione pelo menos um REX!",
            "selectAtLeastOneStatus": "Selecione pelo menos um status!",
            "selectAtLeastOneArea": "Selecione pelo menos uma área!",
            "entubaInstructionHead": "Instruções",
            "entubaInstructionHeadSub": `<p></p>Cada linha corresponde a um incidente a ser entubado.<br /> Se preferir, você pode copiar do template com o Botão <b>Copiar Planilha</b> no Excel e clicar no botão <b>Botão Colar</b> na parte superior direita.<ul><li>Na planilha, haverá instruções de como ela pode ser utilizada. <b>Se atente a elas!</b></li><li>Após o download, clique com o botão direito no arquivo da planilha, vá em propriedades e habilite o desbloqueio de macros nela (no canto inferior direito) e por fim clique em OK.</li><li>Na própria planilha, há imagens para ajudar nestas etapas. Clique abaixo para fazer download do template:</li></ul>`,
            "invalidValue": "Valor inválido",
            "postoneReauthenticate": "Adiar 15min",
            "changeRolesTextBody": "Suas permissões foram alteradas. Ao clicar em 'Reautenticar', TODAS as guias do STORM serão recarregadas. Certifique-se de salvar seu trabalho antes de prosseguir!",
            "changeRolesManualTextBody": "Ao clicar em 'Reautenticar', TODAS as guias do STORM serão recarregadas. Certifique-se de salvar seu trabalho antes de prosseguir!",
            "reauthenticateRoles": "Reautenticar roles",
            "includeRelatedIdsIssue": "Incluir id's de issues relacionados",
            "relatedIssues": "Issues relacionados",
            "reviewedDescription": "Descrição Revisada",
            "reviewedDescriptionSublabel": "Campo destinado à revisão da descrição pelo time de Risco Operacional.",
        },
        "es_es": {
            "invalidSystem" : "El sistema de copia de seguridad debe ser diferente del sistema seleccionado",
            "reviewedDescription": "Descripción Revisada",
            "reviewedDescriptionSublabel": "Campo destinado a la revisión de la descripción por el equipo de Riesgo Operacional.",
            "includeRelatedIdsIssue": "Incluir ids de issues relacionados",
            "relatedIssues": "Issues relacionados",
            "person": "Persona",
            "stormNewVersionAvailable": "Una nueva versión de STORM está disponible",
            "clickToUpdatePage": "Haga clic en el botón para actualizar la página",
            "refreshToken": "Reautenticar",
            "field": "Campo",
            "fieldRequired": "Campo Obligatorio",
            "newActionPlan": "Nuevo Plan de Acción",
            "buttonnewActionPlan": "Crear Nuevo Plan de Acción",
            "editActionPlan": "Editar Plan de Acción",
            "buttonsaveActionPlan": "Guardar",
            "linkOldActionPlan": "Vincular Plan de Acción Existente",
            "owner": "Responsable",
            "action": "Acción",
            "accountingAccount": "Cuentas Contables",
            "accountingAccountSub": "Campo de texto para las cuentas contables",
            "applicationFromModeForm": "Formulario",
            "applicationFromModeEntuba": "Entuba",
            "applicationFromModeMigrate": "Migrado",
            "returnToIncident": "Regresar al incidente",
            "previous": "Anterior",
            "modified": "Modificado",
            "clean": "Limpiar",
            "extraFieldsIncident": "Campos extras",
            "file": "Archivo",
            "labelGuide": "Ayuda",
            "stormGuide": "Guía del usuario Storm",
            "issue": "Problema",
            "Issue": "Problema",
            "title": "Título",
            "filter": "Filtrar",
            "titleminap": "Título (mín. 5 caracteres)",
            "lowriskLevel": "Bajo",
            "mediumriskLevel": "Medio",
            "highriskLevel": "Alto",
            "improvement opportunityriskLevel": "Mejora Operacional",
            "issuetitle": "Título del Problema",
            "issuetitlemoreissueorigin": "Título + Área de Control",
            "summary": "Resumen",
            "on time": "A tiempo",
            "overdue": "Atrasado",
            "draft": "Borrador",
            "risk accepted": "Riesgo Aceptado",
            "closed on time": "Cerrado a tiempo",
            "closed overdue": "Cerrado con retraso",
            "myIssues": "Mis Problemas",
            "yes": "Sí",
            "no": "No",
            "controlAreaIssues": "Problemas del área de control",
            "issuesPendingapproval": "Problemas pendientes de aprobación",
            "issuestatus": "Estado",
            "issuestatusmoreissueorigin": "Estado + Área de Control",
            "issueprogress": "Progreso",
            "issuedescription": "Describa el problema con detalles",
            "issuedescriptionmoreissueorigin": "Descripción + Área de Control",
            "issueOrigin": "Área de control responsable",
            "yourissueOrigin": "Complete su área de control",
            "regulator": "Regulador",
            "issuerecurring": "¿Es un problema recurrente?",
            "idissuerecurring": "ID del problema recurrente",
            "textidissuerecurring": "Haga clic para ver el problema",
            "risk": "Riesgo",
            "issuerisklevel": "¿Cuál es el nivel de riesgo?",
            "issueriskfactor": "¿Cuál es el factor de riesgo?",
            "issuerisk": "¿Cuál es el riesgo involucrado?",
            "issueriskaccepted": "¿Es un riesgo aceptado?",
            "issueriskaccepteddate": "Fecha límite de aceptación de riesgo",
            "issuejustificationriskaccepted": "Justificación de la aceptación de riesgo",
            "report": "Informe",
            "issuereportname": "Nombre del informe",
            "issuereportnumber": "Número del informe",
            "issuereportdate": "Fecha del informe",
            "structure": "Estructura",
            "issuecompany": "Seleccione la entidad relacionada con el problema",
            "issuehierarchy": "Seleccione el área relacionada con el problema",
            "systemriskfactor": "Sistema (factor de riesgo)",
            "product": "Producto",
            "involved": "Involucrados",
            "focalPoint": "Puntos focales",
            "descfocalPoint": "Los puntos focales son las personas del área que resolverán dudas sobre el problema y el progreso de los planes de acción",
            "issueoriginresponsible": "Responsable del área de control",
            "descissueoriginresponsible": "Es la persona del área de control (Riesgo Operacional, Auditoría, CTB) que será responsable de seguir el progreso del problema",
            "issueowner": "Dueño del problema",
            "descissueowner": "Es el responsable del área o negocio por el problema",
            "supplierinvolved": "Proveedor",
            "supplierinvolvedQuestion": "Seleccione el proveedor involucrado",
            "actionplan": "Plan de acción",
            "actionplans": "Planes de acción",
            "actionplansub": "Registre los planes de acción para este problema",
            "dates": "Fechas",
            "estimatedCompletionDate": "Fecha de finalización estimada",
            "estimatedCompletionDateTitle": "No editable, campo calculado automáticamente con base en los planes de acción. <br><br>Primera fecha de finalización estimada para completar todos los planes de acción",
            "actualCompletionDate": "Fecha de finalización ajustada",
            "actualCompletionDateTitle": "No editable, campo calculado automáticamente con base en los planes de acción. <br><br>Fecha de finalización ajustada para completar todos los planes de acción. Es decir, si la primera fecha estimada es alterada, este campo será completado.",
            "remediationDate": "Fecha de remediación",
            "remediationDateTitle": "Editable.<br><br> Fecha en la cual el nivel de riesgo disminuyó - por ejemplo, pasó de nivel de riesgo alto a medio.",
            "mitigationDate": "Fecha de mitigación",
            "mitigationDateTitle": "No editable. <br><br>Fecha de cierre del problema",
            "otherinfos": "Información adicional",
            "followup": "Seguimiento",
            "followupMinCaract": "Seguimiento con mínimo de 20 caracteres",
            "lastfollowup": "Último Seguimiento",
            "fup": "FUP",
            "issueidincident": "IDs de los incidentes",
            "clickissueidincident": "Haga clic para abrir el incidente",
            "hasissueidincident": "¿Tiene incidentes que materializaron el problema?",
            "hasidpro3": "¿Tiene soluciones en PRO3 para integrar?",
            "hassupplierinvolved": "¿Tiene proveedor involucrado en el problema?",
            "hasissueidindicator": "¿Tiene indicadores en PRO3 para el problema?",
            "issueidindicator": "IDs de los indicadores del PRO3",
            "issueupload": "Subidas: adjunte documentos relacionados con el problema registrado",
            "apupload": "Adjunte documentos relacionados con el plan de acción registrado",
            "register": "Registrar",
            "edit": "Editar",
            "issueapproveClosure": "Aprobar cierre",
            "issuedisapproveClosure": "Reprobar cierre",
            "issuerequestClouse": "Solicitar cierre",
            "issuehistory": "Historial del Problema",
            "issueCantBeRiskAccept": "El problema deja de ser riesgo aceptado",
            "issueCantBeDraft": "El problema deja de ser borrador",
            "issueCantHaveOpenAp": "Cerrar todos los planes de acción",
            "issueCreateAp": "Crear planes de acción",
            "issuePendingToClose": "Lo que falta para cerrar el problema:",
            "popupUpdateRemediationDate": "Como el nivel de riesgo disminuyó, la fecha de remediación se definió para hoy. Si lo desea, puede cambiar manualmente esta fecha en la sección de Fechas.",
            "issuedescriptionhistory": "Historial de la descripción del problema",
            "aphistory": "Historial de los planes de acción",
            "idissue": "ID Problema",
            "aptitle": "Título del PA",
            "apstatus": "Estado",
            "approgress": "Progreso del PA",
            "apdescription": "Describa detalladamente el PA",
            "progress": "Progreso",
            "apdate": "Fecha de finalización estimada",
            "apestimateddate": "Fecha de finalización estimada",
            "apeactualdate": "Fecha de finalización real",
            "apfinaldate": "Fecha Final",
            "type": "Tipo",
            "tatic": "Táctico",
            "strategic": "Estratégico",
            "idPRO3integration": "ID PRO3 para integración",
            "apresponsibletodev": "Responsable del plan de acción",
            "area": "Área",
            "editissue": "Editar Problema",
            "fuphistory": "Historial del Seguimiento",
            "date": "Fecha",
            "justificationOfDisapproveClouse": "Justificación de la reprobación del cierre del problema",
            "justification": "Justificación",
            "sendJustificationAndDisapprove": "Enviar justificación y reprobar",
            "changeAPresultchangeIssue": "El cambio en el plan de acción resultó en los siguientes cambios en el problema:",
            "issuechange": "Cambio en el problema",
            "issueclosure": "Cierre del problema",
            "issueclosuretext": "Dado el estado de los planes de acción, el problema puede ser cerrado. ¿Desea solicitar el cierre al responsable del área?",
            "emailSendToApprover": "Se ha enviado un correo electrónico a los jefes del área de control solicitando su cierre.",
            "deniedRequestEmailSend": "La justificación de la negativa ha sido enviada por correo electrónico al solicitante.",
            "requestClosure": "Solicitar cierre",
            "validation": "Validación",
            "open": "Abierto",
            "opening": "En apertura",
            "canceled": "Cancelado",
            "riskaccepted": "Riesgo Aceptado",
            "pendingrequesttoclose": "Solicitar Cierre",
            "pendingapprovaltoclose": "Aprobación del Jefe",
            "closed": "Cerrado",
            "timelineissue": "Cronología del Problema",
            "closeall": "Cerrar todos los ",
            "approvalfrom": "aprobación del ",
            "theareahead": "jefe del área",
            "obstimelineissue": "Nota: Si todos los Planes de Acción son cancelados, el Problema también será cancelado.",
            "statuschangedof": "- Estado cambiado de '",
            "to": "' a '",
            "progresschangedof": "- Progreso cambiado de '",
            "datechangedof": "- Fecha estimada para cerrar el problema cambiada a ",
            "removeddate": "- Fecha estimada para cerrar el problema eliminada",
            "duetochangeof": "debido al cambio de ",
            "toast8SubIssue": "Problema no encontrado",
            "toast8SubAP": "Plan de Acción no encontrado",
            "attentionActionPlan": "Los cambios en el Plan de Acción solo se guardarán realmente al guardar el",
            "attention": "¡Atención!",
            "bdpoPending": "Pendiente",
            "bdpoFilled": "Llenado",
            "attachmentsEmpty": "Arrastre o haga clic para enviar archivos.",
            "bdpoIncidentsEmpty": "Vaya, parece que no hay ningún incidente BDPO por aquí.",
            "myIncidentsEmpty": "Vaya, parece que no hay ningún incidente suyo por aquí.",
            "reviewedByMeEmpty": "Vaya, parece que no hay ningún incidente revisado por usted.",
            "biaEmpty": "Vaya, parece que no hay ninguna campaña BIA por aquí.",
            "fillBdpo": "Llenar BDPO",
            "generalData": "Datos generales",
            "bdpoConfirmButton": "Confirmar llenado",
            "bdpoCancelButton": "Cancelar llenado",
            "bdpoAccountedPlaceholder": "Seleccione la contabilización",
            "bdpoAccountedDescription": "Elija si la pérdida está contabilizada en la PSL",
            "bdpoAccounted": "Contabilizada en la PSL",
            "bdpoLossStatusPlaceholder": "Elija un estado",
            "bdpoLossStatusDescription": "Defina si hay pérdida y cuál es su estado",
            "bdpoLossStatus": "Estado de la pérdida",
            "bdpoBusinessFunctionPlaceholder": "Seleccione una función de negocio",
            "bdpoBusinessFunctionDescription": "Elija una función de negocio para el incidente",
            "bdpoBusinessFunction": "Función de negocio",
            "bdpoCourtOrderPlaceholder": "Seleccione una de las opciones",
            "bdpoCourtOrderDescription": "Defina si hay una orden judicial y la caracterización de su pérdida",
            "bdpoCourtOrder": "Orden judicial",
            "bdpoBaselLevelPlaceholder": "Seleccione el nivel de Basilea",
            "bdpoBaselLevelDescription": "Elija el nivel de Basilea para el incidente",
            "bdpoBaselLevel": "Nivel de Basilea",
            "bdpoRootIncidentRegistrationDescription": "Fecha en que se registró el incidente de pérdida raíz",
            "bdpoRootIncidentRegistration": "Registro de pérdida raíz",
            "bdpoRootIncidentNumber": "Número del incidente de pérdida raíz",
            "bdpoRootIncident": "Incidente de pérdida raíz",
            "bdpoRootDescription": "Elija una opción que caracterice el incidente",
            "bdpoRootLoss": "Pérdida raíz",
            "bdpoEventTypePlaceholder": "Seleccione el tipo de evento",
            "bdpoEventTypeDescription": "Elija una opción que caracterice el incidente",
            "bdpoEventType": "Tipo de evento",
            "bdpoEventNumber": "Número del evento",
            "bdpoField": "Datos BDPO",
            "summaryTitleActive": "Resumen de los incidentes presentados en la lista a continuación",
            "summaryTitleDisabled": "Para ver el resumen, su lista debe tener al menos 3 incidentes y un máximo de 50 incidentes.",
            "seeSummary": "Ver resumen",
            "closeSummary": "Cerrar resumen",
            "refreshAuth": "Actualizar autenticación",
            "incidentID": "ID del Incidente",
            "correspondingIncidents": "incidentes correspondientes",
            "correspondingIssues": "problemas correspondientes",
            "incident": "Incidente",
            "myIncidents": "Mis Incidentes",
            "assignIncidents": "Asignar Incidentes",
            "assignedIncidents": "Incidentes Asignados",
            "reviewedByMe": "Revisados por mí",
            "dateOfOccurrence": "Fecha de Ocurrencia",
            "dateOfOccurrenceSub": "¿En qué fecha ocurrió el incidente?",
            "dateOfDiscovery": "Fecha de Descubrimiento",
            "dateOfDiscoverySub": "¿En qué fecha se descubrió el evento?",
            "dateOfRegistration": "Fecha de Registro",
            "dateOfUpdate": "Fecha de la última actualización",
            "description": "Descripción",
            "descriptionMinCaract": "Descripción (mín. 100 caracteres)",
            "descriptionAPMinCaract": "Descripción (mín. 50 caracteres)",
            "descriptionSub": "Relate el incidente destacando la descripción de los impactos, la causa del problema ocurrido, cómo fue descubierto y qué acciones se tomaron para resolverlo. <br> <b>El campo de Descripción acepta imágenes.</b> Puede pegar (CTRL + V) capturas a continuación. <b>(El campo debe tener al menos 100 caracteres)</b>",
            "descriptionText": "Describa el incidente con detalles",
            "hashtag": "Hashtags",
            "hashtagSub": "Incluya aquí, en formato de #, palabras clave",
            "hashtagSuggestion": "Sugerencia de Hashtag",
            "hashtagSuggestionSub": "¿No encontró la hashtag que quería? Haga su sugerencia para evaluar la inclusión de la #hashtag en nuestra relación.",
            "basel": "Nivel de Basilea",
            "baselSub": "¿Cuál es el tipo de fallo operacional en el que se encuadra el incidente de acuerdo con los niveles de Basilea?",
            "riskFactorSub": "¿Cuál fue el factor que originó el incidente?",
            "system": "Sistema",
            "systemSub": "¿Cuáles son los sistemas involucrados en el incidente?",
            "products": "Productos",
            "productsSub": "¿Qué línea de productos o servicios fue impactada?",
            "capabilitySub": "Elija el macro proceso relacionado",
            "company": "Empresa",
            "companySub": "¿Qué entidad del BTG fue impactada por el incidente?",
            "originatedArea": "Área que originó",
            "originatedAreaSub": "¿Cuál fue el área que originó el incidente?",
            "impactedAreas": "Áreas Impactadas",
            "impactedAreasSub": "¿Cuáles fueron las áreas impactadas por el incidente?",
            "impacts": "Impactos",
            "impactsSub": "Responda las preguntas sobre los impactos del incidente",
            "op": "Operacional",
            "reg": "Regulatorio",
            "rep": "Reputacional",
            "fin": "Financiero",
            "op01": "¿Cuánto tiempo tardó en resolverse el problema tácticamente? (en horas)",
            "op02": "¿Cuántas áreas estuvieron involucradas para resolver el problema?",
            "reg01": "¿Hubo algún impacto o sanción regulatoria en este incidente?",
            "rep01": "¿El incidente impactó a cuántos clientes directamente? (contrapartes/socios)",
            "rep01Input": "Número exacto de clientes",
            "marioInputToolTip": "¡Haga clic fuera de la caja para guardar!",
            "marioStatus": "No calculado",
            "rep02": "¿Cuál es la naturaleza del impacto reputacional de este incidente?",
            "fin01": "¿Cuál fue la naturaleza del principal impacto financiero?",
            "fin02": "¿Hubo movimiento entre cuentas o impacto en cuenta corriente?",
            "fin03": "¿Cuáles son el valor y la moneda asociados a este impacto?",
            "fin04": "¿Hay posibilidad de recuperación/reversión del valor?",
            "forceRisk": "Forzar Riesgo",
            "forceRiskJustification": "Justificación",
            "forceRiskJustificationSub": "Incluir justificación para la alteración del riesgo",
            "eventOriginSub": "Incluir evento de origen del incidente",
            "accountingDate": "Fecha de contabilización",
            "accountingDateSub": "¿En qué fecha fue contabilizado?",
            "managerialImpact": "Impacto Gerencial",
            "managerialImpactSub": "Pérdida Neta (R$): El signo negativo (-) indica una ganancia, mientras que la ausencia de signo representa una pérdida.",
            "financialTransfer": "Transferencia Financiera",
            "financialTransferTotal": "transferencia(s), totalizando",
            "financialTransferSub": "Para este incidente, ¿necesitará realizar transferencias financieras? <br> (Reembolso, Reembolso, Anticipación, Multa o Transferencia interna)",
            "financialTransferModalHead": "Instrucciones",
            "financialTransferModalHeadSub": "<p></p>Informar las transferencias a realizar en el contexto de este incidente.<br /> Si lo prefiere, puede copiar una tabla de Excel (Ctrl + C) y hacer clic en el botón <b>Botón Pegar</b> a la derecha. <ul> <li>Formato de columna: texto, texto, número, lista, lista.</li> <li>No use comas, el separador decimal es un punto.</li> <li>Puede descargar un modelo de llenado haciendo clic en el botón a continuación.</li> </ul>",
            "financialTransferModalCurrency": "Moneda",
            "financialTransferModalAddLine": "Agregar Línea",
            "financialTransferModalDeleteLine": "Eliminar Línea",
            "financialTransferModalApplyCurrency": "Aplicar moneda a todos",
            "financialTransferModalCategory": "Categoría",
            "financialTransferModalApplyCategory": "Aplicar categoría a todos",
            "financialTransferModalOriginAccount": "Cuenta Origen",
            "financialTransferModalDestinationAccount": "Cuenta Destino",
            "financialTransferModalValue": "Valor",
            "transfersResume": "Resumen de Transferencias",
            "uploadSub": "Adjunte los documentos relacionados con el incidente registrado.",
            "uploadText": "Arrastre y suelte archivos para subir.",
            "cc": "Con copia para",
            "ccSub": "¿Quién debe estar en copia en el correo de registro del incidente?",
            "registeredBy": "Registrado por",
            "requestedBy": "Solicitado por",
            "buttonSend": "Enviar",
            "buttonSave": "Guardar",
            "buttonCancel": "Cancelar",
            "buttonReview": "Revisar",
            "buttonClose": "Cerrar",
            "buttonSelect": "Seleccionar",
            "buttonClean": "Limpiar",
            "buttonPaste": "Pegar",
            "buttonGenerate": "Generar",
            "buttonAccept": "Aceptar",
            "buttonView": "Visualizar",
            "buttonAssign": "Asignar",
            "buttonReAssign": "Reasignar",
            "buttonBatchAssign": "Asignar en lote",
            "buttonReload": "Recargar",
            "buttonRefuse": "Rechazar",
            "buttonYes": "Sí",
            "buttonNo": "No",
            "confirmRefuse": "Confirmar Rechazo",
            "menuButtonNew": "Nuevo",
            "generatedTweet": "Tweet Generado",
            "menuButtonSearch": "Buscar",
            "optionYes": "Sí",
            "optionNo": "No",
            "extraFields": "Campos Extras",
            "incidentHistory": "Historial del incidente",
            "status-2": "Retirado",
            "status0": "Rechazado",
            "status1": "Cerrado",
            "status2": "Pendiente de Distribución",
            "status3": "Pendiente de Revisión",
            "status4": "Revisado",
            "searchOptions": "Opciones de búsqueda",
            "searchOptionDate": "Fecha",
            "searchOptionDateSub": "¿Qué fecha quiere mirar?",
            "searchOptionArea": "Área",
            "searchOptionDescription": "Descripción",
            "start": "Inicio",
            "end": "Fin",
            "occurrence": "Ocurrencia",
            "discovery": "Descubrimiento",
            "record": "Registro",
            "result": "RESULTADOS",
            "lowMario": "Bajo",
            "mediumMario": "Medio",
            "highMario": "Alto",
            "criticalMario": "Crítico",
            "fowardButton": "Reenviar Incidente",
            "downloadButton": "Descargar",
            "buttonEdit": "Editar",
            "buttonEdit2": "Ediciones permitidas para RO y usuario de registro",
            "editTweet": "Editar Tweet",
            "reviewDisabled": "Nivel de Riesgo Bajo",
            "users": "Usuarios",
            "user": "Usuario",
            "hierarchy": "Jerarquía",
            "reviewedBy": "Revisado Por",
            "responsibleToReview": "Responsable de la Revisión",
            "log0": "El",
            "log1": "fue",
            "log2": "creado",
            "log3": "Modificado",
            "log4": "de",
            "log5": "a",
            "log6": "Agregado",
            "log7": "la fecha",
            "log8": "al campo",
            "log9": "migrado de Archer",
            "log10": "Eliminado",
            "statusCreated": "creado",
            "statusUpdated": "actualizado",
            "statusReviewed": "Revisado",
            "dialog0": "Éxito",
            "dialog1": "Su incidente fue",
            "dialogIssue1": "Su problema fue",
            "toast0": "Ahora está editando el incidente",
            "toast1": "Ahora está revisando el incidente",
            "toast2": "Transferencias incompletas",
            "toast3": "¡Formato no soportado!",
            "toast3Sub": "El formato del archivo no es soportado: ",
            "toast4": "El Tweet debe tener más caracteres",
            "toast5": "¡Campo vacío!",
            "toast5Sub": "El campo no puede estar vacío",
            "toast6": "Algo salió mal",
            "toast6Sub": "Por favor, contacte al equipo de Riesgo Operacional",
            "toast7": "Campos obligatorios en blanco",
            "toast7Sub": "Por favor, complete los siguientes campos:",
            "toast8": "Error al buscar",
            "toast8Sub": "No se encontraron incidentes correspondientes",
            "toast9": "¡Error al asignar!",
            "toast10": "¡Incidente asignado!",
            "toast11": "¡Seleccione al menos 1 incidente para distribuir en lote!",
            "toast12": "¡Seleccione un usuario!",
            "toast13": "Nombre del incidente copiado con éxito",
            "issueCopied": "ID del problema copiado con éxito",
            "toast14": "Descripción del incidente copiada con éxito",
            "toast15": "Complete todos los campos marcados como obligatorios",
            "toast16": "Los campos de BDPO fueron llenados con éxito",
            "generalDataBia": "Datos generales",
            "companyAssociatedWithProcess": "Empresa asociada al proceso",
            "capability": "Capacidad",
            "capabilityAssociatedWithProcess": "Capacidad asociada al proceso",
            "areaToWhichProcessBelongs": "Área a la que pertenece el proceso",
            "processDescription": "Descripción del proceso",
            "processNarrative": "Narrativa del proceso, que presenta las actividades y controles pertinentes a la ejecución del proceso",
            "involvedEmployees": "Empleados involucrados",
            "employeesInvolvedInProcess": "Empleados involucrados en la ejecución del proceso en el día a día (Buscar por nombre o correo electrónico)",
            "backupEmployees": "Empleados de respaldo",
            "employeesAbleToExecute": "Empleados capaces de ejecutar el proceso en ausencia de los involucrados (Buscar por nombre o correo electrónico)",
            "executionLocations": "Lugares de ejecución",
            "officeLocations": "Oficina(s) y/o lugares donde el proceso se ejecuta normalmente",
            "processExecutionFrequency": "Frecuencia de ejecución del proceso",
            "executionPeriod": "Período de ocurrencia de la ejecución del proceso",
            "executions": "Ejecuciones",
            "dailyExecutionCount": "¿Cuántas veces al día se ejecuta el proceso?",
            "weekDays": "Día(s) de la semana",
            "weekDaysExecution": "¿En qué día(s) de la semana se ejecuta el proceso?",
            "addExecutionTimes": "Agregar horario(s) de ejecución",
            "addExecutionTime": "Agregar horario de ejecución",
            "addCriticalExecutionTime": "Agregar horario de ejecución crítico",
            "executionTimes": "Horario(s) de ejecución",
            "indicateExecutionPeriods": "Indique los períodos de horarios en que se ejecuta el proceso",
            "addCriticalExecutionTimes": "Agregar horario(s) de ejecución crítico(s)",
            "indicateCriticalExecutionPeriods": "Indique los períodos de horarios en que la ejecución del proceso es crítica",
            "selectPeriodCriticalExecution": "Seleccione el período en que se inserta la ejecución crítica",
            "addTimeRange": "Agregar rango de horario",
            "timeWindow": "Ventana de horarios",
            "periodsWhenExecuted": "Períodos en que se ejecuta el proceso en el día",
            "normalHours": "Horarios normales",
            "criticalHours": "Horarios críticos",
            "systems": "Sistemas",
            "doesProcessUseSystem": "¿El proceso utiliza algún sistema?",
            "addSystem": "Agregar sistema",
            "editSystem": "Editar sistema",
            "findSystemInList": "Encuentre en la lista el sistema deseado (Si no encuentra el sistema en la lista, por favor informe al equipo de riesgo operacional)",
            "selectSystem": "Seleccione el sistema",
            "systemDescription": "Proporcione una breve descripción sobre cómo se emplea el sistema en la ejecución del proceso. (mínimo de 30 caracteres)",
            "potentialToHaltProcess": "Potencial para paralizar el proceso",
            "couldInterruptionForceContingency": "¿Una interrupción podría forzar el uso de un procedimiento de contingencia?",
            "answerQuestion": "Responda la pregunta",
            "contingencyStrategy": "Estrategia de contingencia",
            "contingencyDescription": "Descripción de la contingencia",
            "systemUsageDescription": "Descripción del uso del sistema",
            "contingencyDescriptionSubLabel": "Proporcione información detallada sobre la estrategia de contingencia. (mínimo de 30 caracteres)",
            "typesOfContingencyStrategies": "Tipos de estrategias de contingencia disponibles en caso de indisponibilidad del sistema",
            "backupSystem": "Sistema de respaldo",
            "backupSystemSubLabel": "Identifique en la lista el sistema que actuaría como respaldo del sistema principal, si la estrategia de contingencia incluye tal medida",
            "suppliers": "Proveedores",
            "supplier": "Proveedor",
            "supplierRoleDescription": "Descripción del papel del proveedor",
            "doesProcessDependOnSupplier": "¿La ejecución del proceso depende de algún proveedor? (Si utiliza un sistema que es proporcionado por un tercero, por favor incluya al proveedor. Es posible buscar utilizando el nombre del sistema. Si no lo sabe, contacte al equipo de riesgo operacional)",
            "addSupplier": "Agregar proveedor",
            "editSupplier": "Editar proveedor",
            "typeCharacterizingSupplier": "Tipo que caracteriza la participación del proveedor en el proceso",
            "supplierName": "Nombre del proveedor",
            "enterSupplierName": "Ingrese el nombre del proveedor involucrado en la ejecución del proceso",
            "selectType": "Seleccione el tipo",
            "describeSupplierInvolvement": "Describa brevemente la participación del proveedor en la ejecución del proceso, es decir, su función dentro del mismo",
            "couldSupplierAbsenceForceContingency": "¿La ausencia del proveedor podría forzar el uso de un procedimiento de contingencia?",
            "typesOfContingencyStrategiesSupplier": "Tipos de estrategias de contingencia disponibles en caso de ausencia del proveedor elegido.",
            "internalDependencies": "Dependencias internas",
            "internalDependency": "Dependencia interna",
            "doesProcessDependOnOtherAreas": "¿La ejecución de este proceso depende de la participación de otras áreas de la empresa? (Ejemplos: intercambio de información, soporte IT, otro)",
            "addDependency": "Agregar dependencia",
            "editDependency": "Editar dependencia",
            "dependencyType": "Tipo de dependencia",
            "dependencyRoleDescription": "Descripción del papel de la dependencia",
            "selectAreaForDependency": "Seleccione el área en la que desea determinar el tipo de dependencia con el proceso",
            "chooseDependencyType": "Elija el tipo de dependencia del área seleccionada con el proceso",
            "describeDependency": "Describa brevemente el tipo de dependencia que el proceso tiene con el área seleccionada",
            "personalDataHandling": "Manejo de Datos Personales",
            "personalDataHandlingLGPD": "Manejo de Datos Personales - LGPD/GDPR/ADPPA",
            "personalDataHandlingSublabel": "El manejo de datos personales se refiere a cualquier operación realizada con datos que identifiquen a una persona, como la recolección, almacenamiento, procesamiento o compartición.",
            "personalInformation": "Información personal",
            "doesProcessHandlePersonalInformation": "¿El proceso maneja alguna información personal?",
            "sensitiveInformation": "Información sensible",
            "doesProcessHandleSensitiveInformation": "¿El proceso maneja alguna información sensible?",
            "confidentialInformation": "Información confidencial",
            "doesProcessHandleConfidentialInformation": "¿El proceso maneja alguna información confidencial?",
            "criticalityClassification": "Clasificación de criticidad",
            "oneHour": "1 hora",
            "threeHours": "3 horas",
            "sixHours": "6 horas",
            "twelveHours": "12 horas",
            "twentyFourHours": "24 horas",
            "addJustification": "Agregar justificación",
            "financial": "Financiero",
            "regulatory": "Regulatorio",
            "reputational": "Reputacional",
            "operational": "Operacional",
            "customers": "Clientes",
            "immaterial": "Inmaterial",
            "low": "Bajo",
            "medium": "Medio",
            "high": "Alto",
            "extreme": "Extremo",
            "describeHighFinancialImpact": "Describa el motivo por el cual el impacto financiero es alto o extremo durante la ejecución del proceso",
            "openJustification": "Abrir justificación",
            "calculatedImpact": "Impacto calculado",
            "calculatedImpactFromValues": "Impacto calculado a partir de los valores insertados en la tabla",
            "roImpact": "Impacto RO",
            "roTeamAnalysisImpact": "Impacto determinado por el análisis del equipo de RO",
            "justifyImpactValue": "Justificar la elección del valor del Impacto (mínimo de 30 caracteres)",
            "finalImpact": "Impacto final",
            "finalImpactValue": "Valor definido a partir de la comparación del impacto calculado y RO",
            "rtoRpo": "RTO / RPO",
            "recoveryTimeObjective": "Objetivo de Tiempo de Recuperación",
            "calculatedRTOValue": "Valor de RTO calculado a partir de los datos anteriores",
            "userRecoveryTimeObjective": "Objetivo de Tiempo de Recuperación del Usuario",
            "chooseRTOValue": "Elija un valor para RTO si no está de acuerdo con el valor calculado",
            "justifyRTOValue": "Justificar la elección del valor de RTO",
            "recoveryPointObjective": "Objetivo de Punto de Recuperación",
            "maxTolerableDataLoss": "Tiempo máximo tolerable para la pérdida de datos en caso de falla",
            "contingency": "Contingencia",
            "minimumNumberOfPeople": "Cantidad mínima de personas",
            "minimumPeopleForContingency": "Cantidad mínima necesaria para ejecutar el proceso en una situación de contingencia",
            "keyPersonnel": "Personal clave",
            "keyPersonnelForCrisis": "Personal esencial para la ejecución del proceso en un escenario de crisis (Buscar por nombre o correo electrónico)",
            "saveDraft": "Guardar borrador",
            "submitForm": "Enviar formulario",
            "approve": "Aprobar",
            "reject": "Rechazar",
            "submitFormInstructions": "Después de hacer clic en 'Confirmar envío', el formulario será enviado para la aprobación del BPM. Solo en caso de rechazo, el REX podrá editar el formulario nuevamente.",
            "confirmDeletion": "Eliminar",
            "deleteProcessInstructions": "Después de hacer clic en ‘Eliminar’, el formulario será eliminado de la campaña y el proceso desactivado.",
            "confirmApprovalNote": "Después de hacer clic en 'Confirmar aprobación', el formulario será enviado para la validación de RO. No podrá editarlo más.",
            "confirmApproval": "Confirmar aprobación",
            "confirmFinalizationNote": "Después de hacer clic en 'Confirmar aprobación', el formulario será finalizado.",
            "confirmSubmission": "Confirmar envío",
            "returnToCampaignPage": "Regresar a la página de la campaña",
            "returnToCampaignList": "Regresar a la lista de campañas",
            "navigation": "Navegación",
            "addPerson": "Agregar persona",
            "selectLocation": "Seleccione un lugar",
            "selectPeriod": "Seleccione un período",
            "selectOption": "Seleccione una opción",
            "saveTime": "Guardar horario",
            "daysOfMonth": "Día(s) del mes",
            "daysOfMonthExecution": "¿En qué día(s) del mes se ejecuta el proceso?",
            "firstSemester": "Primer semestre",
            "firstSemesterExecution": "¿En qué día y mes del primer semestre se ejecuta el proceso?",
            "secondSemester": "Segundo semestre",
            "secondSemesterExecution": "¿En qué día y mes del segundo semestre se ejecuta el proceso?",
            "dayAndMonth": "Día y mes",
            "dayAndMonthSelection": "Seleccione el día y mes del año en que se ejecuta el proceso",
            "otherOption": "Otra opción",
            "otherScenarios": "Escriba a continuación los escenarios en que se ejecuta el proceso",
            "timeConflict": "No puede haber conflicto de horarios",
            "criticalTimeExceedsRange": "El horario crítico no puede ser mayor que el rango",
            "january": "Enero",
            "february": "Febrero",
            "march": "Marzo",
            "april": "Abril",
            "may": "Mayo",
            "june": "Junio",
            "july": "Julio",
            "august": "Agosto",
            "september": "Septiembre",
            "october": "Octubre",
            "november": "Noviembre",
            "december": "Diciembre",
            "impactDescription": "Describa el motivo por el cual el impacto es alto o extremo durante la ejecución del proceso. (mínimo de 30 caracteres)",
            "rejectionJustification": "Justificación de rechazo",
            "rejectionReasonDescription": "Describa el(los) motivo(s) para el rechazo del formulario (mínimo de 30 caracteres)",
            "formRejectedByBPM": "Formulario rechazado por BPM",
            "formRejectedByRO": "Formulario rechazado por RO",
            "viewJustification": "Ver justificación",
            "biaFieldFormStatus": "Estado",
            "biaFieldDailyExecutionTeam": "Empleados involucrados",
            "biaFieldBackupExecutionTeam": "Empleados de respaldo",
            "biaFieldNormalExecutionLocations": "Lugares de ejecución",
            "biaFieldExecutionHours": "Horarios de ejecución",
            "biaFieldCriticalExecutionHours": "Horarios de ejecución críticos",
            "biaFieldSystem": "Sistemas",
            "biaFieldSupplier": "Proveedores",
            "biaFieldInternalDependency": "Dependencias internas",
            "biaFieldPersonalInfo": "Información personal",
            "biaFieldSensitiveInfo": "Información sensible",
            "biaFieldConfidentialInfo": "Información confidencial",
            "biaFieldImpact": "Tabla de criticidad",
            "biaFieldCalculatedImpact": "Criticidad calculada",
            "biaFieldRoImpactJustification": "Justificación de cambio de criticidad",
            "biaFieldUserRtoJustification": "Justificación de cambio de RTO",
            "biaFieldContingencyMinimumRequiredPeople": "Cantidad mínima de personas",
            "biaFieldContingencyEmployees": "Personal clave",
            "biaFieldPeriodType": "Frecuencia de ejecución del proceso",
            "biaFieldBpmRejectionJustification": "Justificación de rechazo (BPM)",
            "biaFieldRoRejectionJustification": "Justificación de rechazo (RO)",
            "biaForm": "Formulario BIA",
            "biaStatus1": "Llenar REX",
            "biaStatus2": "Aprobación BPM",
            "biaStatus3": "Aprobación RO",
            "biaStatus4": "Finalizado",
            "biaStatus5": "Revisar REX",
            "invalidJustification": "Justificación inválida",
            "invalidJustificationLength": "La justificación debe tener al menos 30 caracteres",
            "timeAdded": "¡Horario agregado!",
            "blankFields": "Campos en blanco",
            "fillFields": "Complete los campos: ",
            "systemAdded": "Sistema agregado",
            "systemEdited": "Sistema editado",
            "supplierAdded": "Proveedor agregado",
            "supplierEdited": "Proveedor editado",
            "dependencyAdded": "Dependencia agregada",
            "dependencyEdited": "Dependencia editada",
            "atLeastOneExecutionTime": "Debe haber al menos un horario de ejecución",
            "formSaved": "¡Formulario guardado!",
            "saveError": "¡Error al guardar! Contacte al equipo de RO",
            "sendError": "¡Error al enviar! Contacte al equipo de RO",
            "rejectError": "¡Error al rechazar! Contacte al equipo de RO",
            "approvalError": "¡Error al aprobar! Contacte al equipo de RO",
            "tooltipIndicateInvolvedEmployees": "Indique los empleados que normalmente están involucrados en la ejecución del proceso.",
            "tooltipIndicateBackupEmployees": "Indique los empleados que están capacitados para ejecutar el proceso, en caso de que los empleados habituales no estén disponibles.",
            "tooltipIndicateExecutionLocations": "Indique los lugares donde normalmente se realiza el proceso.",
            "tooltipIndicateCriticalPeriods": "Si los hay, indique en este campo los períodos críticos comprendidos dentro de la ventana de ejecución del proceso, es decir, períodos en los que una interrupción en el proceso causaría un mayor impacto.",
            "tooltipMapCriticalSystems": "Esta información se utiliza para mapear sistemas críticos, es decir, sistemas vitales para la ejecución de las actividades relacionadas con el proceso y cuya interrupción afectaría significativamente la capacidad de ejecutar un proceso crítico.",
            "tooltipMapCriticalSuppliers": "Esta información se utiliza para mapear proveedores o terceros críticos, los proveedores críticos son aquellos cuya falla en proporcionar productos o servicios puede interrumpir significativamente la capacidad de ejecutar el proceso. La dependencia de estos proveedores requiere que la empresa tenga planes para mitigar los riesgos asociados a cualquier interrupción en el suministro.",
            "tooltipIndicateInternalDependencies": "Las dependencias internas se refieren a las interacciones y relaciones entre diferentes áreas o departamentos dentro de una organización que son esenciales para mantener las operaciones ininterrumpidas. La interrupción en un área puede impactar negativamente otras áreas dependientes, comprometiendo la continuidad de las operaciones generales.",
            "tooltipPersonalInfoDescription": "Información que permite identificar a una persona física o hace posible su identificación. Ejemplos incluyen nombre, dirección, número de teléfono, CPF, correo electrónico y datos de ubicación.",
            "tooltipSensitiveInfoDescription": "Categoría especial de datos personales que revelan información sobre origen racial o étnico, convicción religiosa, opinión política, afiliación a sindicato u organización de carácter religioso, filosófico o político, datos relacionados con la salud o la vida sexual, datos genéticos o biométricos.",
            "tooltipConfidentialInfoDescription": "Cualquier información que debe mantenerse en secreto por involucrar privacidad o seguridad, abarcando tanto datos personales como información empresarial confidencial.",
            "tooltipImpactMap": "Mapa de impacto",
            "tooltipJustifyHighImpact": "Si se identifican impactos altos o extremos, es necesario proporcionar una breve explicación para justificar el impacto.",
            "tooltipRtoDefinition": "Objetivo de Tiempo de Recuperación (RTO): El Objetivo de Tiempo de Recuperación es el tiempo máximo que debe tomar para que sus sistemas y servicios vuelvan a funcionar después de un problema o desastre. Por ejemplo, si su RTO es de 4 horas, esto significa que si hay un problema en algún recurso necesario para la ejecución del proceso (por ejemplo, un sistema crítico) es necesario que el problema se solucione hasta un máximo de 4 horas después de la interrupción.",
            "tooltipRpoDefinition": "Objetivo de Punto de Recuperación (RPO): El Objetivo de Punto de Recuperación se refiere a cuánto de datos pueden perderse en caso de un incidente relevante o desastre. Mide el tiempo máximo que puede pasar entre la última copia de seguridad de datos y una posible falla. Por ejemplo, si su RPO es de 1 hora, las copias de seguridad de los datos utilizados en el proceso deben realizarse en un máximo de 1 hora para evitar impactos en caso de pérdida de datos.",
            "tooltipIndicateMinimumRequiredPeople": "Indique el número mínimo de personas necesario para ejecutar un proceso en una situación que requiera actuar en contingencia.",
            "tooltipClientImpact": "El impacto en los clientes se caracteriza por la insatisfacción, pérdida de confianza y posible pérdida de clientes debido a fallas o interrupciones en la entrega de productos o servicios. De esta manera, seleccione el impacto de una interrupción del proceso para cada uno de los períodos.",
            "tooltipOperationalImpact": "El impacto operacional se caracteriza por la interrupción de las actividades diarias, causando retrasos, ineficiencias y posibles paradas en la producción o prestación de servicios. De esta manera, seleccione el impacto de una interrupción del proceso para cada uno de los períodos.",
            "tooltipReputationalImpact": "El impacto reputacional se caracteriza por la pérdida de confianza y credibilidad entre clientes, inversores y otros stakeholders, afectando la imagen pública de la empresa. De esta manera, seleccione el impacto de una interrupción del proceso para cada uno de los períodos.",
            "tooltipRegulatoryImpact": "El impacto regulatorio se caracteriza por el incumplimiento de normas, leyes o reglamentos, lo que puede resultar en multas, sanciones u otras acciones legales. De esta manera, seleccione el impacto de una interrupción del proceso para cada uno de los períodos.",
            "tooltipFinancialImpact": "El impacto financiero se caracteriza por la pérdida de ingresos, aumento de costos u otras consecuencias económicas adversas resultantes de una interrupción del proceso. De esta manera, seleccione el impacto de una interrupción del proceso para cada uno de los períodos.",
            "toBia": "hasta",
            "biaMenu1": "Campañas",
            "biaMenu2": "Nueva campaña",
            "biaCampaignStatus1": "En curso",
            "biaCampaignStatus2": "Finalizado",
            "biaCampaign": "Campañas BIA",
            "biaFilterAreas": "Seleccione la(s) área(s) que desea filtrar",
            "biaFilteredAreas": "Áreas filtradas",
            "biaCreation": "Creación",
            "biaExpectedConclusion": "Conclusión prevista",
            "biaSelectStatus": "Seleccione uno o más estados",
            "biaSelectRex": "Seleccione uno o más REX's",
            "biaSelectBpm": "Seleccione uno o más BPM's",
            "biaStatusView": "Vista de Estado",
            "biaFilteredStatus": "Estados filtrados",
            "biaFilteredRex": "REX's filtrados",
            "biaFilteredBpm": "BPM's filtrados",
            "deletedField": "El valor del campo fue eliminado",
            "newCampaign": "Nueva campaña",
            "campaignName": "Nombre de la campaña",
            "chooseCampaignName": "Elija un nombre para identificar la campaña",
            "rexBpmDeadline": "Plazo REX y BPM",
            "chooseRexBpmDeadline": "Elija un plazo, en días, para que el REX y BPM realicen sus tareas",
            "roTeamDeadline": "Plazo del equipo RO",
            "chooseRoTeamDeadline": "Elija un plazo, en días, para que el equipo de RO apruebe los BIA",
            "areasProcesses": "Áreas y procesos",
            "searchAreas": "Busque las áreas que desea encontrar",
            "registerNewCampaign": "Registrar nueva campaña",
            "chooseAreasProcesses": "Elija las áreas y sus procesos que formarán parte de la campaña que está creando",
            "campaignCreatedSuccess1": "La campaña",
            "campaignCreatedSuccess2": "fue creada con éxito!",
            "goToCampaignPage": "Ir a la página de la campaña",
            "createNewCampaign": "Crear nueva campaña",
            "selectAtLeastOneBPM": "¡Seleccione al menos un BPM!",
            "selectAtLeastOneREX": "¡Seleccione al menos un REX!",
            "selectAtLeastOneStatus": "¡Seleccione al menos un estado!",
            "selectAtLeastOneArea": "¡Seleccione al menos un área!",
            "entubaInstructionHead": "Instrucciones",
            "entubaInstructionHeadSub": "<p></p>Cada línea corresponde a un incidente a ser entubado.<br /> Si lo prefiere, puede copiar del template con el Botón <b>Copiar Planilla</b> en Excel y hacer clic en el botón <b>Botón Pegar</b> en la parte superior derecha.<ul><li>En la planilla, habrá instrucciones de cómo puede ser utilizada. <b>¡Preste atención a ellas!</b></li><li>Después de la descarga, haga clic con el botón derecho en el archivo de la planilla, vaya a propiedades y habilite el desbloqueo de macros en ella (en la esquina inferior derecha) y finalmente haga clic en OK.</li><li>En la propia planilla, hay imágenes para ayudar en estos pasos. Haga clic a continuación para descargar el template:</li></ul>",
            "invalidValue": "Valor inválido",
            "postoneReauthenticate": "Posponer 15 min",
            "changeRolesTextBody": "Sus permisos han sido modificados. Al hacer clic en 'Reautenticar', TODAS las pestañas de STORM se recargarán. ¡Asegúrese de guardar su trabajo antes de continuar!",
            "changeRolesManualTextBody": "Al hacer clic en 'Reautenticar', TODAS las pestañas de STORM se recargarán. ¡Asegúrese de guardar su trabajo antes de continuar!",
            "reauthenticateRoles": "Reautenticar roles",
            "includeRelatedIds": "Incluir ids de incidentes relacionados",
            "relatedIncidents": "Incidentes relacionados",
            "insertValidId": "Inserte un id válido",
            "notFound": " no encontrado"
        }
    }

    static getValue(key) {
        return ContentService.data[ContentService.currentLang][key]
    }

    static async getLanguageLocalStorage() {
        var lang
        await Util.getRemoteStorage('lang').then((response) => {
            if (response?.data == null) {
                lang = "pt_br"
            }
            else {
                lang = response.data
            }

            ContentService.currentLang = lang

            return response
        })

    }

    static setLang(lang) {
        Util.setRemoteStorage("lang", lang).then(() => {
            window.location.reload()
        })
    }

    static contentDROFile = {
        pt_br: {
            upload_file: "Subir arquivo",
            choose: "Escolher",
            upload: "Carregar",
            cancel: "Cancelar",
            line: "Linha",
            column: "Coluna",
            message: "Messagem",
            original_value: "Valor original",
            file_validated_successfully: "Arquivo validado com sucesso",
            send_to_database: "Enviar para banco de dados"
        },
        es_es: {
            upload_file: "Subir archivo",
            choose: "Escolher",
            upload: "Subir",
            cancel: "Cancelar",
            line: "Línea",
            column: "Columna",
            message: "Mensaje",
            original_value: "Valor original",
            file_validated_successfully: "Archivo validado con éxito",
            send_to_database: "Enviar a base de datos"
        },
        en_us: {
            upload_file: "Upload File",
            choose: "Choose",
            upload: "Upload",
            cancel: "Cancel",
            line: "Line",
            column: "Column",
            message: "Message",
            original_value: "Original value",
            file_validated_successfully: "File validated successfully",
            send_to_database: "Send to Database"
        }
    }
}
