import BackendService from './BackendService';
import UserService from './UserService'

export default class GraphService {

    static searchUser(user, filter = "users", maxResults = 100, company = UserService.company) {
        return BackendService.get("graph-ms/object/search?value=" + user  + "&filter=" + filter + "&top=" + maxResults + "&company="+company).then(resp => {
            return resp.data
        })
    }

    static getPhoto(email) {
        return  BackendService.getBackendURL() + "/graph-ms/users/" + email + "/photo"
    }

}


