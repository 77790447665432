import BackendService from "./BackendService"

export default class BdpoService {

    static listBdpo() {
        return BackendService.get("/bdpo/list").then(resp => {
            return resp
        })
    }

    static downloadSummary(id) {
        return BackendService.get("/bdpo/download/" + id).then(resp => {
            return resp
        })
    }

    static generateSummary() {
        return BackendService.get("/bdpo/generate").then(resp => {
            return resp
        })
    }
}