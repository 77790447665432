<template>
    <Toast />
    <div class="card">
        <div class="h2" style="display: flex; justify-content: center; margin: 48px 0px 64px 0px ">
            Gestão de Roles
        </div>
        <div class="flex">
            <AutoComplete class="customAutoComplete" field="user" style="height: fit-content; width: 100%" :multiple="false"
                v-model="selectedUser" :suggestions="filteredUsers" :disabled="selectedUser?.mail != null"
                @complete="searchUsers($event, 'user')" :forceSelection="true" :invalid="value === ''"
                :placeholder="selectedUser == null ? 'Selecionar usuário' : ''" @change="searchRoles" />
            <i v-if="selectedUser?.mail != null" class="pi pi-times flex align-items-center" @click="cleanSearch()"
                style="font-size: 1.1rem; cursor: pointer; color: red"></i>
        </div>
        <div class="mt-4 flex" v-if="pickListRoles != null">
            <div class="flex" style="margin-top: 50px; width: 80%; padding: 30px">
                <PickList class="customPickList" v-model="pickListRoles" listStyle="height:342px" style="width: 100%;">
                    <template #sourceheader> Roles disponíveis </template>
                    <template #targetheader> Roles ativas </template>
                    <template #item="slotProps">
                        <div class="flex-1 flex flex-column gap-2">
                            <span class="font-bold">{{ slotProps.item.role }}</span>
                            <div class="flex align-items-center gap-2">
                                <span>{{ slotProps.item.description }}</span>
                            </div>
                        </div>
                    </template>
                </PickList>
            </div>
            <div class="flex flex-column justify-content-center align-items-center" style="width: 20%; gap: 30px">
                <Button class="primaryButton" label="Cancelar" :disabled="disableCancel" @click="mountPickList"
                    style="width: 120px; background-color: rgb(243, 56, 56) !important;" severity="secondary">
                </Button>
                <Button class="primaryButton" label="Salvar" :disabled="disableSubmit" style="width: 120px"
                    @click="setRoles" severity="secondary">
                </Button>
            </div>
        </div>
    </div>
</template>
  
<script>
import GraphService from "../service/GraphService"
import AuthService from "../service/AuthService";
import Util from "../service/Util"

export default {
    components: {
    },
    data() {
        return {
            roleList: [
                { role: "beta-tester", description: "Participa da fase de testes, fornecendo feedback sobre novas funcionalidades e melhorias." },
                { role: "bia.user", description: "Acesso ao módulo de Business Impact Analysis (BIA)" },
                { role: "team.op-risk-europe", description: "Membro do time de Risco Operacional da Europa." },
                { role: "admin", description: "Administrador do sistema com permissões totais para gerenciar usuários, configurar sistemas e acessar todas as áreas do sistema." },
                { role: "bia.manager", description: "Gerente do sistema de Business Impact Analysis (BIA)" },
                { role: "team.op-risk", description: "Membro do time de Risco Operacional do Brasil." },
                { role: "inc.entuba", description: "Permissão para entubar incidentes." },
                { role: "team.op-risk-colombia", description: "Membro do time de Risco Operacional da Colômbia." },
                { role: "team.op-risk-chile", description: "Membro do time de Risco Operacional do Chile." },
                { role: "team.audit", description: "Membro da equipe de auditoria." },
                { role: "issue.user", description: "Permissão de acessar o módulo de issue." },
                { role: "bdpo-user", description: "Acesso à tela de BDPO." },
                { role: "role.manager", description: "Permissão para atribuir permissões." },
                { role: "approver-issue", description: "Permissão para aprovar o fechamento de Issues." }
            ],
            filteredUsers: [],
            selectedUser: null,
            pickListRoles: null,
            oldRoles: null,
            disableCancel: false,
            disableSubmit: false,
        };
    },
    watch: {
        pickListRoles() {
            if (this.pickListRoles != null) {
                let tempArray = []

                this.pickListRoles[1].forEach((item) => {
                    tempArray.push(item.role)
                })

                if (Util.compareArrays(tempArray, this.oldRoles)) {
                    this.disableCancel = true
                    this.disableSubmit = true
                }
                else {
                    this.disableCancel = false
                    this.disableSubmit = false
                }
            }
        },
    },
    methods: {
        searchUsers(event, filter = "users") {
            let tempArray = [];

            GraphService.searchUser(event.query, filter).then((response) => {
                response.forEach((user) => {
                    if (user.mail != "") {
                        tempArray.push({
                            user: Util.emailToName(user.mail.toLowerCase()),
                            mail: user.mail.toLowerCase()
                        });
                    }
                });
                this.filteredUsers = tempArray;
            });
        },
        searchRoles() {
            setTimeout(() => {
                if (this.selectedUser != null) {
                    Util.loading = true
                    AuthService.getUserRoles(this.selectedUser.mail).then((resp) => {
                        Util.loading = false
                        if (resp.success) {
                            this.oldRoles = resp.data

                            this.mountPickList()
                        }
                        else {
                            this.$toast.add({
                                severity: "error",
                                summary: "Erro ao buscar roles",
                                life: 5000,
                            });
                        }
                    })
                }
            }, 200)
        },
        mountPickList() {
            let tempArray = [[], []]

            this.roleList.forEach((item) => {
                if (this.oldRoles.includes(item.role)) {
                    tempArray[1].push(item)
                }
                else {
                    tempArray[0].push(item)
                }
            })

            this.pickListRoles = tempArray

            this.disableCancel = true
            this.disableSubmit = true
        },
        cleanSearch() {
            this.pickListRoles = null
            this.oldRoles = null
            this.selectedUser = null
        },
        setRoles() {
            let tempArray = []

            this.pickListRoles[1].forEach((item) => {
                tempArray.push(item.role)
            })

            let payload = {
                role: tempArray
            }

            Util.loading = true
            AuthService.setUserRoles(payload, this.selectedUser.mail,).then((resp) => {
                Util.loading = false
                if (resp.success) {
                    this.oldRoles = tempArray

                    this.disableCancel = true
                    this.disableSubmit = true
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: "Erro ao salvar roles",
                        life: 5000,
                    });
                }
            })
        }
    },
};
</script>
<style>
.customAutoComplete input {
    width: 100%;
}

.customPickList .p-picklist-source-controls,
.customPickList .p-picklist-target-controls {
    display: none;
}

.customPickList .p-picklist-transfer-buttons button {
    background-color: var(--color_brand_medium);
}

.customPickList .p-picklist-header {
    background: var(--color_brand_medium);
    color: white;
}
</style>
  