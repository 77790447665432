<template>
  <SpeedDial v-if="bdpoIsFilled == true
  " style="right: 20px; bottom: 20px; position: fixed" :model="dialItems" :radius="120" type="quarter-circle"
    direction="up-left" :tooltipOptions="{ position: 'top' }">
    <template #item="{ item }">
      <div v-tooltip.top="{ value: item.label }">
        <Button :icon="item.icon" @click="item.command" class="p-button p-component p-button-icon-only p-button-rounded"
          :disabled="item.disabled" />
      </div>
    </template>
  </SpeedDial>
  <div>
    <Toast />

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoEventType") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoEventTypeDescription")
            }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedEvent == null && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedEvent" :options="bdpoOptions.eventType"
          optionLabel="label" :placeholder="this.bdpoEventTypePlaceholder" style="width: 100%" />
        <Chip v-if="selectedEvent != null && this.bdpoIsFilled == true" :label="selectedEvent.label"
          class="secondaryChip" />
      </div>
      <div v-if="this.bdpoIsFilled == false" style="width: 100%">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootLoss") }}</div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoRootDescription")
            }}</span>
        </div>
        <InputNumber v-model="rootID" inputId="rootID" style="width: 100%" :useGrouping="false" />
      </div>
    </div>

    <div v-if="rootID != null && this.bdpoIsFilled == true" style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootIncident") }}</div>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoRootIncidentNumber")
            }}</span>
        </div>
        <div @click="loadIncidents" class="temporaryChip" style="
          cursor: pointer;
          background-color: var(--color_gray_xlight);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 16px;
        ">
          <!-- <Skeleton v-tooltip.top="Critico" shape="circle" size="1rem" :style="{
            'background-color': 'var(--color_incident_critical)',
          }">
          </Skeleton> -->
          <div style="
            padding-left: 8px;
            display: flex;
            font-weight: 700;
            line-height: 0px;
          ">
            {{ `INC-${this.rootID}` }}
          </div>
          <i class="pi pi-arrow-up-right" style="padding-left: 8px; color: var(--color_gray_medium)"></i>
        </div>
      </div>
      <div style="width: 100%">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoRootIncidentRegistration") }}</div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{
            this.$langGetValue("bdpoRootIncidentRegistrationDescription") }}</span>
        </div>
        <span class="body3" style="font-weight: 700">10/10/2010</span>
      </div>
    </div>

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoAccounted") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoAccountedDescription")
            }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedPSL == null && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedPSL" :options="bdpoOptions.psl" optionLabel="label"
          :placeholder="this.bdpoAccountedPlaceholder" style="width: 100%" />
        <Chip v-if="selectedPSL != null && this.bdpoIsFilled == true" :label="selectedPSL.label"
          class="secondaryChip" />
      </div>
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoCourtOrder") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoCourtOrderDescription")
            }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedJudicialOrigin == null && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedJudicialOrigin" :options="bdpoOptions.judicialOrigin"
          optionLabel="label" :placeholder="this.bdpoCourtOrderPlaceholder" style="width: 100%" />
        <Chip v-if="selectedJudicialOrigin != null && this.bdpoIsFilled == true" :label="selectedJudicialOrigin.label"
          class="secondaryChip" />
      </div>
    </div>

    <div style="
      display: flex;
      align-items: start;
      gap: 32px;
      width: 100%;
      padding-top: 48px;
    ">
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoBusinessFunction") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{
            this.$langGetValue("bdpoBusinessFunctionDescription") }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedBusinessFunction == null && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedBusinessFunction"
          :options="bdpoOptions.businessFunction" optionLabel="label"
          :placeholder="this.bdpoBusinessFunctionPlaceholder" style="width: 100%" />
        <Chip v-if="selectedBusinessFunction != null && this.bdpoIsFilled == true"
          :label="selectedBusinessFunction.label" class="secondaryChip" />
      </div>
      <div style="width: 100%">
        <div style="display: flex; align-items: start; gap: 4px">
          <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoLossStatus") }}</div>
          <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
        </div>
        <div style="margin-bottom: 16px">
          <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoLossStatusDescription")
            }}</span>
        </div>
        <Dropdown :class="{ 'invalid-dropdown': this.selectedStatus == null && buttonClicked }"
          v-if="this.bdpoIsFilled == false" v-model="this.selectedStatus" :options="bdpoOptions.lossStatus"
          optionLabel="label" :placeholder="this.bdpoLossStatusPlaceholder" style="width: 100%" />
        <Chip v-if="selectedStatus != null && this.bdpoIsFilled == true" :label="selectedStatus.label"
          class="secondaryChip" />
      </div>
    </div>

    <div style=" display: flex; align-items: start; flex-direction: column; width: 100%; padding-top: 48px;">
      <div style="display: flex; align-items: start; gap: 4px">
        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue("bdpoBaselLevel") }}</div>
        <i style="color: var(--color_feedback_negative); cursor: help" title="Campo Obrigatório">*</i>
      </div>
      <div style="margin-bottom: 16px">
        <span class="body3" style="color: var(--color_gray_medium)">{{ this.$langGetValue("bdpoBaselLevelDescription")
          }}</span>
      </div>
      <BaselLevel :editMode="!bdpoIsFilled" :baselLevel="baselLevel" @basel-level-change="baselLevelComplete" />
    </div>

    <div v-if="this.bdpoIsFilled == false" style="
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      width: 100%;
      padding-top: 64px;
    ">
      <button @click="setButtonState" class="primaryButton">
        {{ this.$langGetValue("bdpoConfirmButton") }}
      </button>
      <button @click="cancelFilling" class="negativeButton">{{ this.$langGetValue("bdpoCancelButton") }}</button>
    </div>
  </div>
</template>

<script>

import BaselLevel from './BaselLevel.vue';
import IncidentService from "../service/IncidentService";
import FieldService from "../service/FieldService"

export default {
  components: {
    BaselLevel,
  },
  data() {
    return {
      bdpoOptions: {
        eventType: FieldService.getField('incident', 'bdpoEventType'),
        psl: FieldService.getField('incident', 'bdpoAccountedPsl'),
        judicialOrigin: FieldService.getField('incident', 'bdpoJudicialOrigin'),
        businessFunction: FieldService.getField('incident', 'bdpoBusinessFunction'),
        lossStatus: FieldService.getField('incident', 'bdpoLossStatus'),
      },
      baselLevel: { levelOne: this.query.baselLevel.levelOne, levelTwo: this.query.baselLevel.levelTwo, levelThree: this.query.baselLevel.levelThree },
      bdpoIsFilled: false,
      selectedPSL: null,
      selectedStatus: null,
      selectedBusinessFunction: null,
      selectedJudicialOrigin: null,
      selectedEvent: null,
      rootID: null,
      buttonClicked: false,
      queryLocal: this.query,
      isThirdLevelBaselComplete: false,
      dialItems: [
        {
          label: this.$langGetValue("buttonEdit"),
          icon: 'pi pi-pencil',
          disabled: false,
          command: () => {
            this.bdpoIsFilled = false
          }
        },
        {
          label: this.$langGetValue("fowardButton"),
          icon: 'pi pi-envelope',
          disabled: false,
          command: () => {
          }
        }
      ],
      bdpoEventTypePlaceholder: null,
      bdpoCourtOrderPlaceholder: null,
      bdpoBusinessFunctionPlaceholder: null,
      bdpoLossStatusPlaceholder: null,
      bdpoAccountedPlaceholder: null,
    };
  },
  props: {
    query: Object,
  },
  mounted() {
    this.fillValues();
    this.settingPlaceholderStrings();
  },
  watch: {
    // selectedEvent(newValue){
    //   this.queryLocal.bdpo.eventType = newValue.id
    // },
    // selectedJudicialOrigin(newValue){
    //   this.queryLocal.bdpo.judicialOrigin = newValue.id
    // },
    // selectedBusinessFunction(newValue){
    //   this.queryLocal.bdpo.businessFunction = newValue.id
    // },
    // selectedPSL(newValue){
    //   this.queryLocal.bdpo.accountedPsl = newValue.id
    // },
    // selectedStatus(newValue){
    //   this.queryLocal.bdpo.lossStatus = newValue.id
    // },
    // rootID(newValue){
    //   this.queryLocal.bdpo.rootLoss = newValue
    // },
  },
  methods: {
    cancelFilling() {
      const fullURL = window.location.href
      const toBeRemoved = "&bdpo=true"
      window.location.href = fullURL.substring(toBeRemoved.length)
      location.reload();
    },
    settingPlaceholderStrings() {
      this.bdpoAccountedPlaceholder = this.$langGetValue("bdpoAccountedPlaceholder")
      this.bdpoLossStatusPlaceholder = this.$langGetValue("bdpoLossStatusPlaceholder")
      this.bdpoBusinessFunctionPlaceholder = this.$langGetValue("bdpoBusinessFunctionPlaceholder")
      this.bdpoCourtOrderPlaceholder = this.$langGetValue("bdpoCourtOrderPlaceholder")
      this.bdpoEventTypePlaceholder = this.$langGetValue("bdpoEventTypePlaceholder")
    },
    loadIncidents() {
      var newWindow = window.open(
        window.location.origin + "/#/incident/search?id=" + this.rootID
      );

      if (newWindow) {
        newWindow.onload = function () {
          newWindow.location.reload();
        };
      }
    },
    fillValues() {
      let checkList = 0;

      if (this.queryLocal.bdpo == null) {
        return;
      }

      // Verifica e define o tipo de evento
      if (this.queryLocal.bdpo.eventType != null) {
        this.bdpoOptions.eventType.forEach((option) => {
          if (option.id === this.queryLocal.bdpo.eventType) {
            this.selectedEvent = option;
            checkList++;
          }
        });
      }

      // Verifica e define o status de perda
      if (this.queryLocal.bdpo.lossStatus != null) {
        this.bdpoOptions.lossStatus.forEach((option) => {
          if (option.id === this.queryLocal.bdpo.lossStatus) {
            this.selectedStatus = option;
            checkList++;
          }
        });
      }

      // Verifica e define a função de negócios
      if (this.queryLocal.bdpo.businessFunction != null) {
        this.bdpoOptions.businessFunction.forEach((option) => {
          if (option.id === this.queryLocal.bdpo.businessFunction) {
            this.selectedBusinessFunction = option;
            checkList++;
          }
        });
      }

      // Verifica e define a origem judicial
      if (this.queryLocal.bdpo.judicialOrigin != null) {
        this.bdpoOptions.judicialOrigin.forEach((option) => {
          if (option.id === this.queryLocal.bdpo.judicialOrigin) {
            this.selectedJudicialOrigin = option;
            checkList++;
          }
        });
      }

      // Verifica e define o PSL contabilizado
      if (this.queryLocal.bdpo.accountedPsl != null) {
        this.bdpoOptions.psl.forEach((option) => {
          if (option.id === this.queryLocal.bdpo.accountedPsl) {
            this.selectedPSL = option;
            checkList++;
          }
        });
      } else {
        this.selectedPSL = this.bdpoOptions.psl[0];
        this.queryLocal.bdpo.accountedPsl = this.selectedPSL;
        checkList++;
      }

      // Verifica e define a perda raiz
      if (this.queryLocal.bdpo.rootLoss != null) {
        this.rootID = this.queryLocal.bdpo.rootLoss;
        checkList++;
      }

      // Verifica o nível três do Basel
      // if (this.queryLocal.baselLevel.levelThree != null) {
      //   this.isThirdLevelBaselComplete = true;
      // }

      // Define se o bdpo está preenchido
      if (checkList >= 5) {
        this.bdpoIsFilled = true;
      }
    },
    setButtonState() {
      this.buttonClicked = true;
      this.setBdpoFilledState();
    },
    baselLevelComplete(baselLevel) {

      this.queryLocal.baselLevel = baselLevel

      if (this.queryLocal.baselLevel.levelThree != null) {
        this.isThirdLevelBaselComplete = true
      } else if (this.queryLocal.baselLevel.levelThree == null) {
        this.isThirdLevelBaselComplete = false
      }

    },
    setBdpoFilledState() {

      if (
        this.selectedEvent.id != null &&
        // this.isThirdLevelBaselComplete === true &&
        this.selectedJudicialOrigin.id != null &&
        this.selectedBusinessFunction.id != null &&
        this.selectedStatus.id != null
      ) {

        var payload = {
          baselLevel: this.queryLocal.baselLevel,
          bdpo: {
            eventType: this.selectedEvent.id,
            judicialOrigin: this.selectedJudicialOrigin.id,
            businessFunction: this.selectedBusinessFunction.id,
            accountedPsl: this.selectedPSL.id,
            lossStatus: this.selectedStatus.id,
            rootLoss: this.rootID
          }
        }

        IncidentService.patchIncident(payload, this.queryLocal.id).then((response) => {
          if (response.success != false) {
            this.bdpoIsFilled = true;
            this.$toast.add({
              severity: "success",
              summary: this.$langGetValue("toast16"),
              life: 3000,
            });
          }
        })
      } else {
        this.$toast.add({
          severity: "error",
          summary: this.$langGetValue("toast15"),
          life: 3000,
        });
      }
    },
  },
};
</script>

<style>
.invalid-dropdown {
  border-width: 1px;
  border-color: var(--color_feedback_negative);
}
</style>
