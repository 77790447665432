<template>
    <div>
        <button v-if="campaingId != null" @click="returnToCampaing" class="linkButton" style="
        display: flex;
        gap: 8px;
        margin-bottom: 0px;
        justify-content: start;
        width: 260px;
        cursor: pointer;
      ">
            <i class="pi pi-chevron-left" style="color: var(--color_brand_medium)"></i>
            <div class="body2" style="color: var(--color_brand_medium)">
                    {{ label }}
            </div>
        </button>
        <div v-if="data != null">
            <BiaFormComponent :formData="data" :mode="mode"/>
        </div>
    </div>
</template>


<script>
import BiaService from "../service/BiaService.js"
import BiaFormComponent from "../components/BiaFormComponent.vue"

export default {
    components: {
        BiaFormComponent,
    },
    data() {
      return {
        id: null,
        mode: null,
        data: null,
        campaingId: null,
        label: ""
      };
    },
    async created() {
        if (this.$route.query.formId != undefined) {    
            this.id = this.$route.query.formId
            this.mode = this.$route.query.mode
        } else{
            this.$router.push({ name: 'bia' });
        }

        if(this.$route.query.campaingId != undefined) {
            this.campaingId = this.$route.query.campaingId
        }

        BiaService.getForm(this.id).then((response) => {
            this.data = response.data
        })

        this.label = this.$langGetValue('returnToCampaignPage')
    },
    methods: {
        returnToCampaing(){
            window.location.href = window.location.origin + "/#/bia/campaign?id=" + this.campaingId,
                 "_blank"
        }
    }
  };
</script>
<style scoped>
  
</style>
  