import BackendService from './BackendService';

export default class AuthService {
    static reautenticate(){
        localStorage.setItem("reload", "true")
        window.location  = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash);
    }

    static getUserRoles(user) {
        return BackendService.get("/auth/user/" + user).then(resp => {
            return resp
        })
    }

    static setUserRoles(payload, user) {
        return BackendService.put("/auth/role/" + user, payload).then(resp => {
            return resp
        })
    }

}