import BackendService from './BackendService';

export default class FileShareService {
    static local = true
    static port = '5011'

    static list() {
        var url = "/file-share/list"
        if (FileShareService.local) {
            url = "/list"
        }

        return BackendService.get(url, false, FileShareService.local, FileShareService.port).then(resp => {
            return resp
        })
    }

    static upload(input) {
        var url = "/file-share/upload"
        if (FileShareService.local) {
            url = "/upload"
        }
        var payload = new FormData();
        // payload.append('my_field', 'my value');
        // payload.append('my_buffer', new Blob([1, 2, 3]));
        payload.append('my_file', input[0]);

        return BackendService.post(url, payload, false, FileShareService.local, FileShareService.port).then(resp => {
            return resp
        })
    }

    static replace(input, id) {
        var url = "/file-share/replace/"+id
        if (FileShareService.local) {
            url = "/replace/"+id
        }
        var payload = new FormData();
        // payload.append('my_field', 'my value');
        // payload.append('my_buffer', new Blob([1, 2, 3]));
        payload.append('my_file', input[0]);

        return BackendService.post(url, payload, false, FileShareService.local, FileShareService.port).then(resp => {
            return resp
        })
    }

    static download(id) {
        var url = "/file-share/download/"+id
        if (FileShareService.local) {
            url = "/download/"+id
        }

        return BackendService.get(url, true, FileShareService.local, FileShareService.port).then(resp => {
            return resp
        })
    }

}