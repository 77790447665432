import BackendService from './BackendService';

export default class LaraService {

    static searchUser(user, filter = "user") {
        return BackendService.get("lara/search?user=" + user  + "&filter=" + filter).then(resp => {
            return resp.data
        })
    }

    static returnUserManager(data) {
        return BackendService.get("/lara/user/manager?id=" + data).then(resp => {
            return resp.data
        })
    }

    // url estava apontando para dev https://storm-dev.pactual.net/api/storm
    static getUserphoto(email) {
        return BackendService.get("/lara/user/" + email + "/photo").then(resp => {
            console.log(resp)
            return resp
        })
    }

}