<template>
  <Toast />
  <DataTable
    class="customDataTable"
    v-model:expandedRows="expandedRows"
    :value="resultLocalData"
    dataKey="id"
    lazy
    :paginator="resultLocalData == null || resultLocalData.length > 0"
    :rows="pages"
    :rowsPerPageOptions="[5, 10, 20, 50]"
    :totalRecords="totalRecords"
    @page="paginate($event)"
    :first="this.currentPageLocal * pages"
    :loading="resultLocalData == null"
    loadingIcon=""
  >
    <template #loading>
      <div v-for="index in 5" :key="index">
        <div class="flex">
          <div
            class="col-10 flex flex-column justify-content-start pr-4"
            style="padding: 0"
          >
            <div class="flex align-items-center">
              <Skeleton shape="circle" size="16px" class="mr-2"> </Skeleton>
              <Skeleton width="8rem" class="mr-4"></Skeleton>

              <div class="mt-1 flex">
                <Skeleton width="4rem" class="mb-2"></Skeleton>
                <Skeleton width="4rem" class="mb-2 ml-2"></Skeleton>
              </div>
            </div>

            <div class="flex align-items-center justify-content-start mt-3">
              <Skeleton width="50rem" class="mb-2"></Skeleton>
            </div>
          </div>

          <div class="col-2 flex" style="padding: 0">
            <div style="width: 100%; height: 100%" class="flex">
              <div
                style="width: 50%"
                class="flex align-items-center justify-content-start"
              >
                <Divider layout="vertical" />
              </div>
              <div
                style="width: 50%"
                class="flex align-items-center justify-content-start"
              >
                <Skeleton shape="circle" size="1rem"> </Skeleton>
              </div>
            </div>
          </div>
        </div>
        <Divider layout="horizontal" />
      </div>
    </template>
    <Column expander style="width: 3rem; padding-right: 0"> </Column>
    <Column style="padding-left: 0" field="header">
      <template #body="slotProps">
        <div
          style="padding: 17px"
          @dblclick="loadIssues(slotProps.data.id)"
          @mouseover="slotProps.data.hover = true"
          @mouseleave="slotProps.data.hover = false"
          :class="{ flex: true }"
        >
          <div
            class="col-9 flex flex-column justify-content-start"
            style="padding: 0px"
          >
            <div class="flex align-items-center">
              <Skeleton
                v-if="slotProps.data.status != -1"
                v-tooltip.top="getRisk(slotProps.data.riskLevel)"
                shape="circle"
                size="16px"
                class="mr-2"
                :style="{
                  'background-color': getColorRisk(slotProps.data.riskLevel),
                  'min-width': '16px',
                }"
              >
              </Skeleton>
              <i
                v-else
                class="flex mr-2 pi pi-times-circle"
                v-tooltip.top="{ value: this.$langGetValue('status0') }"
                style="font-size: 1rem; color: red"
              ></i>
              <div
                style="
                  white-space: nowrap;
                  color: #05132a;
                  font-size: 16px;
                  font-weight: 700;
                "
              >
                {{ slotProps.data.name }}:
                {{ truncateTitle(slotProps.data.title) }}
                <i
                  v-show="slotProps.data.hover"
                  style="
                    font-size: 1.1rem;
                    cursor: pointer;
                    color: rgb(73, 80, 87);
                  "
                  @click="copyIssue(slotProps.data.id)"
                  class="pi pi-copy"
                ></i>
              </div>

              <div class="flex flex-wrap ml-4" style="gap:4px">
                <Chip
                  class="primaryChip"
                  v-for="selected in slotProps.data.idissueOrigin"
                  :key="selected"
                  :label="
                    this.issue_origin?.find((item) => item.id == selected).label
                  "
                />
              </div>
            </div>

            <div
              class="flex align-items-center justify-content-start mt-2"
              :style="{
                width: '100% !important',
                overflow: 'hidden',
                'text-decoration-line':
                  slotProps.data.status == -1 ? 'line-through' : 'none',
              }"
            >
              <div
                class="resumeText"
                v-if="slotProps.data.descriptionSanitized"
              >
                {{
                  slotProps.data.descriptionSanitized.length > 100
                    ? abbreviateDescription(slotProps.data.descriptionSanitized)
                    : slotProps.data.descriptionSanitized
                }}
              </div>
            </div>
          </div>

          <div class="col-1 flex align-items-center justify-content-center">
            <Chip
              class="temporaryChip"
              :label="this.getStatus(slotProps.data.status)"
              v-tooltip.top="{ value: getProgress(slotProps.data.progress) }"
              :style="{
                color: slotProps.data.progress == 2 ? 'red' : 'black',
              }"
            />
          </div>

          <Divider layout="vertical" style="color: #cacaca" />

          <div
            class="col-2 flex align-items-center justify-content-center"
            style="padding: 0px"
          >
            <div class="flex">
              <span class="mr-3">
                <i
                  class="pi pi-search-plus"
                  v-tooltip.top="{ value: this.$langGetValue('buttonView') }"
                  @click="loadIssues(slotProps.data.id)"
                  style="font-size: 1.1rem; cursor: pointer"
                ></i>
              </span>
            </div>
          </div>
        </div>
      </template>
    </Column>

    <template #expansion="slotProps">
      <div class="flex flex-column" style="padding: 24px 0px 24px 42px">
        <div class="flex">
          <div class="flex justify-content-start align-items-center">
            <div style="font-weight: 500">
              {{ this.$langGetValue("registeredBy") }}
            </div>
            <div class="flex" style="padding: 0px 0px 0px 8px">
              <b>{{ this.emailToName(slotProps.data.submittedBy) }}</b>
            </div>
          </div>
          <div
            class="flex justify-content-start align-items-center"
            style="padding-left: 32px"
          >
            <div style="font-weight: 500">
              {{ this.$langGetValue("dateOfRegistration") }}
            </div>
            <div class="flex" style="padding: 0px 0px 0px 8px">
              <b>{{ formatDate(slotProps.data.submittedTimestamp) }}</b>
            </div>
          </div>
        </div>

        <div class="flex flex-column" style="padding-top: 24px">
          <div class="flex">
            <b>{{ this.$langGetValue("description") }}</b>
          </div>
          <div class="flex" style="padding-top: 8px; font-size: 14px">
            {{ this.addSpace(slotProps.data.descriptionSanitized) }}
          </div>
        </div>

        <!-- <div class="flex flex-column" style="padding-top: 24px">
          <div class="flex">
            <b>{{ this.$langGetValue("actionplans") }}</b>
          </div>
          {{ slotProps.data.actionPlans }}
          <div
            v-if="slotProps.data.actionPlans[0].id"
            style="
              padding-top: 8px;
              gap: 8px;
              flex-direction: column;
              display: flex;
              flex-wrap: wrap;
            "
          >
            <span
              v-for="selected in slotProps.data.actionPlans"
              :key="selected.id"
              class="secondaryText"
            >
              ID-{{ selected.id }}: <strong>{{ selected.title }}</strong> ({{
                this.$langGetValue("date")
              }}: {{ formatDate(selected.date) }}, Status:
              {{ getStatusAP(selected.status) }},
              {{ this.$langGetValue("progress") }}:
              {{ getProgressAP(selected.progress) }})
            </span>
          </div>
          <div
            v-else
            style="
              padding-top: 8px;
              gap: 8px;
              flex-direction: column;
              display: flex;
              flex-wrap: wrap;
            "
          >
            <span class="secondaryText"> - </span>
          </div>
        </div> -->

        <div
          v-if="slotProps.data.fup"
          class="flex flex-column"
          style="padding-top: 24px"
        >
          <div class="flex">
            <b>{{ this.$langGetValue("lastfollowup") }}</b>
          </div>
          <div class="flex" style="padding-top: 8px; font-size: 14px">
            {{
              slotProps.data.fup?.slice(-1).pop().text +
              " (" +
              this.$langGetValue("date") +
              ": " +
              formatDate(slotProps.data.fup?.slice(-1).pop().date) +
              ", " +
              this.$langGetValue("user") +
              ": " +
              this.emailToName(slotProps.data.fup?.slice(-1).pop().user) +
              ")"
            }}
          </div>
        </div>

        <div
          class="flex align-items-center"
          style="padding: 24px 0px 12px 0px; width: 100%; gap: 30px"
        >
          <div
            class="flex flex-column justify-content-start col-4"
            style="padding: 0px; width: calc(33% - 10px)"
          >
            <b>{{ this.$langGetValue("hierarchy") }} </b>
            <div
              v-if="slotProps.data?.OBUChip.length > 0"
              style="
                padding-top: 8px;
                gap: 8px;
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <Chip
                class="secondaryChip"
                v-for="selected in slotProps.data.OBUChip"
                :key="selected"
                :label="selected?.label != null ? selected?.label : '-' "
              />
            </div>
            <div
              v-else
              style="
                padding-top: 8px;
                gap: 8px;
                flex-direction: row;
                display: flex;
                flex-wrap: wrap;
              "
            >
              -
            </div>
          </div>
          <div
            class="flex flex-column justify-content-start col-4"
            style="padding: 0px; width: calc(33% - 10px)"
          >
            <b>{{ this.$langGetValue("issueoriginresponsible") }}</b>
            <div
              style="padding: 8px 0px 0px 0px"
              v-if="slotProps.data.fupResponsible"
            >
              <Chip
                class="secondaryChip"
                v-for="selected in slotProps.data.fupResponsible"
                :key="selected"
                :label="this.emailToName(selected)"
              />
            </div>
            <div v-else>-</div>
          </div>
          <div
            class="flex flex-column justify-content-start col-4"
            style="padding: 0px; width: calc(33% - 10px)"
          >
            <b>{{ this.$langGetValue("focalPoint") }}</b>
            <div
              style="padding: 8px 0px 0px 0px"
              v-if="slotProps.data.focalPoint"
            >
              <Chip
                class="secondaryChip"
                v-for="selected in slotProps.data.focalPoint"
                :key="selected"
                :label="this.emailToName(selected)"
              />
            </div>
            <div v-else>-</div>
          </div>
        </div>

        <div
          class="flex"
          style="padding: 12px 0px 0px 0px; width: 100%; gap: 40px"
        >
          <div
            class="flex flex-column justify-content-start col-4"
            style="padding: 0px; width: calc(33% - 10px)"
          >
            <b>Risk Factor</b>
            <div
              style="padding: 8px 0px 0px 0px"
              v-if="slotProps.data.riskFactor"
            >
              <Chip class="secondaryChip" :label="getRiskFactor(slotProps.data.riskFactor) != null ? getRiskFactor(slotProps.data.riskFactor) : '-'" />
            </div>
            <div v-else>-</div>
          </div>
          <div
            v-if="slotProps.data.system != null"
            class="flex flex-column justify-contet-start"
            style="padding: 0px; width: calc(50% - 20px)"
          >
            <b>{{ this.$langGetValue("system") }}</b>
            <div class="flex flex-wrap" style="padding-top: 8px; gap: 8px">
              <Chip
                class="secondaryChip"
                v-for="selected in slotProps.data.system"
                :key="selected"
                :label="getSystem(selected) != null ? getSystem(selected) : '-'"
              />
            </div>
          </div>
          <div
            v-if="slotProps.data.product != null"
            class="flex flex-column justify-contet-start"
            style="padding: 0px; width: calc(50% - 20px)"
          >
            <b>{{ this.$langGetValue("products") }}</b>
            <div class="flex flex-wrap" style="padding-top: 8px; gap: 8px">
              <Chip
                class="secondaryChip"
                v-for="selected in slotProps.data.product"
                :key="selected"
                :label="getProduct(selected) != null ? getProduct(selected) : '-'"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </DataTable>
</template>
  
  <script>
import UserService from "../service/UserService";
import IssueService from "../service/IssueService.js";
import FieldService from "../service/FieldService.js";
// import ContentService from "../service/ContentService.js";
import Util from "../service/Util.js";
import EventBus from "../AppEventBus";

export default {
  data() {
    return {
      pages: 10,
      initialPageSize: null,
      numberOfRows: null,
      highlightIsActive: false,
      totalRecords: 0,
      loading: false,
      expandedRows: [],
      selectedRoUser: null,
      checked: [],
      areaHierarchy: null,
      hierarchyArray: [],
      resultLocalData: null,
      buttonModeLocal: this.buttonMode,
      sendIssueModal: false,
      items: [],
      roUsers: UserService.roUsers,
      currentPageLocal: null,
      statusAP: [],
      statusIssue: null,
      progressIssue: null,
      progressAP: null,
      issue_origin: null,
    };
  },
  props: {
    buttonMode: String,
    query: Object,
    analyticsOnSearch: Boolean,
    currentPage: Number,
  },
  watch: {
    resultData(newValue) {
      this.resultLocalData = newValue;
    },
  },
  mounted() {
    this.areaHierarchy = FieldService.getField('issue','associatedArea');
    // FieldService.fetch(ContentService.currentLang);
    this.statusIssue = FieldService.getField('issue','issueStatus');
    this.statusAP = FieldService.getField('action-plan','actionPlanStatus');
    this.progressIssue = FieldService.getField('issue','issueProgress');
    this.progressAP = FieldService.getField('action-plan','actionPlanProgress');
    this.issue_origin = FieldService.getField('issue','issueOrigin');
    console.log("caioo", this.issue_origin)

    this.createArrayWithPath();

    if (this.query != null) {
      "page" in this.query
        ? (this.currentPageLocal = this.query.page)
        : (this.currentPageLocal = 0);

      this.paginate({ page: this.currentPageLocal });
    }
  },
  methods: {
    truncateTitle(value) {
      if (value?.length > 30) {
        return value?.substring(0, 30) + "...";
      } else {
        return value;
      }
    },
    copyIssue(issue) {
      Util.copyToClipboard(issue, issue);

      this.$toast.add({
        severity: "info",
        summary: this.$langGetValue("issueCopied"),
        // detail: this.$langGetValue('toast8Sub'),
        life: 1000,
      });
    },
    addSpace(texto) {
      let result = "";
      if (texto) {
        const words = texto.split(" ");

        words.forEach((word) => {
          if (word.length <= 50) {
            result = result + " " + word;
          } else {
            let tempWord = "";
            for (let i = 0; i < word.length; i++) {
              tempWord = tempWord + word[i];
              if (tempWord.length % 50 === 0) {
                tempWord = tempWord + " ";
              }
            }
            result = result + " " + tempWord;
          }
        });
      }
      return result.trim();
    },
    abbreviateDescription(text) {
      if (/[.,]/.test(text[99])) {
        return text.substring(0, 100).replace(/[.,]$/, "...");
      } else if (text[99] === " ") {
        let lastWord = text.substring(0, 100).replace(/\s*\w*$/, "");

        lastWord = lastWord.replace(/[.,]$/, "");

        return lastWord + "...";
      } else {
        let completeWord = text.substring(0, 100).replace(/\s*\w*$/, "");

        return completeWord + "...";
      }
    },
    async paginate(event) {
      let tempQuery = { ...this.query };
      this.resultLocalData = null;
      tempQuery.page = event.page + 1;
      this.currentPageLocal = event.page;

      if (event.rows == undefined) {
        tempQuery.pageSize = parseInt(Util.pageSize)
        this.pages = parseInt(Util.pageSize)
      } else {
        await Util.setRemoteStorage("pagesize", event.rows).then(() => {
          Util.pageSize = event.rows
          tempQuery.pageSize = event.rows;
        });
      }

      IssueService.issueList(tempQuery).then((response) => {
        if (response.success == true) {
          this.resultLocalData = response.data.records;
          if (
            response.data.records == null ||
            response.data.records.length == 0
          ) {
            if (this.buttonModeLocal == "onlyView") {
              this.$toast.add({
                severity: "info",
                summary: this.$langGetValue("toast8"),
                detail: this.$langGetValue("toast8SubIssue"),
                life: 1000,
              });
            }

            this.$emit("update-length", 0);

            this.$emit("update-response", null);

            return;
          }

          this.totalRecords = response.data.totalRecords;

          this.$emit("update-length", this.totalRecords);
          this.$emit("update-response", response.data.records);

          let idList = [];

          response.data.records.forEach((issue) => {
            idList.push(issue.id);
          });
          this.$emit("page-data", idList);
          for (let i = 0; i < this.resultLocalData.length; i++) {
            this.resultLocalData[i].OBUChip = [];
            if (this.resultLocalData[i].hierarchy != null) {
              const resultado = this.parserAreas(
                this.resultLocalData[i].hierarchy
              );

              this.resultLocalData[i].OBUChip.push(resultado[0]);
              this.resultLocalData[i].IBUsChip = resultado[1];
            }
          }

          // if (tempQuery.page == 1) {
          //   tempQuery.pageSize = 10000;
          //   IssueService.issueList(tempQuery).then((responseTotal) => {
          //     this.$emit("update-response", responseTotal.data.records);
          //   });
          // }
        } else {
          if (this.currentPageLocal >= 1) {
            this.paginate({ page: this.currentPageLocal - 1 });
          }
        }
      });
    },
    emailToName(email) {
      return Util.emailToName(email);
    },
    loadIssues(id) {
      if (window.location.hash.includes("issue/search")) {
        EventBus.emit("searchIssueByID", id);
      } else {
        // this.$router.push({ path: '/issue/search?', query: { id: id } });
        window.open(window.location.href + "issue/search?id=" + id, "_blank");
      }
    },
    formatDate(date) {
      return date ? Util.formatDate(date) : "-";
    },
    getColorRisk(issue) {
      // TODO: better way?
      if (issue == 8) {
        return "#307AE0";
      } else if (issue == 6) {
        return "#FFCA43";
      } else if (issue == 9) {
        return "#EB3E48";
      } else if (issue == 11) {
        return "#D3D3D3";
      }else if (issue == 10) {
        return "#754CC5";
      }
    },
    getRisk(risk) {
      if (risk == 8) {
        return this.$langGetValue("lowriskLevel");
      } else if (risk == 6) {
        return this.$langGetValue("mediumriskLevel");
      } else if (risk == 9) {
        return this.$langGetValue("highriskLevel");
      } else if (risk == 11) {
        return this.$langGetValue("improvement opportunityriskLevel");
      }
    },
    getStatus(id) {
      return this.statusIssue?.find((item) => item.id == id)?.label;
    },
    getProgress(id) {
      return this.progressIssue?.find((item) => item.id == id)?.label;
    },
    getProgressAP(id) {
      return this.progressAP?.find((item) => item.id == id)?.label;
    },
    getStatusAP(id) {
      if (id) {
        return this.statusAP.find((item) => item.id == id)?.label;
      } else {
        return "";
      }
    },
    getRiskFactor(id) {
      return FieldService.getField('issue','riskFactor').filter((s) => s.id == id)[0]?.label
    },
    getSystem(id) {
      return FieldService.getField('issue','system').filter((s) => s.id == id)[0]?.label
    },
    getProduct(id) {
      return FieldService.getField('issue','product').filter((s) => s.id == id)[0]?.label
    },
    createArrayWithPath() {
      function visitNodes(tree, path = []) {
        if (!tree) {
          return [];
        }

        path.push(tree.label);
        const info = {
          id: tree.id,
          label: tree.label,
          path: path.join(" -> "),
        };

        const result = [info];

        if (tree.children && tree.children.length > 0) {
          for (const children of tree.children) {
            result.push(...visitNodes(children, path.slice()));
          }
        }

        return result;
      }

      for (let i = 0; i < this.areaHierarchy.length; i++) {
        this.hierarchyArray = this.hierarchyArray.concat(
          visitNodes(this.areaHierarchy[i])
        );
      }
    },
    parserAreas(response) {
      let OBUChip = null;
      let IBUsChip = [];

      response.forEach((tempHierarchy) => {
        const area = this.hierarchyArray.find(
          (object) =>
            object.id === tempHierarchy.id &&
            tempHierarchy.type === "originated"
        );
        if (area) {
          OBUChip = {
            label: area.label,
            path: area.path,
            id: area.id,
          };
        }
      });

      response.forEach((tempHierarchy) => {
        const area = this.hierarchyArray.find(
          (object) =>
            object.id === tempHierarchy.id && tempHierarchy.type === "impacted"
        );
        if (area) {
          IBUsChip.push({
            label: area.label,
            path: area.path,
            id: area.id,
          });
        }
      });

      return [OBUChip, IBUsChip];
    },
  },
};
</script>
  
  <style>
.edited-tooltip-issue .p-tooltip-text {
  white-space: nowrap;
  width: fit-content;
}

.customDataTable th {
  display: none;
}

.customDataTable .p-datatable-tbody tr td {
  padding-top: 0px;
  padding-bottom: 0px;
}

.customDataTable .p-datatable-loading-overlay {
  display: none;
}

.p-button {
  background-color: #195ab4;
}

.p-button:hover {
  background-color: #2860ad;
}

.resumeText {
  margin-top: 8px;
  color: #484848;
  font-size: 12px;
}

.customDataTable .p-datatable-row-expansion {
  background: #f7f7f7 !important;
}
</style>
  