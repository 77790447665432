import BackendService from './BackendService';
import ContentService from './ContentService';

export default class BiaService {

    static createBia(payload) {
        return BackendService.post("/bia/campaign/create", payload).then(resp => {
            return resp
        })
    }

    static getBias() {
        return BackendService.get("/bia/campaign/list").then(resp => {
            return resp
        })
    }

    static getProcess() {

        return BackendService.get("/bia/process").then(resp => {
            return resp
        })
    }


    static getCampaignProcess(data) {
        return BackendService.get(`/bia/campaign/${data}`).then(resp => {
            return resp
        })
    }

    static getForm(formId) {
        return BackendService.get(`/bia/form/${formId}` + "?lang=" + ContentService.currentLang).then(resp => {
            return resp
        })
    }

    static saveDraft(payload, formId) {
        return BackendService.put(`/bia/form/draft/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static saveForm(payload, formId) {
        return BackendService.put(`/bia/form/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static bpmApprove(payload, formId) {
        return BackendService.put(`/bia/form/bpm/approve/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static bpmReject(payload, formId) {
        return BackendService.put(`/bia/form/bpm/reject/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static roApprove(payload, formId) {
        return BackendService.put(`/bia/form/ro/approve/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static roReject(payload, formId) {
        return BackendService.put(`/bia/form/ro/reject/${formId}`, payload).then(resp => {
            return resp
        })
    }

    static changeBpm(payload) {
        return BackendService.post(`/bia/change/bpm`, payload).then(resp => {
            return resp
        })
    }

    static changeRex(payload) {
        return BackendService.post(`/bia/change/rex`, payload).then(resp => {
            return resp
        })
    }

    static changeDescription(payload) {
        return BackendService.post(`/bia/change/description`, payload).then(resp => {
            return resp
        })
    }

    static changeName(payload) {
        return BackendService.post(`/bia/change/name`, payload).then(resp => {
            return resp
        })
    }

    static deleteProcess(formId) {
        return BackendService.delete(`/bia/form/${formId}`).then(resp => {
            return resp
        })
    }

    static listForms(payload) {
        return BackendService.post(`/bia/form/list`, payload).then(resp => {
            return resp
        })
    }
}