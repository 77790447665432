<template>
  <div style="
      margin-bottom: 24px;
      width: 100%;
      background-color: var(--color_brand_dark);
      width: fit-content;
      padding: 4px 4px;
      border-radius: 80px;
    ">
    <button @click="toggle" style="
        background-color: transparent;
        border-width: 0px;
        padding: 0px;
        width: 100%;
      ">
      <div class="profileTriggerDiv flex justify-content-start semicircle" @contextmenu="toggleMenu">
        <div class="flex justify-content-start">
          <Avatar :image="getUserPhoto()" class="mr-2" size="large" shape="circle" style="margin-left: 3px; margin-top: 3px" />
        </div>
        <div class="flex justify-content-center align-items-center mr-3 body2"
          style="font-weight: 600; color: whitesmoke">
          {{ getUserName() }}
        </div>
      </div>
    </button>
    <ContextMenu ref="menu" :model="items" />
  </div>
  <div class="layout-menu-container">
    <AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
  </div>

  <div v-if="displayRoleReauthenticate">
    <Dialog :showHeader="false" :closeOnEscape="false" :closable="false" :modal="true"
      v-model:visible="displayRoleReauthenticate" :draggable="false"
      class="card text-center justify-content-center loading-dialog" style="width: 400px; height: 350px; margin: ">
      <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100"
        style="margin-bottom: 10px" />
      <div class="mt-3" style="margin: 15px">
        <h4 style="font-weight: bold">
          {{ this.$langGetValue("reauthenticateRoles") }}
        </h4>
        <p style="font-size: small">
          {{ this.$langGetValue("changeRolesManualTextBody") }}
        </p>
      </div>
      <template #footer>
        <div class="text-center mt-2">
          <Button icon="pi pi-times-circle" :label="this.$langGetValue('buttonCancel')"
            style="width: 170px; background-color: red" class="p-button p-component p-button-warning"
            @click="displayRoleReauthenticate = false" />
          <Button icon="pi pi-refresh" :label="this.$langGetValue('refreshToken')" style="width: 170px"
            class="p-button p-component p-button-success" @click="reautenticate()" :disabled="tweetReplaceButton" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import AppSubmenu from "./AppSubmenu";
import Util from "./service/Util";
import UserService from "./service/UserService";
import AuthService from "./service/AuthService";
import { Vue3Lottie } from "vue3-lottie";
import thunderAnimation from "./assets/animations/AnimacaoThunder.json";
import GraphService from "./service/GraphService";

export default {
  props: {
    model: Array,
  },
  data() {
    return {
      // userPhoto: null,
      user: UserService.user,
      displayRoleReauthenticate: false,
      items: [{
        label: this.$langGetValue("refreshToken"),
        icon: 'pi pi-refresh',
        command: () => {
          this.displayRoleReauthenticate = true;
        }
      }
      ],
      thunderAnimation,
    };
  },
  // mounted() {
  //   this.getUserPhoto()
  // },
  methods: {
    toggleMenu(event) {
      this.$refs.menu.show(event);
    },
    onMenuItemClick(event) {
      this.$emit("menuitem-click", event);
    },
    onKeyDown(event) {
      const nodeElement = event.target;
      if (event.code === "Enter" || event.code === "Space") {
        nodeElement.click();
        event.preventDefault();
      }
    },
    bannerImage() {
      return this.$appState.darkTheme
        ? "images/banner-primeblocks-dark.png"
        : "images/banner-primeblocks.png";
    },
    getUserName() {
      return Util.emailToName(this.user);
    },
    getUserPhoto() {
      return GraphService.getPhoto(this.user)
    },
    reautenticate() {
      AuthService.reautenticate();
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
  components: {
    AppSubmenu: AppSubmenu,
    Vue3Lottie,
  },
};
</script>

<style>
.layout-sidebar {
  box-shadow: none !important;
  border-radius: 0px;
  background-color: var(--color_gray_xlight);
  height: 100vh;
  padding: 24px 0px 88px 40px;
  position: fixed;
  left: 0px;
  top: 71px;
  z-index: 999;
}

/* width */
.layout-sidebar::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.layout-sidebar::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}

/* Handle */
.layout-sidebar::-webkit-scrollbar-thumb {
  background: var(--color_brand_medium);
  border-radius: 100px;
}

.layout-menu li a.router-link-exact-active {
  color: var(--color_brand_medium);
}

.layout-menu li a {
  color: var(--color_gray_dark);
}

.layout-menu li .layout-menuitem-root-text {
  color: var(--color_brand_dark);
}
</style>
